import { gql } from '@apollo/client';

export const UPDATE_INSTRUMENT_MUTATION = gql`
  mutation UpdateInstrument($id: uuid!, $name: String!, $prettyName: String!) {
    update_instruments_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, prettyName: $prettyName }
    ) {
      id
      name
      prettyName
    }
  }
`;
