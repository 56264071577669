import { useField } from 'formik';
import { Select } from '@mantine/core';

const BULLSHIT_DATA = [
  {
    label: 'React',
    value: 'react',
  },
  {
    label: 'Angular',
    value: 'angular',
  },
];

// @ts-expect-error dumb
const ACField = ({ label, ...props }) => {
  // @ts-expect-error dumb
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <Select
      label={label}
      value={value}
      onChange={setValue}
      error={meta.touched && meta.error}
      data={props['data'] || BULLSHIT_DATA}
      {...props}
    />
  );
};

export { ACField };
