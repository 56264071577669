import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useParams, useHistory } from 'react-router-dom';
import { SectionTitle, InfoItem, Grid } from '@rr/ui';
import { Avatar, Button, useMantineTheme } from '@mantine/core';
import { IconSchool, IconBuilding } from '@tabler/icons';

import { useMutation, useQuery } from '@apollo/client';
import { GET_LOCATION_QUERY } from '../LocationUpdate/GetLocation.query.gql';
import { GetLocationQuery } from 'types/graphql';

import { LocationStudents } from './LocationStudents';
import { LocationTeachers } from './LocationTeachers';
import { DELETE_LOCATION_MUTATION } from './DeleteLocation.mutation.gql';
import { DeleteLocationMutation } from 'types/graphql';

export const LocationDetail = () => {
  const theme = useMantineTheme();
  const history = useHistory();
  const { locationId }: { locationId: string } = useParams();

  const [deleteLocation] = useMutation<DeleteLocationMutation>(
    DELETE_LOCATION_MUTATION,
    {
      variables: {
        id: locationId,
      },
      refetchQueries: ['Locations'],
    }
  );

  useEffect(() => {
    localStorage.setItem('lastLocation', locationId);
  }, [locationId]);

  const { loading, error, data } = useQuery<GetLocationQuery>(
    GET_LOCATION_QUERY,
    {
      variables: {
        id: locationId,
      },
    }
  );

  if (loading) return <div></div>;

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        gap: 10px;
        align-items: center;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }

    .students,
    .lessons,
    .locations,
    .teachers,
    .general {
      padding: 24px;

      a {
        display: block;
      }
    }
  `;

  const handleDeleteLocation = () => {
    const decision = window.confirm('Do you want to delete this location?');
    if (decision) {
      deleteLocation().then(() => {
        localStorage.removeItem('lastLocation');
        history.replace(`/locations`);
      });
    }
  };

  return (
    <div css={styles}>
      <div className="header">
        <div className="l">
          <Avatar color="blue" radius="xl">
            {data?.locations_by_pk?.location_type === 'SCHOOL' ? (
              <IconSchool />
            ) : (
              <IconBuilding />
            )}
          </Avatar>
          <div className="name t1">{data?.locations_by_pk?.name}</div>
        </div>
        <div className="r">
          <Button variant="outline" color="red" onClick={handleDeleteLocation}>
            Delete
          </Button>
          <Button>Edit</Button>
        </div>
      </div>
      <div className="general">
        <SectionTitle title="Basic information" />
        <Grid columns={1}>
          <InfoItem title="Address">{data?.locations_by_pk?.address}</InfoItem>
        </Grid>
        <Grid columns={2}>
          <InfoItem title="School contact">
            {data?.locations_by_pk?.school_contact}
          </InfoItem>
          <InfoItem title="School email">
            {data?.locations_by_pk?.school_email}
          </InfoItem>
          <InfoItem title="Phone">{data?.locations_by_pk?.phone}</InfoItem>
        </Grid>
      </div>
      <div className="students">
        <SectionTitle title="Students">
          <Button variant="outline" size="sm">
            Assign Student
          </Button>
        </SectionTitle>
        <LocationStudents />
      </div>
      <div className="teachers">
        <SectionTitle title="Teachers">
          <Button variant="outline" size="sm">
            Assign Teacher
          </Button>
        </SectionTitle>
        <LocationTeachers />
      </div>
    </div>
  );
};
