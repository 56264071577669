import { useContext, createContext, useMemo } from 'react';
import {
  Column,
  useTable as useTableHook,
  useFlexLayout,
  useRowSelect,
  TableInstance,
  TableToggleAllRowsSelectedProps,
  TableToggleCommonProps,
} from 'react-table';

const miniTableContext = createContext({});

interface IMiniTableContext extends TableInstance {
  offset: number;
  limit: number;
  updateOffset: (number: number) => void;
  count?: number;
  handleSort: (id: string) => void;
  activeSortProperty?: {
    [property: string]: 'asc' | 'desc';
  };
}

export const useMiniTable = () =>
  useContext<Partial<IMiniTableContext>>(miniTableContext);

interface MiniTableWrapperProps {
  data: Record<string, unknown>[];
  columns: Column<Record<string, unknown>>[];
  handleSort?: (id: string) => void;
  activeSortProperty?: {
    [property: string]: 'asc' | 'desc';
  };
  offset: number;
  limit: number;
  updateOffset: (number: number) => void;
  count?: number;
}

export const MiniTableWrapper: React.FC<MiniTableWrapperProps> = ({
  children,
  data,
  columns,
  offset,
  limit,
  count,
  updateOffset,
  handleSort,
  activeSortProperty,
}) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const value = useTableHook(
    { data: memoizedData, columns: memoizedColumns },
    useFlexLayout
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: 'selection',
    //       Header: ({
    //         getToggleAllRowsSelectedProps,
    //       }: {
    //         getToggleAllRowsSelectedProps: TableToggleAllRowsSelectedProps;
    //       }) => (
    //         <div>
    //           <input type="checkbox" {...getToggleAllRowsSelectedProps} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  return (
    <miniTableContext.Provider
      value={{
        ...value,
        offset,
        limit,
        count,
        updateOffset,
        handleSort,
        activeSortProperty,
      }}
    >
      <div>{value === null ? 'Loading...' : children}</div>
    </miniTableContext.Provider>
  );
};
