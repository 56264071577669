// export * from './SearchTableWrapper';
// export * from './SearchTableHeader';
// export * from './SearchTableFooter';
// export * from './SearchTableBody';
import { SearchTableHeader } from './SearchTableHeader';
import { SearchTableBody } from './SearchTableBody';
import { SearchTableFooter } from './SearchTableFooter';
import { SearchTableWrapper } from './SearchTableWrapper';

export const ST = {
  Header: SearchTableHeader,
  Body: SearchTableBody,
  Footer: SearchTableFooter,
  Wrapper: SearchTableWrapper,
};
