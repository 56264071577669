import { gql } from '@apollo/client';

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation(
    $name: String!
    $address: String!
    $phone: String!
    $schoolContact: String!
    $schoolEmail: String!
  ) {
    insert_locations_one(
      object: {
        name: $name
        address: $address
        phone: $phone
        school_contact: $schoolContact
        school_email: $schoolEmail
      }
    ) {
      id
    }
  }
`;
