import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { humanDate } from '@rr/utils';
import { MiniTable } from '@rr/ui';

import { GET_PLAN_LESSONS_QUERY } from './GetPlanLessons.query.gql';
import { GetPlanLessonsQuery } from 'types/graphql';

const LESSON_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Student',
    accessor: 'student',
  },
  {
    Header: 'Teacher',
    accessor: 'teacher',
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Date',
    accessor: 'dateTime',
  },
];

export const PlanLessons = () => {
  const { planId }: { planId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [planId]);

  const updateOffset = (number: number) => setOffset(number);

  const { error, data } = useQuery<GetPlanLessonsQuery>(
    GET_PLAN_LESSONS_QUERY,
    {
      variables: {
        id: planId,
        limit: LIMIT,
        offset,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const lessonData =
    data?.plans_by_pk?.lessons.map((lesson) => ({
      id: lesson.id,
      student: lesson.student.gdpr_name,
      teacher: lesson.user.gdpr_name,
      location: lesson.location?.name,
      dateTime: humanDate(lesson.start_time, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        minute: '2-digit',
        hour: '2-digit',
        hour12: true,
      }),
    })) || [];

  return lessonData.length ? (
    <MiniTable.Wrapper
      columns={LESSON_COLUMNS}
      data={lessonData}
      offset={offset}
      count={data?.plans_by_pk?.lessons_aggregate.aggregate?.count}
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Lessons</MiniTable.Zero>
  );
};
