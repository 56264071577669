import { css } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';
import { useMiniTable } from '../MiniTableWrapper';

export const MiniTableBody = () => {
  const theme = useMantineTheme();
  const { getTableBodyProps, data, limit, rows, prepareRow } = useMiniTable();

  const styles = css`
    background: white;
    border-left: 1px solid ${theme.colors.gray[2]};
    border-right: 1px solid ${theme.colors.gray[2]};
    min-height: ${limit && rows && rows.length < limit ? 0 : '195px'};

    .TableCell {
      padding: 13px 16px;

      a {
        color: inherit;
      }
    }

    .TableRow:nth-of-type(odd) {
      .TableCell {
        background: ${theme.colors.gray[0]};
      }
    }

    .Loading {
      padding: 13px 16px;
      background: white;
      min-height: 205px;
    }
  `;

  return (
    <div css={styles} {...(getTableBodyProps && getTableBodyProps())}>
      {!data || !data.length ? (
        <div className="Loading">Loading...</div>
      ) : (
        rows &&
        rows.map((row) => {
          prepareRow && prepareRow(row);
          return (
            <div className="TableRow" {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <div className="TableCell" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </div>
              ))}
            </div>
          );
        })
      )}
    </div>
  );
};
