import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Modal, Space } from '@mantine/core';

import { GET_TEACHERS_INSTRUMENTS_QUERY } from './GetTeachersAndInstruments.query.gql';
import { ASSIGN_TEACHERS_TO_INSTRUMENT_MUTATION } from './AssignTeachersToInstrument.mutation.gql';
import { GetTeachersAndInstrumentsQuery } from 'types/graphql';

import { Form } from '@rr/ui';

import { AutocompleteTeacher } from 'components';

interface InstrumentAssignTeachersProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const InstrumentAssignTeachers = ({
  opened,
  onClose,
}: InstrumentAssignTeachersProps) => {
  const { instrumentId }: { instrumentId: string } = useParams();

  const { loading, error, data } = useQuery<GetTeachersAndInstrumentsQuery>(
    GET_TEACHERS_INSTRUMENTS_QUERY,
    {
      variables: {
        instrumentId,
      },
    }
  );

  const [assignTeachersToInstrument] = useMutation(
    ASSIGN_TEACHERS_TO_INSTRUMENT_MUTATION,
    {
      refetchQueries: ['GetInstrument'],
    }
  );

  const initialValues = {
    teachers: [],
  };

  const validationSchema = Yup.object().shape({
    teachers: Yup.array().required('Required'),
  });

  const onSubmit = (values: typeof initialValues) => {
    console.log(values);
    console.log(instrumentId);
    assignTeachersToInstrument({
      variables: {
        teacherIds: values.teachers.map((teacher) => ({
          instrument_id: instrumentId,
          teacher_id: teacher,
        })),
      },
    }).then((response) => {
      console.log(response);
    });
    onClose(false);
  };

  if (loading) return <div></div>;
  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  console.log(data);

  const existingIds =
    data?.instruments_by_pk?.teacher_instruments.map(
      (teacher) => teacher.teacher_id
    ) || [];

  const teacherData = data?.users.map((teacher) => ({
    value: teacher.id,
    label: `${teacher.first_name} ${teacher.last_name}`,
    firstName: teacher.first_name,
    lastName: teacher.last_name,
  }));

  const filteredTeacherData = teacherData?.filter(
    (teacher) => !existingIds.includes(teacher.value)
  );

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Assign teachers"
    >
      <Form.Context
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form.Wrapper>
          <AutocompleteTeacher
            value={filteredTeacherData}
            multiple
            name="teachers"
            label="Teachers"
          />
          <Space h="md" />
          <Button type="submit" color="blue" fullWidth>
            Assign teachers
          </Button>
        </Form.Wrapper>
      </Form.Context>
    </Modal>
  );
};
