import { Fragment, useState, Dispatch, SetStateAction } from 'react';
import { Sidebar, TableSortPropertyState } from '@rr/ui';
import { useStudentsQuery } from 'types/graphql';

const LIMIT = 20;

interface StudentListProps {
  handleCreate?: Dispatch<SetStateAction<boolean>>;
}

export const StudentList = ({ handleCreate }: StudentListProps) => {
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<TableSortPropertyState>({
    first_name: 'asc',
  });

  const { error, data } = useStudentsQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: { ...orderBy },
    },
  });

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const students = data?.students || [];
  const updateOffset = (number: number) => setOffset(number);

  return (
    <Fragment>
      <Sidebar.Header title="Students" handleCreate={handleCreate} />
      <Sidebar.Content>
        {students.map((student) => {
          return (
            <Sidebar.Item key={student.id} to={`/students/${student.id}`}>
              <div>{student.gdpr_name}</div>
              <small>
                {student.student_locations[0]?.location.name || '-'}
              </small>
            </Sidebar.Item>
          );
        })}
      </Sidebar.Content>
      <Sidebar.Footer
        updateOffset={updateOffset}
        offset={offset}
        limit={LIMIT}
        count={data?.students_aggregate.aggregate?.count}
      />
    </Fragment>
  );
};
