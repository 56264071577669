import { MiniTableWrapper } from './MiniTableWrapper';
import { MiniTableHeader } from './MiniTableHeader';
import { MiniTableBody } from './MiniTableBody';
import { MiniTablePagination } from './MiniTablePagination';
import { MiniTableZero } from './MiniTableZero';

export const MiniTable = {
  Wrapper: MiniTableWrapper,
  Header: MiniTableHeader,
  Body: MiniTableBody,
  Pagination: MiniTablePagination,
  Zero: MiniTableZero,
};
