import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar, SplitLayout, SplitContainer, ZeroDetail } from '@rr/ui';

import * as ROUTES from 'config/routes';

import { LocationList } from './LocationList';
import { LocationDetail } from './LocationDetail';
import { LocationCreate } from './LocationCreate';

const Locations = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastLocation = localStorage.getItem('lastLocation');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <LocationList handleCreate={setCreateModalOpen} />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route path={ROUTES.LOCATIONS_UPDATE} component={LocationDetail} />
          {lastLocation && <Redirect to={`/locations/${lastLocation}`} />}
          <Route>
            <ZeroDetail title="No location selected">
              Please select one
            </ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
      <LocationCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </SplitLayout>
  );
};

export { Locations };
