import { gql } from '@apollo/client';

export const AUTOCOMPLETE_TEACHERS_QUERY = gql`
  query AutocompleteTeachers {
    users(where: { isTeacher: { _eq: true } }) {
      id
      first_name
      last_name
    }
  }
`;
