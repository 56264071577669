import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';

import { MiniTable } from '@rr/ui';
import { InstrumentStudentsQuery, Students } from 'types/graphql';
import { INSTRUMENT_STUDENTS_QUERY } from './InstrumentStudents.query.gql';

interface InstrumentStudentsProps {
  instrumentId: string;
}

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Students;
    };
  };
}

interface LocationCell {
  value: string;
  cell: {
    row: {
      original: {
        locationId: string;
      };
    };
  };
}

export const InstrumentStudents = () => {
  const { instrumentId }: { instrumentId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 10;

  useEffect(() => {
    setOffset(0);
  }, [instrumentId]);

  const updateOffset = (number: number) => setOffset(number);

  const { data } = useQuery<InstrumentStudentsQuery>(
    INSTRUMENT_STUDENTS_QUERY,
    {
      variables: {
        id: instrumentId,
        offset,
        limit: LIMIT,
      },
    }
  );

  const STUDENT_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ cell, value }: NameCell) => (
        <Link to={`/students/${cell.row.original.id}`}>{value}</Link>
      ),
    },
    {
      Header: 'Location',
      accessor: 'location',
      Cell: ({ cell, value }: LocationCell) => (
        <Link to={`/locations/${cell.row.original.locationId}`}>{value}</Link>
      ),
    },
  ];

  const studentData =
    data?.instruments_by_pk?.student_instruments.map(({ student }) => ({
      id: student.id,
      name: student.gdpr_name,
      location: student.student_locations[0]?.location.name,
      locationId: student.student_locations[0]?.location.id,
    })) || [];

  return studentData.length ? (
    <MiniTable.Wrapper
      columns={STUDENT_COLUMNS}
      data={studentData}
      offset={offset}
      count={
        data?.instruments_by_pk?.student_instruments_aggregate.aggregate?.count
      }
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No students</MiniTable.Zero>
  );
};
