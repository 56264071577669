import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Form } from '@rr/ui';

import {
  AutocompleteStudent,
  AutocompleteTeacher,
  AutocompleteLocation,
} from 'components';

import { CREATE_INVOICE_MUTATION } from './CreateInvoice.mutation.gql';
import { CreateInvoiceMutation } from 'types/graphql';

interface InvoiceCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

// TODO: Finalise createMutation
// TODO: Save to database
// TODO: Add field for type of invoice

export const InvoiceCreate = ({ opened, onClose }: InvoiceCreateProps) => {
  const history = useHistory();

  const [createInvoice, { data, loading, error }] =
    useMutation<CreateInvoiceMutation>(CREATE_INVOICE_MUTATION, {
      refetchQueries: ['Invoices'],
    });

  const VALIDATION_SCHEMA = Yup.object({
    amount: Yup.number().required('Required'),
    date: Yup.date().required('Required'),
    student: Yup.string().required('Required'),
    teacher: Yup.string().required('Required'),
    location: Yup.string().required('Required'),
    issuedAt: Yup.date().required('Required'),
    dueAt: Yup.date().required('Required'),
  });

  const INITIAL_VALUES = {
    amount: '',
    date: new Date(),
    student: '',
    teacher: '',
    location: '',
    issuedAt: new Date(),
    dueAt: new Date(),
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create an invoice"
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);

            const variables = {
              amount: values.amount,
              date: values.date,
              student: values.student,
              teacher: values.teacher,
              location: values.location,
              issuedAt: values.issuedAt,
              dueAt: values.dueAt,
              type: 'school',
            };

            createInvoice({
              variables,
            }).then(() => {
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew label="Amount" type="number" name="amount" />
            <Space h="md" />
            <AutocompleteStudent label="Student" name="student" />
            <Space h="md" />
            <AutocompleteTeacher label="Teacher" name="teacher" />
            <Space h="md" />
            <AutocompleteLocation label="Location" name="location" />
            <Space h="md" />
            <Form.DatePicker label="Issued at" name="issuedAt" />
            <Space h="md" />
            <Form.DatePicker label="Due on" name="dueAt" />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create invoice' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
