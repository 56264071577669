import { css } from '@emotion/react';
import { Button, useMantineTheme } from '@mantine/core';
import { useTable } from '../TableWrapper';

const TablePagination: React.FC = () => {
  const { offset, limit, updateOffset, count } = useTable();
  const theme = useMantineTheme();

  const styles = css`
    height: 52px;
    background: white;
    padding: 11px 16px;
    box-sizing: border-box;
    border-top: 1px solid ${theme.colors.gray[1]};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ButtonContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4px;
    }
  `;

  const handlePreviousPage = () => {
    if (typeof offset !== 'undefined') {
      if (offset > 0 && limit) {
        updateOffset && updateOffset(offset - limit);
      }
    }
  };

  const handleNextPage = () => {
    if (
      typeof count != 'undefined' &&
      typeof limit !== 'undefined' &&
      typeof offset !== 'undefined'
    ) {
      if (offset + limit < count) {
        updateOffset && updateOffset(offset + limit);
      }
    }
  };

  if (
    typeof count == 'undefined' ||
    typeof offset == 'undefined' ||
    typeof limit == 'undefined'
  ) {
    return <div>Error!</div>;
  }

  return (
    <div css={styles}>
      <div>
        {offset + 1} - {offset + limit < count ? offset + limit : count} of{' '}
        {count} results
      </div>
      <div className="ButtonContainer">
        <Button
          variant="subtle"
          disabled={offset < limit}
          onClick={handlePreviousPage}
        >
          Previous
        </Button>
        <Button
          variant="subtle"
          disabled={offset + limit > count}
          onClick={handleNextPage}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export { TablePagination };
