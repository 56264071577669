import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MiniTable } from '@rr/ui';

import { GET_PLAN_STUDENTS_QUERY } from './GetPlanStudents.query.gql';
import { GetPlanStudentsQuery, Students } from 'types/graphql';

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Students;
    };
  };
}

interface UserNameCell {
  value: string;
  cell: {
    row: {
      original: {
        teacherId: string;
      };
    };
  };
}

interface LocationNameCell {
  value: string;
  cell: {
    row: {
      original: {
        locationId: string;
      };
    };
  };
}

const STUDENT_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value, cell }: NameCell) => (
      <Link to={`/students/${cell.row.original.id}`}>{value}</Link>
    ),
  },
  {
    Header: 'Teacher',
    accessor: 'teacher',
    Cell: ({ value, cell }: UserNameCell) => (
      <Link to={`/teachers/${cell.row.original.teacherId}`}>{value}</Link>
    ),
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: ({ value, cell }: LocationNameCell) => (
      <Link to={`/locations/${cell.row.original.locationId}`}>{value}</Link>
    ),
  },
];

export const PlanStudents = () => {
  const { planId }: { planId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [planId]);

  const updateOffset = (number: number) => setOffset(number);

  const { error, data } = useQuery<GetPlanStudentsQuery>(
    GET_PLAN_STUDENTS_QUERY,
    {
      variables: {
        id: planId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const studentData =
    data?.plans_by_pk?.student_plans.map(({ student, user, location }) => ({
      id: student.id,
      name: student.gdpr_name,
      teacherId: user.id,
      teacher: user.gdpr_name,
      locationId: location.id,
      location: location.name,
    })) || [];

  return studentData.length ? (
    <MiniTable.Wrapper
      columns={STUDENT_COLUMNS}
      offset={offset}
      count={data?.plans_by_pk?.student_plans_aggregate.aggregate?.count}
      limit={LIMIT}
      updateOffset={updateOffset}
      data={studentData}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Students</MiniTable.Zero>
  );
};
