import { css } from '@emotion/react';
import {
  IconSortAscending,
  IconSortDescending,
  IconArrowsSort,
} from '@tabler/icons';
import { useTable } from '../TableWrapper';
import { useMantineTheme } from '@mantine/core';

const BORDER_RADIUS = '5px';

interface TableHeaderProps {
  activeSortProperty?: {
    [property: string]: 'asc' | 'desc';
  };
  handleSort?: (id: string) => void;
}

const sortIcons = {
  asc: <IconSortDescending />,
  desc: <IconSortAscending />,
  hover: <IconArrowsSort className="hoverSort" />,
};

const TableHeader: React.FC<TableHeaderProps> = () => {
  const theme = useMantineTheme();
  const { headerGroups, handleSort, activeSortProperty } = useTable();

  const styles = css`
    background: white;
    overflow: hidden;
    border-top-left-radius: ${BORDER_RADIUS};
    border-top-right-radius: ${BORDER_RADIUS};
    font-weight: 600;
    border-bottom: 1px solid ${theme.colors.gray[3]};

    .TableCell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;

      &:not(:last-of-type) {
        border-right: 1px solid ${theme.colors.gray[3]};
      }

      &[data-sort-active] {
        color: ${theme.colors.blue[6]};
      }

      .hoverSort {
        opacity: 0.1;
      }

      &:hover {
        .hoverSort {
          opacity: 0.5;
          color: ${theme.colors.blue[6]};
        }
      }
    }
  `;

  return (
    <div css={styles}>
      {headerGroups &&
        headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <div
                onClick={
                  // @ts-expect-error stupid
                  column.sortable && handleSort
                    ? () => handleSort(column.id)
                    : () => {
                        return false;
                      }
                }
                className="TableCell"
                data-sort-active={
                  // @ts-expect-error stupid
                  (column.sortable &&
                    activeSortProperty &&
                    activeSortProperty[column.id]) ||
                  null
                }
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
                {/* @ts-expect-error stupid */}
                {column.sortable &&
                  activeSortProperty &&
                  !activeSortProperty[column.id] &&
                  sortIcons.hover}
                {/* @ts-expect-error stupid */}
                {(column.sortable &&
                  activeSortProperty &&
                  sortIcons[activeSortProperty[column.id]]) ||
                  null}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export { TableHeader };
