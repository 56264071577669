import { gql } from '@apollo/client';

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $uid: String!
    $first_name: String!
    $last_name: String!
    $phone: String!
  ) {
    update_users_by_pk(
      pk_columns: { id: $uid }
      _set: { first_name: $first_name, last_name: $last_name, phone: $phone }
    ) {
      id
      first_name
      last_name
      phone
    }
  }
`;
