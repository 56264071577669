import { Formik, Form as FormikForm } from 'formik';
import { Button } from '../';
import { Field } from './Field';
import { FieldNew } from './FieldNew';
import { Checkbox } from './Checkbox';
import { MultiSelect } from './MultiSelect';
import { Select } from './Select';
import { DatePicker } from './DatePicker';
import { TimeInput } from './TimeInput';
import { ACField } from './Autocomplete';

const Form = {
  Context: Formik,
  Wrapper: FormikForm,
  Field,
  FieldNew,
  MultiSelect,
  Checkbox,
  Button,
  Select,
  DatePicker,
  TimeInput,
  ACField,
};

export { Form };
