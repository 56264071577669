import { Fragment, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Avatar, Badge, Button, useMantineTheme } from '@mantine/core';
import { SectionTitle, InfoItem, Grid, MiniTable } from '@rr/ui';
import { getRandomThemeColor } from '@rr/utils';

import { useQuery, useMutation } from '@apollo/client';

import { GET_STUDENT_QUERY } from './GetStudent.query.gql';
import { DELETE_STUDENT_MUTATION } from './DeleteStudent.mutation.gql';

import { GetStudentQuery } from 'types/graphql';

import { StudentLessons } from './StudentLessons';
import { StudentLocations } from './StudentLocations';
import { StudentTeachers } from './StudentTeachers';
import { StudentPlans } from './StudentPlans';
import { StudentUpdate } from './StudentUpdate';
import { StudentPlanAssign } from './StudentPlanAssign';

export const StudentDetail = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [assignPlanModalOpen, setAssignPlanModalOpen] = useState(false);

  const theme = useMantineTheme();
  const history = useHistory();
  const { studentId }: { studentId: string } = useParams();

  const [deleteStudent] = useMutation(DELETE_STUDENT_MUTATION, {
    variables: {
      id: studentId,
    },
    refetchQueries: ['Students'],
  });

  useEffect(() => {
    localStorage.setItem('lastStudent', studentId);
  }, [studentId]);

  const { loading, error, data } = useQuery<GetStudentQuery>(
    GET_STUDENT_QUERY,
    {
      variables: {
        id: studentId,
      },
    }
  );

  if (loading) return <div></div>;
  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        gap: 16px;
        align-items: center;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }

        .badges {
          display: flex;
          gap: 6px;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }

    .general,
    .lessons,
    .locations,
    .plans,
    .teachers {
      padding: 24px;

      a {
        display: block;
      }
    }
  `;

  if (data?.students_by_pk === null) {
    localStorage.removeItem('lastStudent');
    window.location.href = '/students';
  }

  const handleDeleteStudent = () => {
    const decision = window.confirm('Do you want to delete this student?');
    if (decision) {
      deleteStudent().then(() => {
        localStorage.removeItem('lastStudent');
        history.replace(`/students`);
      });
    }
  };

  return (
    <Fragment>
      <div css={styles}>
        <div className="header">
          <div className="l">
            <Avatar color={getRandomThemeColor()} radius="xl">
              {data?.students_by_pk?.first_name.charAt(0)}
              {data?.students_by_pk?.last_name.charAt(0)}
            </Avatar>
            <div className="name t1">
              {data?.students_by_pk?.first_name}{' '}
              {data?.students_by_pk?.last_name}
            </div>
            <div className="badges">
              {data?.students_by_pk?.student_instruments.map(
                ({ instrument }) => (
                  <Badge
                    key={instrument.prettyName}
                    size="md"
                    color={getRandomThemeColor()}
                  >
                    {instrument.prettyName}
                  </Badge>
                )
              )}
            </div>
          </div>

          <div className="r">
            <Button variant="outline" color="red" onClick={handleDeleteStudent}>
              Delete
            </Button>
            <Button onClick={() => setEditModalOpen(true)}>Edit</Button>
          </div>
        </div>
        <div className="general">
          <SectionTitle title="Basic information">
            {!data?.students_by_pk?.student_users.length ? (
              <Button variant="outline">Assign guardian</Button>
            ) : null}
          </SectionTitle>
          {data?.students_by_pk?.student_users.length ? (
            <>
              <Grid columns={3}>
                <InfoItem title="Guardian name">
                  <Link
                    to={`/guardians/${data?.students_by_pk?.student_users[0].user.id}`}
                  >
                    {data?.students_by_pk?.student_users[0].user.gdpr_name}
                  </Link>
                </InfoItem>
                <InfoItem title="Guardian phone">
                  {data?.students_by_pk?.student_users[0].user.phone}
                </InfoItem>
                <InfoItem title="Guardian email">
                  {data?.students_by_pk?.student_users[0].user.email}
                </InfoItem>
              </Grid>
              <Grid columns={1}>
                <InfoItem title="Notes">{data?.students_by_pk?.notes}</InfoItem>
              </Grid>
            </>
          ) : (
            // TODO: Add a link to add a guardian
            <MiniTable.Zero>
              <div>No guardian found</div>
            </MiniTable.Zero>
          )}
        </div>
        <div className="plans">
          <SectionTitle title="Plans">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setAssignPlanModalOpen(true)}
            >
              Assign plan
            </Button>
          </SectionTitle>
          <StudentPlans />
        </div>
        <div className="lessons">
          <SectionTitle title="Lessons" />
          <StudentLessons />
        </div>
        <div className="locations">
          <SectionTitle title="Locations" />
          <StudentLocations />
        </div>
        <div className="teachers">
          <SectionTitle title="Teachers" />
          <StudentTeachers />
        </div>
      </div>
      <StudentUpdate opened={editModalOpen} onClose={setEditModalOpen} />
      <StudentPlanAssign
        opened={assignPlanModalOpen}
        onClose={setAssignPlanModalOpen}
      />
    </Fragment>
  );
};
