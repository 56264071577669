import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form } from '@rr/ui';

import { CREATE_LOCATION_MUTATION } from './CreateLocation.mutation.gql';
import { CreateLocationMutation } from 'types/graphql';

interface LocationCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const LocationCreate = ({ opened, onClose }: LocationCreateProps) => {
  const history = useHistory();
  const [createLocation, { data, loading, error }] =
    useMutation<CreateLocationMutation>(CREATE_LOCATION_MUTATION, {
      refetchQueries: ['Locations'],
    });

  const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    schoolContact: Yup.string().required('Required'),
    schoolEmail: Yup.string()
      .email('A valid email is required')
      .required('Required'),
  });

  const INITIAL_VALUES = {
    name: '',
    address: '',
    phone: '',
    schoolContact: '',
    schoolEmail: '',
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create a location"
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            createLocation({
              variables: {
                ...values,
              },
            }).then((response) => {
              history.push(
                `/locations/${response.data?.insert_locations_one?.id}`
              );
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="name"
              label="Name"
              placeholder="Location name"
              fullWidth
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="address"
              label="Address"
              placeholder="Address"
              fullWidth
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="phone"
              label="Phone"
              placeholder="Phone number"
              fullWidth
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="schoolContact"
              label="School contact"
              placeholder="School contact (name)"
              fullWidth
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="email"
              name="schoolEmail"
              label="School email"
              placeholder="School email"
              fullWidth
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create location' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
