import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar, SplitLayout, SplitContainer, ZeroDetail } from '@rr/ui';

import * as ROUTES from 'config/routes';

import { PlanCreate } from './PlanCreate';
import { PlanList } from './PlanList';
import { PlanDetail } from './PlanDetail';

const Plans = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastPlan = localStorage.getItem('lastPlan');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <PlanList handleCreate={setCreateModalOpen} />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route path={ROUTES.PLANS_UPDATE} component={PlanDetail} />
          {lastPlan && <Redirect to={`/plans/${lastPlan}`} />}
          <Route>
            <ZeroDetail title="No plan selected">Please select one</ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
      <PlanCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </SplitLayout>
  );
};

export { Plans };
