import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { Badge } from '@mantine/core';

import { MiniTable } from '@rr/ui';
import { formatCurrency } from '@rr/utils';

import { GET_GUARDIAN_PLANS } from './GetGuardianPlans.query.gql';
import { GuardianPlansQuery, Plans, Users } from 'types/graphql';

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Plans | Users;
    };
  };
}

interface TeacherCell {
  value: string;
  cell: {
    row: {
      original: {
        teacherId: string;
      };
    };
  };
}

interface LessonPriceCell {
  value: number;
}

interface ActivePlanCell {
  value: boolean;
}

export const GuardianPlans = () => {
  const { userId }: { userId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [userId]);

  const updateOffset = (number: number) => setOffset(number);

  const { loading, error, data } = useQuery<GuardianPlansQuery>(
    GET_GUARDIAN_PLANS,
    {
      variables: {
        uid: userId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const PLAN_COLUMNS = [
    {
      Header: 'Plan ID',
      accessor: 'planId',
      Cell: ({ value }: NameCell) => (
        <Link to={`/plans/${value}`}>{value}</Link>
      ),
    },
    {
      Header: 'Student',
      accessor: 'student',
    },
    {
      Header: 'Teacher',
      accessor: 'teacher',
      Cell: ({ value, cell }: TeacherCell) => (
        <Link to={`/teachers/${cell.row.original.teacherId}`}>{value}</Link>
      ),
    },
    {
      Header: 'Lesson Price',
      accessor: 'lessonPrice',
      Cell: ({ value }: LessonPriceCell) => <div>{formatCurrency(value)}</div>,
    },
    {
      Header: 'LessonCount',
      accessor: 'lessonCount',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: ActivePlanCell) => (
        <div>
          {value ? (
            <Badge variant="dot" color="green">
              ACTIVE
            </Badge>
          ) : (
            <Badge variant="dot" color="yellow">
              Inactive
            </Badge>
          )}
        </div>
      ),
    },
  ];

  const planData =
    data?.users_by_pk?.student_users[0]?.student.student_plans.map(
      ({ plan, user, student }) => ({
        planId: plan.id,
        teacher: user.gdpr_name,
        student: student.gdpr_name,
        studentId: student.id,
        teacherId: user.id,
        lessonPrice: plan.lessonPrice,
        lessonCount: plan.lessonCount,
        status: plan.active,
      })
    ) || [];

  return planData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={PLAN_COLUMNS}
      data={planData}
      offset={offset}
      count={
        data?.users_by_pk?.student_users[0]?.student.student_plans_aggregate
          .aggregate?.count
      }
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Plans</MiniTable.Zero>
  );
};
