import { gql } from '@apollo/client';

export const ASSIGN_TEACHERS_TO_INSTRUMENT_MUTATION = gql`
  mutation AssignTeachersToInstrument(
    $teacherIds: [teacher_instruments_insert_input!]!
  ) {
    insert_teacher_instruments(objects: $teacherIds) {
      affected_rows
    }
  }
`;
