import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Form } from '@rr/ui';

import {
  AutocompleteStudent,
  AutocompleteTeacher,
  AutocompleteLocation,
  AutocompleteInstrument,
} from 'components';

import { CREATE_LESSONS_MUTATION } from './CreateLessons.mutation.gql';

//TODO: Abstract me out to common file, same for all modals
interface LessonCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const LessonCreate = ({ opened, onClose }: LessonCreateProps) => {
  const history = useHistory();

  const [createLessons, { data, loading, error }] = useMutation(
    CREATE_LESSONS_MUTATION,
    {
      refetchQueries: ['Lessons', 'GetAllLessons'],
    }
  );

  const VALIDATION_SCHEMA = Yup.object({
    student: Yup.string().required('Required'),
    teacher: Yup.string().required('Required'),
    location: Yup.string().required('Required'),
    instrument: Yup.string().required('Required'),

    quantity: Yup.number().max(20, 'Message').required('Required'),
  });

  const INITIAL_VALUES = {
    student: '',
    teacher: '',
    location: '',
    instrument: '',
    date: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    quantity: 1,
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create a lesson"
    >
      <Form.Context
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            console.log(values);
            const date = new Date(values.date);
            const startTime = new Date(values.startTime);
            const endTime = new Date(values.endTime);

            // Combine date and startTime
            const startDateTime = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              startTime.getHours(),
              startTime.getMinutes(),
              startTime.getSeconds()
            );

            // Combine date and endTime
            const endDateTime = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              endTime.getHours(),
              endTime.getMinutes(),
              endTime.getSeconds()
            );

            setSubmitting(false);
            const objects = [];
            for (let i = 0; i < values.quantity; i++) {
              objects.push({
                student_id: values.student,
                teacher_id: values.teacher,
                location_id: values.location,
                instrument_id: values.instrument,
                start_time: startDateTime,
                end_time: endDateTime,
                status: 'ISSUED',
              });
            }
            const variables = {
              objects,
            };
            console.table(objects);
            createLessons({
              variables,
            }).then(() => {
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <AutocompleteTeacher label="Teacher" name="teacher" />
            <Space h="md" />
            <AutocompleteStudent label="Student" name="student" />
            <Space h="md" />
            <AutocompleteLocation label="Location" name="location" />
            <Space h="md" />
            <AutocompleteInstrument label="Instrument" name="instrument" />
            <Space h="md" />
            <Form.DatePicker label="Date" name="date" />
            <Space h="md" />
            <Form.TimeInput label="Start time" name="startTime" />
            <Space h="md" />
            <Form.TimeInput label="End time" name="endTime" />
            <Space h="md" />
            <Form.FieldNew
              max="20"
              type="number"
              label="Quantity"
              name="quantity"
              readOnly
              disabled
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create lesson' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
