import { gql } from '@apollo/client';

export const AUTOCOMPLETE_STUDENTS_QUERY = gql`
  query AutocompleteStudents {
    students {
      id
      first_name
      last_name
    }
  }
`;
