import { gql } from '@apollo/client';

export const GET_PLAN_STUDENTS_QUERY = gql`
  query GetPlanStudents($id: uuid!) {
    plans_by_pk(id: $id) {
      student_plans {
        student {
          id
          gdpr_name
        }
        user {
          id
          gdpr_name
        }
        location {
          id
          name
        }
      }
      student_plans_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
