import { gql } from '@apollo/client';

export const GET_ALL_TEACHERS_QUERY = gql`
  query GetAllTeachers {
    users(where: { isTeacher: { _eq: true } }) {
      id
      first_name
      last_name
    }
  }
`;
