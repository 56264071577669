import { css } from '@emotion/react';
import { Button } from '@mantine/core';
import { Link } from 'react-router-dom';

const NoRoute = () => {
  const styles = css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;

    h2 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 24px;
    }
  `;

  return (
    <div css={styles}>
      <div className="text">
        <h2>Oops!</h2>
        <p>We couldn't find that page</p>
        <Button variant="white" component={Link} to="/">
          Back to dashboard
        </Button>
      </div>
    </div>
  );
};

export { NoRoute };
