import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

interface ButtonProps {
  fullWidth?: boolean;
  disabled?: boolean;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: 'normal' | 'slim';
}

const Button: React.FC<ButtonProps> = ({
  children,
  fullWidth,
  disabled,
  to,
  onClick,
  size = 'normal',
}) => {
  const styles = {
    base: css`
      appearance: none;
      font: inherit;
      border: none;
      font-weight: 600;
      text-decoration: none;
      background: black;
      display: block;
      color: white;
      width: ${fullWidth ? '100%' : 'auto'};
      opacity: ${!disabled ? 1 : 0.2};
      line-height: 1;
      border-radius: 4px;
      color: ${!disabled ? 'white' : 'rgba(255,255,255,.5)'};
    `,
    sizes: {
      normal: css`
        padding: 16px 16px 14px;
        line-height: 1.1;
      `,
      slim: css`
        padding: 8px 10px;
        font-size: 0.9rem;
      `,
    },
  };

  return to ? (
    <Link className="Button" to={to} css={[styles.base, styles.sizes[size]]}>
      {children}
    </Link>
  ) : (
    <button
      className="Button"
      css={[styles.base, styles.sizes[size]]}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export { Button };
