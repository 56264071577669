import { useField } from 'formik';
import { TextInput, Textarea } from '@mantine/core';

// @ts-expect-error dumb
const FieldNew = ({ label, ...props }) => {
  // @ts-expect-error dumb
  const [field, meta] = useField(props);

  switch (props.type) {
    case 'textarea':
      return (
        <Textarea
          label={label}
          value={field.value}
          onChange={field.onChange}
          error={meta.touched && meta.error}
          {...props}
        />
      );
    default:
      return (
        <TextInput
          label={label}
          value={field.value}
          onChange={field.onChange}
          error={meta.touched && meta.error}
          {...props}
        />
      );
  }
};

export { FieldNew };
