import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Button } from '../../Button';
import { connectPagination } from 'react-instantsearch-dom';

const CustomPagination = ({
  // @ts-expect-error ignore
  currentRefinement,
  // @ts-expect-error ignore
  nbPages,
  // @ts-expect-error ignore
  refine,
  // @ts-expect-error ignore
  createURL,
}) => {
  console.log('currentRefinement', currentRefinement);
  console.log('nbPages', nbPages);
  const handleClick = (direction: 'forward' | 'backward') => {
    direction === 'forward'
      ? refine(currentRefinement + 1)
      : refine(currentRefinement - 1);
  };
  return (
    <Fragment>
      <div>
        Page {currentRefinement} of {nbPages}
      </div>
      <div className="ButtonContainer">
        <Button
          size="slim"
          onClick={() => handleClick('backward')}
          disabled={currentRefinement === 1}
        >
          Previous
        </Button>
        <Button
          size="slim"
          onClick={() => handleClick('forward')}
          disabled={currentRefinement === nbPages}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

const Pagination = connectPagination(CustomPagination);

const SearchTableFooter: React.FC = () => {
  const styles = css`
    height: 52px;
    box-sizing: border-box;
    border-top: 1px solid #dedede;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    padding: 11px 16px;
    background: white;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .ButtonContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4px;
    }
  `;

  return (
    <div css={styles} className="TableFooter">
      <Pagination />
    </div>
  );
};

export { SearchTableFooter };
