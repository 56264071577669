import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  timestamptz: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "instruments" */
export type Instruments = {
  __typename?: 'instruments';
  id: Scalars['uuid'];
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  name: Scalars['String'];
  prettyName?: Maybe<Scalars['String']>;
  /** An array relationship */
  student_instruments: Array<Student_Instruments>;
  /** An aggregate relationship */
  student_instruments_aggregate: Student_Instruments_Aggregate;
  /** An array relationship */
  student_plans: Array<Student_Plans>;
  /** An aggregate relationship */
  student_plans_aggregate: Student_Plans_Aggregate;
  /** An array relationship */
  teacher_instruments: Array<Teacher_Instruments>;
  /** An aggregate relationship */
  teacher_instruments_aggregate: Teacher_Instruments_Aggregate;
};


/** columns and relationships of "instruments" */
export type InstrumentsLessonsArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "instruments" */
export type InstrumentsLessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "instruments" */
export type InstrumentsStudent_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


/** columns and relationships of "instruments" */
export type InstrumentsStudent_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


/** columns and relationships of "instruments" */
export type InstrumentsStudent_PlansArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "instruments" */
export type InstrumentsStudent_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "instruments" */
export type InstrumentsTeacher_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};


/** columns and relationships of "instruments" */
export type InstrumentsTeacher_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};

/** aggregated selection of "instruments" */
export type Instruments_Aggregate = {
  __typename?: 'instruments_aggregate';
  aggregate?: Maybe<Instruments_Aggregate_Fields>;
  nodes: Array<Instruments>;
};

/** aggregate fields of "instruments" */
export type Instruments_Aggregate_Fields = {
  __typename?: 'instruments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Instruments_Max_Fields>;
  min?: Maybe<Instruments_Min_Fields>;
};


/** aggregate fields of "instruments" */
export type Instruments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Instruments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "instruments". All fields are combined with a logical 'AND'. */
export type Instruments_Bool_Exp = {
  _and?: Maybe<Array<Instruments_Bool_Exp>>;
  _not?: Maybe<Instruments_Bool_Exp>;
  _or?: Maybe<Array<Instruments_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lessons?: Maybe<Lessons_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  prettyName?: Maybe<String_Comparison_Exp>;
  student_instruments?: Maybe<Student_Instruments_Bool_Exp>;
  student_plans?: Maybe<Student_Plans_Bool_Exp>;
  teacher_instruments?: Maybe<Teacher_Instruments_Bool_Exp>;
};

/** unique or primary key constraints on table "instruments" */
export enum Instruments_Constraint {
  /** unique or primary key constraint */
  InstrumentsPkey = 'instruments_pkey'
}

/** input type for inserting data into table "instruments" */
export type Instruments_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  lessons?: Maybe<Lessons_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  prettyName?: Maybe<Scalars['String']>;
  student_instruments?: Maybe<Student_Instruments_Arr_Rel_Insert_Input>;
  student_plans?: Maybe<Student_Plans_Arr_Rel_Insert_Input>;
  teacher_instruments?: Maybe<Teacher_Instruments_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Instruments_Max_Fields = {
  __typename?: 'instruments_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  prettyName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Instruments_Min_Fields = {
  __typename?: 'instruments_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  prettyName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "instruments" */
export type Instruments_Mutation_Response = {
  __typename?: 'instruments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Instruments>;
};

/** input type for inserting object relation for remote table "instruments" */
export type Instruments_Obj_Rel_Insert_Input = {
  data: Instruments_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Instruments_On_Conflict>;
};

/** on conflict condition type for table "instruments" */
export type Instruments_On_Conflict = {
  constraint: Instruments_Constraint;
  update_columns?: Array<Instruments_Update_Column>;
  where?: Maybe<Instruments_Bool_Exp>;
};

/** Ordering options when selecting data from "instruments". */
export type Instruments_Order_By = {
  id?: Maybe<Order_By>;
  lessons_aggregate?: Maybe<Lessons_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  prettyName?: Maybe<Order_By>;
  student_instruments_aggregate?: Maybe<Student_Instruments_Aggregate_Order_By>;
  student_plans_aggregate?: Maybe<Student_Plans_Aggregate_Order_By>;
  teacher_instruments_aggregate?: Maybe<Teacher_Instruments_Aggregate_Order_By>;
};

/** primary key columns input for table: instruments */
export type Instruments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "instruments" */
export enum Instruments_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrettyName = 'prettyName'
}

/** input type for updating data in table "instruments" */
export type Instruments_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  prettyName?: Maybe<Scalars['String']>;
};

/** update columns of table "instruments" */
export enum Instruments_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrettyName = 'prettyName'
}

/** columns and relationships of "invoices" */
export type Invoices = {
  __typename?: 'invoices';
  amount: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  due_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  issued_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  notes?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
  teacher_id: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "invoices" */
export type Invoices_Aggregate = {
  __typename?: 'invoices_aggregate';
  aggregate?: Maybe<Invoices_Aggregate_Fields>;
  nodes: Array<Invoices>;
};

/** aggregate fields of "invoices" */
export type Invoices_Aggregate_Fields = {
  __typename?: 'invoices_aggregate_fields';
  avg?: Maybe<Invoices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_Max_Fields>;
  min?: Maybe<Invoices_Min_Fields>;
  stddev?: Maybe<Invoices_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_Sum_Fields>;
  var_pop?: Maybe<Invoices_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_Var_Samp_Fields>;
  variance?: Maybe<Invoices_Variance_Fields>;
};


/** aggregate fields of "invoices" */
export type Invoices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invoices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoices" */
export type Invoices_Aggregate_Order_By = {
  avg?: Maybe<Invoices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Invoices_Max_Order_By>;
  min?: Maybe<Invoices_Min_Order_By>;
  stddev?: Maybe<Invoices_Stddev_Order_By>;
  stddev_pop?: Maybe<Invoices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Invoices_Stddev_Samp_Order_By>;
  sum?: Maybe<Invoices_Sum_Order_By>;
  var_pop?: Maybe<Invoices_Var_Pop_Order_By>;
  var_samp?: Maybe<Invoices_Var_Samp_Order_By>;
  variance?: Maybe<Invoices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoices" */
export type Invoices_Arr_Rel_Insert_Input = {
  data: Array<Invoices_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Invoices_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoices_Avg_Fields = {
  __typename?: 'invoices_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoices" */
export type Invoices_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices". All fields are combined with a logical 'AND'. */
export type Invoices_Bool_Exp = {
  _and?: Maybe<Array<Invoices_Bool_Exp>>;
  _not?: Maybe<Invoices_Bool_Exp>;
  _or?: Maybe<Array<Invoices_Bool_Exp>>;
  amount?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  issued_at?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  paid_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
  teacher_id?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "invoices" */
export enum Invoices_Constraint {
  /** unique or primary key constraint */
  InvoicesPkey = 'invoices_pkey'
}

/** input type for incrementing numeric columns in table "invoices" */
export type Invoices_Inc_Input = {
  amount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoices" */
export type Invoices_Insert_Input = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  issued_at?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Invoices_Max_Fields = {
  __typename?: 'invoices_max_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  issued_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoices" */
export type Invoices_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  issued_at?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Invoices_Min_Fields = {
  __typename?: 'invoices_min_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  issued_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoices" */
export type Invoices_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  issued_at?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "invoices" */
export type Invoices_Mutation_Response = {
  __typename?: 'invoices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices>;
};

/** on conflict condition type for table "invoices" */
export type Invoices_On_Conflict = {
  constraint: Invoices_Constraint;
  update_columns?: Array<Invoices_Update_Column>;
  where?: Maybe<Invoices_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices". */
export type Invoices_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  issued_at?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  paid_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: invoices */
export type Invoices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoices" */
export enum Invoices_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  Id = 'id',
  /** column name */
  IssuedAt = 'issued_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoices" */
export type Invoices_Set_Input = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  issued_at?: Maybe<Scalars['timestamptz']>;
  location_id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  paid_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Invoices_Stddev_Fields = {
  __typename?: 'invoices_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoices" */
export type Invoices_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoices_Stddev_Pop_Fields = {
  __typename?: 'invoices_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoices" */
export type Invoices_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoices_Stddev_Samp_Fields = {
  __typename?: 'invoices_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoices" */
export type Invoices_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Invoices_Sum_Fields = {
  __typename?: 'invoices_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoices" */
export type Invoices_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "invoices" */
export enum Invoices_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  Id = 'id',
  /** column name */
  IssuedAt = 'issued_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PaidAt = 'paid_at',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Invoices_Var_Pop_Fields = {
  __typename?: 'invoices_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoices" */
export type Invoices_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoices_Var_Samp_Fields = {
  __typename?: 'invoices_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoices" */
export type Invoices_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoices_Variance_Fields = {
  __typename?: 'invoices_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoices" */
export type Invoices_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "lessons" */
export type Lessons = {
  __typename?: 'lessons';
  created_at: Scalars['timestamptz'];
  /** A computed field, executes function "day_month_year" */
  dmy?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  instrument?: Maybe<Instruments>;
  instrument_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  /** An object relationship */
  plan?: Maybe<Plans>;
  plan_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
  /** fetch data from the table: "student_notes" */
  student_notes: Array<Student_Notes>;
  /** An aggregate relationship */
  student_notes_aggregate: Student_Notes_Aggregate;
  teacher_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};


/** columns and relationships of "lessons" */
export type LessonsStudent_NotesArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


/** columns and relationships of "lessons" */
export type LessonsStudent_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};

/** aggregated selection of "lessons" */
export type Lessons_Aggregate = {
  __typename?: 'lessons_aggregate';
  aggregate?: Maybe<Lessons_Aggregate_Fields>;
  nodes: Array<Lessons>;
};

/** aggregate fields of "lessons" */
export type Lessons_Aggregate_Fields = {
  __typename?: 'lessons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lessons_Max_Fields>;
  min?: Maybe<Lessons_Min_Fields>;
};


/** aggregate fields of "lessons" */
export type Lessons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lessons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lessons" */
export type Lessons_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Lessons_Max_Order_By>;
  min?: Maybe<Lessons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lessons" */
export type Lessons_Arr_Rel_Insert_Input = {
  data: Array<Lessons_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Lessons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lessons". All fields are combined with a logical 'AND'. */
export type Lessons_Bool_Exp = {
  _and?: Maybe<Array<Lessons_Bool_Exp>>;
  _not?: Maybe<Lessons_Bool_Exp>;
  _or?: Maybe<Array<Lessons_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dmy?: Maybe<String_Comparison_Exp>;
  end_time?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instrument?: Maybe<Instruments_Bool_Exp>;
  instrument_id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  plan?: Maybe<Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  start_time?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
  student_notes?: Maybe<Student_Notes_Bool_Exp>;
  teacher_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "lessons" */
export enum Lessons_Constraint {
  /** unique or primary key constraint */
  LessonsPkey = 'lessons_pkey'
}

/** input type for inserting data into table "lessons" */
export type Lessons_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instrument?: Maybe<Instruments_Obj_Rel_Insert_Input>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  plan?: Maybe<Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
  student_notes?: Maybe<Student_Notes_Arr_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lessons_Max_Fields = {
  __typename?: 'lessons_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lessons" */
export type Lessons_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lessons_Min_Fields = {
  __typename?: 'lessons_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lessons" */
export type Lessons_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lessons" */
export type Lessons_Mutation_Response = {
  __typename?: 'lessons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lessons>;
};

/** input type for inserting object relation for remote table "lessons" */
export type Lessons_Obj_Rel_Insert_Input = {
  data: Lessons_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Lessons_On_Conflict>;
};

/** on conflict condition type for table "lessons" */
export type Lessons_On_Conflict = {
  constraint: Lessons_Constraint;
  update_columns?: Array<Lessons_Update_Column>;
  where?: Maybe<Lessons_Bool_Exp>;
};

/** Ordering options when selecting data from "lessons". */
export type Lessons_Order_By = {
  created_at?: Maybe<Order_By>;
  dmy?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instrument?: Maybe<Instruments_Order_By>;
  instrument_id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  plan?: Maybe<Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
  student_notes_aggregate?: Maybe<Student_Notes_Aggregate_Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: lessons */
export type Lessons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lessons" */
export enum Lessons_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lessons" */
export type Lessons_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "lessons" */
export enum Lessons_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  address: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  location_type: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  school_contact?: Maybe<Scalars['String']>;
  school_email?: Maybe<Scalars['String']>;
  /** An array relationship */
  student_locations: Array<Student_Locations>;
  /** An aggregate relationship */
  student_locations_aggregate: Student_Locations_Aggregate;
  /** An array relationship */
  student_plans: Array<Student_Plans>;
  /** An aggregate relationship */
  student_plans_aggregate: Student_Plans_Aggregate;
  /** An array relationship */
  teacher_locations: Array<Teacher_Locations>;
  /** An aggregate relationship */
  teacher_locations_aggregate: Teacher_Locations_Aggregate;
};


/** columns and relationships of "locations" */
export type LocationsInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsLessonsArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsLessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsStudent_LocationsArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsStudent_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsStudent_PlansArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsStudent_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsTeacher_LocationsArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsTeacher_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoices?: Maybe<Invoices_Bool_Exp>;
  lessons?: Maybe<Lessons_Bool_Exp>;
  location_type?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  school_contact?: Maybe<String_Comparison_Exp>;
  school_email?: Maybe<String_Comparison_Exp>;
  student_locations?: Maybe<Student_Locations_Bool_Exp>;
  student_plans?: Maybe<Student_Plans_Bool_Exp>;
  teacher_locations?: Maybe<Teacher_Locations_Bool_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint */
  LocationsPkey = 'locations_pkey'
}

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoices?: Maybe<Invoices_Arr_Rel_Insert_Input>;
  lessons?: Maybe<Lessons_Arr_Rel_Insert_Input>;
  location_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  school_contact?: Maybe<Scalars['String']>;
  school_email?: Maybe<Scalars['String']>;
  student_locations?: Maybe<Student_Locations_Arr_Rel_Insert_Input>;
  student_plans?: Maybe<Student_Plans_Arr_Rel_Insert_Input>;
  teacher_locations?: Maybe<Teacher_Locations_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  school_contact?: Maybe<Scalars['String']>;
  school_email?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  school_contact?: Maybe<Scalars['String']>;
  school_email?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** on conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: Maybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoices_Aggregate_Order_By>;
  lessons_aggregate?: Maybe<Lessons_Aggregate_Order_By>;
  location_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  school_contact?: Maybe<Order_By>;
  school_email?: Maybe<Order_By>;
  student_locations_aggregate?: Maybe<Student_Locations_Aggregate_Order_By>;
  student_plans_aggregate?: Maybe<Student_Plans_Aggregate_Order_By>;
  teacher_locations_aggregate?: Maybe<Teacher_Locations_Aggregate_Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Id = 'id',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  SchoolContact = 'school_contact',
  /** column name */
  SchoolEmail = 'school_email'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  school_contact?: Maybe<Scalars['String']>;
  school_email?: Maybe<Scalars['String']>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Id = 'id',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  SchoolContact = 'school_contact',
  /** column name */
  SchoolEmail = 'school_email'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "instruments" */
  delete_instruments?: Maybe<Instruments_Mutation_Response>;
  /** delete single row from the table: "instruments" */
  delete_instruments_by_pk?: Maybe<Instruments>;
  /** delete data from the table: "invoices" */
  delete_invoices?: Maybe<Invoices_Mutation_Response>;
  /** delete single row from the table: "invoices" */
  delete_invoices_by_pk?: Maybe<Invoices>;
  /** delete data from the table: "lessons" */
  delete_lessons?: Maybe<Lessons_Mutation_Response>;
  /** delete single row from the table: "lessons" */
  delete_lessons_by_pk?: Maybe<Lessons>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "plan_adjustments" */
  delete_plan_adjustments?: Maybe<Plan_Adjustments_Mutation_Response>;
  /** delete single row from the table: "plan_adjustments" */
  delete_plan_adjustments_by_pk?: Maybe<Plan_Adjustments>;
  /** delete data from the table: "plans" */
  delete_plans?: Maybe<Plans_Mutation_Response>;
  /** delete single row from the table: "plans" */
  delete_plans_by_pk?: Maybe<Plans>;
  /** delete data from the table: "student_instruments" */
  delete_student_instruments?: Maybe<Student_Instruments_Mutation_Response>;
  /** delete single row from the table: "student_instruments" */
  delete_student_instruments_by_pk?: Maybe<Student_Instruments>;
  /** delete data from the table: "student_locations" */
  delete_student_locations?: Maybe<Student_Locations_Mutation_Response>;
  /** delete single row from the table: "student_locations" */
  delete_student_locations_by_pk?: Maybe<Student_Locations>;
  /** delete data from the table: "student_notes" */
  delete_student_notes?: Maybe<Student_Notes_Mutation_Response>;
  /** delete single row from the table: "student_notes" */
  delete_student_notes_by_pk?: Maybe<Student_Notes>;
  /** delete data from the table: "student_plans" */
  delete_student_plans?: Maybe<Student_Plans_Mutation_Response>;
  /** delete single row from the table: "student_plans" */
  delete_student_plans_by_pk?: Maybe<Student_Plans>;
  /** delete data from the table: "student_teachers" */
  delete_student_teachers?: Maybe<Student_Teachers_Mutation_Response>;
  /** delete single row from the table: "student_teachers" */
  delete_student_teachers_by_pk?: Maybe<Student_Teachers>;
  /** delete data from the table: "student_users" */
  delete_student_users?: Maybe<Student_Users_Mutation_Response>;
  /** delete single row from the table: "student_users" */
  delete_student_users_by_pk?: Maybe<Student_Users>;
  /** delete data from the table: "students" */
  delete_students?: Maybe<Students_Mutation_Response>;
  /** delete single row from the table: "students" */
  delete_students_by_pk?: Maybe<Students>;
  /** delete data from the table: "teacher_instruments" */
  delete_teacher_instruments?: Maybe<Teacher_Instruments_Mutation_Response>;
  /** delete single row from the table: "teacher_instruments" */
  delete_teacher_instruments_by_pk?: Maybe<Teacher_Instruments>;
  /** delete data from the table: "teacher_locations" */
  delete_teacher_locations?: Maybe<Teacher_Locations_Mutation_Response>;
  /** delete single row from the table: "teacher_locations" */
  delete_teacher_locations_by_pk?: Maybe<Teacher_Locations>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "instruments" */
  insert_instruments?: Maybe<Instruments_Mutation_Response>;
  /** insert a single row into the table: "instruments" */
  insert_instruments_one?: Maybe<Instruments>;
  /** insert data into the table: "invoices" */
  insert_invoices?: Maybe<Invoices_Mutation_Response>;
  /** insert a single row into the table: "invoices" */
  insert_invoices_one?: Maybe<Invoices>;
  /** insert data into the table: "lessons" */
  insert_lessons?: Maybe<Lessons_Mutation_Response>;
  /** insert a single row into the table: "lessons" */
  insert_lessons_one?: Maybe<Lessons>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "plan_adjustments" */
  insert_plan_adjustments?: Maybe<Plan_Adjustments_Mutation_Response>;
  /** insert a single row into the table: "plan_adjustments" */
  insert_plan_adjustments_one?: Maybe<Plan_Adjustments>;
  /** insert data into the table: "plans" */
  insert_plans?: Maybe<Plans_Mutation_Response>;
  /** insert a single row into the table: "plans" */
  insert_plans_one?: Maybe<Plans>;
  /** insert data into the table: "student_instruments" */
  insert_student_instruments?: Maybe<Student_Instruments_Mutation_Response>;
  /** insert a single row into the table: "student_instruments" */
  insert_student_instruments_one?: Maybe<Student_Instruments>;
  /** insert data into the table: "student_locations" */
  insert_student_locations?: Maybe<Student_Locations_Mutation_Response>;
  /** insert a single row into the table: "student_locations" */
  insert_student_locations_one?: Maybe<Student_Locations>;
  /** insert data into the table: "student_notes" */
  insert_student_notes?: Maybe<Student_Notes_Mutation_Response>;
  /** insert a single row into the table: "student_notes" */
  insert_student_notes_one?: Maybe<Student_Notes>;
  /** insert data into the table: "student_plans" */
  insert_student_plans?: Maybe<Student_Plans_Mutation_Response>;
  /** insert a single row into the table: "student_plans" */
  insert_student_plans_one?: Maybe<Student_Plans>;
  /** insert data into the table: "student_teachers" */
  insert_student_teachers?: Maybe<Student_Teachers_Mutation_Response>;
  /** insert a single row into the table: "student_teachers" */
  insert_student_teachers_one?: Maybe<Student_Teachers>;
  /** insert data into the table: "student_users" */
  insert_student_users?: Maybe<Student_Users_Mutation_Response>;
  /** insert a single row into the table: "student_users" */
  insert_student_users_one?: Maybe<Student_Users>;
  /** insert data into the table: "students" */
  insert_students?: Maybe<Students_Mutation_Response>;
  /** insert a single row into the table: "students" */
  insert_students_one?: Maybe<Students>;
  /** insert data into the table: "teacher_instruments" */
  insert_teacher_instruments?: Maybe<Teacher_Instruments_Mutation_Response>;
  /** insert a single row into the table: "teacher_instruments" */
  insert_teacher_instruments_one?: Maybe<Teacher_Instruments>;
  /** insert data into the table: "teacher_locations" */
  insert_teacher_locations?: Maybe<Teacher_Locations_Mutation_Response>;
  /** insert a single row into the table: "teacher_locations" */
  insert_teacher_locations_one?: Maybe<Teacher_Locations>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "instruments" */
  update_instruments?: Maybe<Instruments_Mutation_Response>;
  /** update single row of the table: "instruments" */
  update_instruments_by_pk?: Maybe<Instruments>;
  /** update data of the table: "invoices" */
  update_invoices?: Maybe<Invoices_Mutation_Response>;
  /** update single row of the table: "invoices" */
  update_invoices_by_pk?: Maybe<Invoices>;
  /** update data of the table: "lessons" */
  update_lessons?: Maybe<Lessons_Mutation_Response>;
  /** update single row of the table: "lessons" */
  update_lessons_by_pk?: Maybe<Lessons>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update data of the table: "plan_adjustments" */
  update_plan_adjustments?: Maybe<Plan_Adjustments_Mutation_Response>;
  /** update single row of the table: "plan_adjustments" */
  update_plan_adjustments_by_pk?: Maybe<Plan_Adjustments>;
  /** update data of the table: "plans" */
  update_plans?: Maybe<Plans_Mutation_Response>;
  /** update single row of the table: "plans" */
  update_plans_by_pk?: Maybe<Plans>;
  /** update data of the table: "student_instruments" */
  update_student_instruments?: Maybe<Student_Instruments_Mutation_Response>;
  /** update single row of the table: "student_instruments" */
  update_student_instruments_by_pk?: Maybe<Student_Instruments>;
  /** update data of the table: "student_locations" */
  update_student_locations?: Maybe<Student_Locations_Mutation_Response>;
  /** update single row of the table: "student_locations" */
  update_student_locations_by_pk?: Maybe<Student_Locations>;
  /** update data of the table: "student_notes" */
  update_student_notes?: Maybe<Student_Notes_Mutation_Response>;
  /** update single row of the table: "student_notes" */
  update_student_notes_by_pk?: Maybe<Student_Notes>;
  /** update data of the table: "student_plans" */
  update_student_plans?: Maybe<Student_Plans_Mutation_Response>;
  /** update single row of the table: "student_plans" */
  update_student_plans_by_pk?: Maybe<Student_Plans>;
  /** update data of the table: "student_teachers" */
  update_student_teachers?: Maybe<Student_Teachers_Mutation_Response>;
  /** update single row of the table: "student_teachers" */
  update_student_teachers_by_pk?: Maybe<Student_Teachers>;
  /** update data of the table: "student_users" */
  update_student_users?: Maybe<Student_Users_Mutation_Response>;
  /** update single row of the table: "student_users" */
  update_student_users_by_pk?: Maybe<Student_Users>;
  /** update data of the table: "students" */
  update_students?: Maybe<Students_Mutation_Response>;
  /** update single row of the table: "students" */
  update_students_by_pk?: Maybe<Students>;
  /** update data of the table: "teacher_instruments" */
  update_teacher_instruments?: Maybe<Teacher_Instruments_Mutation_Response>;
  /** update single row of the table: "teacher_instruments" */
  update_teacher_instruments_by_pk?: Maybe<Teacher_Instruments>;
  /** update data of the table: "teacher_locations" */
  update_teacher_locations?: Maybe<Teacher_Locations_Mutation_Response>;
  /** update single row of the table: "teacher_locations" */
  update_teacher_locations_by_pk?: Maybe<Teacher_Locations>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_InstrumentsArgs = {
  where: Instruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Instruments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InvoicesArgs = {
  where: Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LessonsArgs = {
  where: Lessons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lessons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Plan_AdjustmentsArgs = {
  where: Plan_Adjustments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plan_Adjustments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PlansArgs = {
  where: Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Student_InstrumentsArgs = {
  where: Student_Instruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Instruments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Student_LocationsArgs = {
  where: Student_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Student_NotesArgs = {
  where: Student_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Student_PlansArgs = {
  where: Student_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Student_TeachersArgs = {
  where: Student_Teachers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Teachers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Student_UsersArgs = {
  where: Student_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StudentsArgs = {
  where: Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Students_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_InstrumentsArgs = {
  where: Teacher_Instruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Instruments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_LocationsArgs = {
  where: Teacher_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_InstrumentsArgs = {
  objects: Array<Instruments_Insert_Input>;
  on_conflict?: Maybe<Instruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Instruments_OneArgs = {
  object: Instruments_Insert_Input;
  on_conflict?: Maybe<Instruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvoicesArgs = {
  objects: Array<Invoices_Insert_Input>;
  on_conflict?: Maybe<Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_OneArgs = {
  object: Invoices_Insert_Input;
  on_conflict?: Maybe<Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LessonsArgs = {
  objects: Array<Lessons_Insert_Input>;
  on_conflict?: Maybe<Lessons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lessons_OneArgs = {
  object: Lessons_Insert_Input;
  on_conflict?: Maybe<Lessons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: Maybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_AdjustmentsArgs = {
  objects: Array<Plan_Adjustments_Insert_Input>;
  on_conflict?: Maybe<Plan_Adjustments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plan_Adjustments_OneArgs = {
  object: Plan_Adjustments_Insert_Input;
  on_conflict?: Maybe<Plan_Adjustments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlansArgs = {
  objects: Array<Plans_Insert_Input>;
  on_conflict?: Maybe<Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plans_OneArgs = {
  object: Plans_Insert_Input;
  on_conflict?: Maybe<Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_InstrumentsArgs = {
  objects: Array<Student_Instruments_Insert_Input>;
  on_conflict?: Maybe<Student_Instruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Instruments_OneArgs = {
  object: Student_Instruments_Insert_Input;
  on_conflict?: Maybe<Student_Instruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_LocationsArgs = {
  objects: Array<Student_Locations_Insert_Input>;
  on_conflict?: Maybe<Student_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Locations_OneArgs = {
  object: Student_Locations_Insert_Input;
  on_conflict?: Maybe<Student_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_NotesArgs = {
  objects: Array<Student_Notes_Insert_Input>;
  on_conflict?: Maybe<Student_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Notes_OneArgs = {
  object: Student_Notes_Insert_Input;
  on_conflict?: Maybe<Student_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_PlansArgs = {
  objects: Array<Student_Plans_Insert_Input>;
  on_conflict?: Maybe<Student_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Plans_OneArgs = {
  object: Student_Plans_Insert_Input;
  on_conflict?: Maybe<Student_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_TeachersArgs = {
  objects: Array<Student_Teachers_Insert_Input>;
  on_conflict?: Maybe<Student_Teachers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Teachers_OneArgs = {
  object: Student_Teachers_Insert_Input;
  on_conflict?: Maybe<Student_Teachers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_UsersArgs = {
  objects: Array<Student_Users_Insert_Input>;
  on_conflict?: Maybe<Student_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Users_OneArgs = {
  object: Student_Users_Insert_Input;
  on_conflict?: Maybe<Student_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StudentsArgs = {
  objects: Array<Students_Insert_Input>;
  on_conflict?: Maybe<Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Students_OneArgs = {
  object: Students_Insert_Input;
  on_conflict?: Maybe<Students_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_InstrumentsArgs = {
  objects: Array<Teacher_Instruments_Insert_Input>;
  on_conflict?: Maybe<Teacher_Instruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Instruments_OneArgs = {
  object: Teacher_Instruments_Insert_Input;
  on_conflict?: Maybe<Teacher_Instruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_LocationsArgs = {
  objects: Array<Teacher_Locations_Insert_Input>;
  on_conflict?: Maybe<Teacher_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Locations_OneArgs = {
  object: Teacher_Locations_Insert_Input;
  on_conflict?: Maybe<Teacher_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_InstrumentsArgs = {
  _set?: Maybe<Instruments_Set_Input>;
  where: Instruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Instruments_By_PkArgs = {
  _set?: Maybe<Instruments_Set_Input>;
  pk_columns: Instruments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InvoicesArgs = {
  _inc?: Maybe<Invoices_Inc_Input>;
  _set?: Maybe<Invoices_Set_Input>;
  where: Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_By_PkArgs = {
  _inc?: Maybe<Invoices_Inc_Input>;
  _set?: Maybe<Invoices_Set_Input>;
  pk_columns: Invoices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LessonsArgs = {
  _set?: Maybe<Lessons_Set_Input>;
  where: Lessons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lessons_By_PkArgs = {
  _set?: Maybe<Lessons_Set_Input>;
  pk_columns: Lessons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _set?: Maybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _set?: Maybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_AdjustmentsArgs = {
  _inc?: Maybe<Plan_Adjustments_Inc_Input>;
  _set?: Maybe<Plan_Adjustments_Set_Input>;
  where: Plan_Adjustments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plan_Adjustments_By_PkArgs = {
  _inc?: Maybe<Plan_Adjustments_Inc_Input>;
  _set?: Maybe<Plan_Adjustments_Set_Input>;
  pk_columns: Plan_Adjustments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PlansArgs = {
  _inc?: Maybe<Plans_Inc_Input>;
  _set?: Maybe<Plans_Set_Input>;
  where: Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plans_By_PkArgs = {
  _inc?: Maybe<Plans_Inc_Input>;
  _set?: Maybe<Plans_Set_Input>;
  pk_columns: Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_InstrumentsArgs = {
  _set?: Maybe<Student_Instruments_Set_Input>;
  where: Student_Instruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Instruments_By_PkArgs = {
  _set?: Maybe<Student_Instruments_Set_Input>;
  pk_columns: Student_Instruments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_LocationsArgs = {
  _set?: Maybe<Student_Locations_Set_Input>;
  where: Student_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Locations_By_PkArgs = {
  _set?: Maybe<Student_Locations_Set_Input>;
  pk_columns: Student_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_NotesArgs = {
  _set?: Maybe<Student_Notes_Set_Input>;
  where: Student_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Notes_By_PkArgs = {
  _set?: Maybe<Student_Notes_Set_Input>;
  pk_columns: Student_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_PlansArgs = {
  _inc?: Maybe<Student_Plans_Inc_Input>;
  _set?: Maybe<Student_Plans_Set_Input>;
  where: Student_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Plans_By_PkArgs = {
  _inc?: Maybe<Student_Plans_Inc_Input>;
  _set?: Maybe<Student_Plans_Set_Input>;
  pk_columns: Student_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_TeachersArgs = {
  _set?: Maybe<Student_Teachers_Set_Input>;
  where: Student_Teachers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Teachers_By_PkArgs = {
  _set?: Maybe<Student_Teachers_Set_Input>;
  pk_columns: Student_Teachers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_UsersArgs = {
  _set?: Maybe<Student_Users_Set_Input>;
  where: Student_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Users_By_PkArgs = {
  _set?: Maybe<Student_Users_Set_Input>;
  pk_columns: Student_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StudentsArgs = {
  _inc?: Maybe<Students_Inc_Input>;
  _set?: Maybe<Students_Set_Input>;
  where: Students_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Students_By_PkArgs = {
  _inc?: Maybe<Students_Inc_Input>;
  _set?: Maybe<Students_Set_Input>;
  pk_columns: Students_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_InstrumentsArgs = {
  _set?: Maybe<Teacher_Instruments_Set_Input>;
  where: Teacher_Instruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Instruments_By_PkArgs = {
  _set?: Maybe<Teacher_Instruments_Set_Input>;
  pk_columns: Teacher_Instruments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_LocationsArgs = {
  _set?: Maybe<Teacher_Locations_Set_Input>;
  where: Teacher_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Locations_By_PkArgs = {
  _set?: Maybe<Teacher_Locations_Set_Input>;
  pk_columns: Teacher_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "plan_adjustments" */
export type Plan_Adjustments = {
  __typename?: 'plan_adjustments';
  /** An object relationship */
  audit_user: Users;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  modified_by_id: Scalars['String'];
  next_count: Scalars['Int'];
  /** An object relationship */
  plan: Plans;
  plan_id: Scalars['uuid'];
  prev_count: Scalars['Int'];
  reason: Scalars['String'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
  teacher_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "plan_adjustments" */
export type Plan_Adjustments_Aggregate = {
  __typename?: 'plan_adjustments_aggregate';
  aggregate?: Maybe<Plan_Adjustments_Aggregate_Fields>;
  nodes: Array<Plan_Adjustments>;
};

/** aggregate fields of "plan_adjustments" */
export type Plan_Adjustments_Aggregate_Fields = {
  __typename?: 'plan_adjustments_aggregate_fields';
  avg?: Maybe<Plan_Adjustments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Plan_Adjustments_Max_Fields>;
  min?: Maybe<Plan_Adjustments_Min_Fields>;
  stddev?: Maybe<Plan_Adjustments_Stddev_Fields>;
  stddev_pop?: Maybe<Plan_Adjustments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plan_Adjustments_Stddev_Samp_Fields>;
  sum?: Maybe<Plan_Adjustments_Sum_Fields>;
  var_pop?: Maybe<Plan_Adjustments_Var_Pop_Fields>;
  var_samp?: Maybe<Plan_Adjustments_Var_Samp_Fields>;
  variance?: Maybe<Plan_Adjustments_Variance_Fields>;
};


/** aggregate fields of "plan_adjustments" */
export type Plan_Adjustments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plan_adjustments" */
export type Plan_Adjustments_Aggregate_Order_By = {
  avg?: Maybe<Plan_Adjustments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Plan_Adjustments_Max_Order_By>;
  min?: Maybe<Plan_Adjustments_Min_Order_By>;
  stddev?: Maybe<Plan_Adjustments_Stddev_Order_By>;
  stddev_pop?: Maybe<Plan_Adjustments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Plan_Adjustments_Stddev_Samp_Order_By>;
  sum?: Maybe<Plan_Adjustments_Sum_Order_By>;
  var_pop?: Maybe<Plan_Adjustments_Var_Pop_Order_By>;
  var_samp?: Maybe<Plan_Adjustments_Var_Samp_Order_By>;
  variance?: Maybe<Plan_Adjustments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plan_adjustments" */
export type Plan_Adjustments_Arr_Rel_Insert_Input = {
  data: Array<Plan_Adjustments_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Plan_Adjustments_On_Conflict>;
};

/** aggregate avg on columns */
export type Plan_Adjustments_Avg_Fields = {
  __typename?: 'plan_adjustments_avg_fields';
  next_count?: Maybe<Scalars['Float']>;
  prev_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Avg_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plan_adjustments". All fields are combined with a logical 'AND'. */
export type Plan_Adjustments_Bool_Exp = {
  _and?: Maybe<Array<Plan_Adjustments_Bool_Exp>>;
  _not?: Maybe<Plan_Adjustments_Bool_Exp>;
  _or?: Maybe<Array<Plan_Adjustments_Bool_Exp>>;
  audit_user?: Maybe<Users_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  modified_by_id?: Maybe<String_Comparison_Exp>;
  next_count?: Maybe<Int_Comparison_Exp>;
  plan?: Maybe<Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  prev_count?: Maybe<Int_Comparison_Exp>;
  reason?: Maybe<String_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
  teacher_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "plan_adjustments" */
export enum Plan_Adjustments_Constraint {
  /** unique or primary key constraint */
  PlanAdjustmentsPkey = 'plan_adjustments_pkey'
}

/** input type for incrementing numeric columns in table "plan_adjustments" */
export type Plan_Adjustments_Inc_Input = {
  next_count?: Maybe<Scalars['Int']>;
  prev_count?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "plan_adjustments" */
export type Plan_Adjustments_Insert_Input = {
  audit_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  modified_by_id?: Maybe<Scalars['String']>;
  next_count?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  prev_count?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Plan_Adjustments_Max_Fields = {
  __typename?: 'plan_adjustments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  modified_by_id?: Maybe<Scalars['String']>;
  next_count?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  prev_count?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by_id?: Maybe<Order_By>;
  next_count?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Adjustments_Min_Fields = {
  __typename?: 'plan_adjustments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  modified_by_id?: Maybe<Scalars['String']>;
  next_count?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  prev_count?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by_id?: Maybe<Order_By>;
  next_count?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "plan_adjustments" */
export type Plan_Adjustments_Mutation_Response = {
  __typename?: 'plan_adjustments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan_Adjustments>;
};

/** on conflict condition type for table "plan_adjustments" */
export type Plan_Adjustments_On_Conflict = {
  constraint: Plan_Adjustments_Constraint;
  update_columns?: Array<Plan_Adjustments_Update_Column>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};

/** Ordering options when selecting data from "plan_adjustments". */
export type Plan_Adjustments_Order_By = {
  audit_user?: Maybe<Users_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by_id?: Maybe<Order_By>;
  next_count?: Maybe<Order_By>;
  plan?: Maybe<Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: plan_adjustments */
export type Plan_Adjustments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "plan_adjustments" */
export enum Plan_Adjustments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedById = 'modified_by_id',
  /** column name */
  NextCount = 'next_count',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  PrevCount = 'prev_count',
  /** column name */
  Reason = 'reason',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "plan_adjustments" */
export type Plan_Adjustments_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  modified_by_id?: Maybe<Scalars['String']>;
  next_count?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['uuid']>;
  prev_count?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Plan_Adjustments_Stddev_Fields = {
  __typename?: 'plan_adjustments_stddev_fields';
  next_count?: Maybe<Scalars['Float']>;
  prev_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Stddev_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plan_Adjustments_Stddev_Pop_Fields = {
  __typename?: 'plan_adjustments_stddev_pop_fields';
  next_count?: Maybe<Scalars['Float']>;
  prev_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Stddev_Pop_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plan_Adjustments_Stddev_Samp_Fields = {
  __typename?: 'plan_adjustments_stddev_samp_fields';
  next_count?: Maybe<Scalars['Float']>;
  prev_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Stddev_Samp_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Plan_Adjustments_Sum_Fields = {
  __typename?: 'plan_adjustments_sum_fields';
  next_count?: Maybe<Scalars['Int']>;
  prev_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Sum_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** update columns of table "plan_adjustments" */
export enum Plan_Adjustments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedById = 'modified_by_id',
  /** column name */
  NextCount = 'next_count',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  PrevCount = 'prev_count',
  /** column name */
  Reason = 'reason',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Plan_Adjustments_Var_Pop_Fields = {
  __typename?: 'plan_adjustments_var_pop_fields';
  next_count?: Maybe<Scalars['Float']>;
  prev_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Var_Pop_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plan_Adjustments_Var_Samp_Fields = {
  __typename?: 'plan_adjustments_var_samp_fields';
  next_count?: Maybe<Scalars['Float']>;
  prev_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Var_Samp_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Plan_Adjustments_Variance_Fields = {
  __typename?: 'plan_adjustments_variance_fields';
  next_count?: Maybe<Scalars['Float']>;
  prev_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "plan_adjustments" */
export type Plan_Adjustments_Variance_Order_By = {
  next_count?: Maybe<Order_By>;
  prev_count?: Maybe<Order_By>;
};

/** columns and relationships of "plans" */
export type Plans = {
  __typename?: 'plans';
  active: Scalars['Boolean'];
  allocation_type: Scalars['String'];
  id: Scalars['uuid'];
  lessonCount: Scalars['Int'];
  lessonCountThreshold: Scalars['Int'];
  lessonDuration: Scalars['Int'];
  lessonPrice: Scalars['Int'];
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  name: Scalars['String'];
  nextPlanId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  plan_adjustments: Array<Plan_Adjustments>;
  /** An aggregate relationship */
  plan_adjustments_aggregate: Plan_Adjustments_Aggregate;
  prettyName: Scalars['String'];
  /** An array relationship */
  student_plans: Array<Student_Plans>;
  /** An aggregate relationship */
  student_plans_aggregate: Student_Plans_Aggregate;
};


/** columns and relationships of "plans" */
export type PlansLessonsArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansLessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansPlan_AdjustmentsArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansPlan_Adjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansStudent_PlansArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "plans" */
export type PlansStudent_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};

/** aggregated selection of "plans" */
export type Plans_Aggregate = {
  __typename?: 'plans_aggregate';
  aggregate?: Maybe<Plans_Aggregate_Fields>;
  nodes: Array<Plans>;
};

/** aggregate fields of "plans" */
export type Plans_Aggregate_Fields = {
  __typename?: 'plans_aggregate_fields';
  avg?: Maybe<Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Plans_Max_Fields>;
  min?: Maybe<Plans_Min_Fields>;
  stddev?: Maybe<Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Plans_Sum_Fields>;
  var_pop?: Maybe<Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Plans_Var_Samp_Fields>;
  variance?: Maybe<Plans_Variance_Fields>;
};


/** aggregate fields of "plans" */
export type Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Plans_Avg_Fields = {
  __typename?: 'plans_avg_fields';
  lessonCount?: Maybe<Scalars['Float']>;
  lessonCountThreshold?: Maybe<Scalars['Float']>;
  lessonDuration?: Maybe<Scalars['Float']>;
  lessonPrice?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type Plans_Bool_Exp = {
  _and?: Maybe<Array<Plans_Bool_Exp>>;
  _not?: Maybe<Plans_Bool_Exp>;
  _or?: Maybe<Array<Plans_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  allocation_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lessonCount?: Maybe<Int_Comparison_Exp>;
  lessonCountThreshold?: Maybe<Int_Comparison_Exp>;
  lessonDuration?: Maybe<Int_Comparison_Exp>;
  lessonPrice?: Maybe<Int_Comparison_Exp>;
  lessons?: Maybe<Lessons_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  nextPlanId?: Maybe<Uuid_Comparison_Exp>;
  plan_adjustments?: Maybe<Plan_Adjustments_Bool_Exp>;
  prettyName?: Maybe<String_Comparison_Exp>;
  student_plans?: Maybe<Student_Plans_Bool_Exp>;
};

/** unique or primary key constraints on table "plans" */
export enum Plans_Constraint {
  /** unique or primary key constraint */
  PlansPkey = 'plans_pkey'
}

/** input type for incrementing numeric columns in table "plans" */
export type Plans_Inc_Input = {
  lessonCount?: Maybe<Scalars['Int']>;
  lessonCountThreshold?: Maybe<Scalars['Int']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  lessonPrice?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "plans" */
export type Plans_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  allocation_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lessonCount?: Maybe<Scalars['Int']>;
  lessonCountThreshold?: Maybe<Scalars['Int']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  lessonPrice?: Maybe<Scalars['Int']>;
  lessons?: Maybe<Lessons_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  nextPlanId?: Maybe<Scalars['uuid']>;
  plan_adjustments?: Maybe<Plan_Adjustments_Arr_Rel_Insert_Input>;
  prettyName?: Maybe<Scalars['String']>;
  student_plans?: Maybe<Student_Plans_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Plans_Max_Fields = {
  __typename?: 'plans_max_fields';
  allocation_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lessonCount?: Maybe<Scalars['Int']>;
  lessonCountThreshold?: Maybe<Scalars['Int']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  lessonPrice?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nextPlanId?: Maybe<Scalars['uuid']>;
  prettyName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Plans_Min_Fields = {
  __typename?: 'plans_min_fields';
  allocation_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lessonCount?: Maybe<Scalars['Int']>;
  lessonCountThreshold?: Maybe<Scalars['Int']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  lessonPrice?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nextPlanId?: Maybe<Scalars['uuid']>;
  prettyName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "plans" */
export type Plans_Mutation_Response = {
  __typename?: 'plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plans>;
};

/** input type for inserting object relation for remote table "plans" */
export type Plans_Obj_Rel_Insert_Input = {
  data: Plans_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Plans_On_Conflict>;
};

/** on conflict condition type for table "plans" */
export type Plans_On_Conflict = {
  constraint: Plans_Constraint;
  update_columns?: Array<Plans_Update_Column>;
  where?: Maybe<Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "plans". */
export type Plans_Order_By = {
  active?: Maybe<Order_By>;
  allocation_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lessonCount?: Maybe<Order_By>;
  lessonCountThreshold?: Maybe<Order_By>;
  lessonDuration?: Maybe<Order_By>;
  lessonPrice?: Maybe<Order_By>;
  lessons_aggregate?: Maybe<Lessons_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  nextPlanId?: Maybe<Order_By>;
  plan_adjustments_aggregate?: Maybe<Plan_Adjustments_Aggregate_Order_By>;
  prettyName?: Maybe<Order_By>;
  student_plans_aggregate?: Maybe<Student_Plans_Aggregate_Order_By>;
};

/** primary key columns input for table: plans */
export type Plans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "plans" */
export enum Plans_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllocationType = 'allocation_type',
  /** column name */
  Id = 'id',
  /** column name */
  LessonCount = 'lessonCount',
  /** column name */
  LessonCountThreshold = 'lessonCountThreshold',
  /** column name */
  LessonDuration = 'lessonDuration',
  /** column name */
  LessonPrice = 'lessonPrice',
  /** column name */
  Name = 'name',
  /** column name */
  NextPlanId = 'nextPlanId',
  /** column name */
  PrettyName = 'prettyName'
}

/** input type for updating data in table "plans" */
export type Plans_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  allocation_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lessonCount?: Maybe<Scalars['Int']>;
  lessonCountThreshold?: Maybe<Scalars['Int']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  lessonPrice?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nextPlanId?: Maybe<Scalars['uuid']>;
  prettyName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Plans_Stddev_Fields = {
  __typename?: 'plans_stddev_fields';
  lessonCount?: Maybe<Scalars['Float']>;
  lessonCountThreshold?: Maybe<Scalars['Float']>;
  lessonDuration?: Maybe<Scalars['Float']>;
  lessonPrice?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Plans_Stddev_Pop_Fields = {
  __typename?: 'plans_stddev_pop_fields';
  lessonCount?: Maybe<Scalars['Float']>;
  lessonCountThreshold?: Maybe<Scalars['Float']>;
  lessonDuration?: Maybe<Scalars['Float']>;
  lessonPrice?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Plans_Stddev_Samp_Fields = {
  __typename?: 'plans_stddev_samp_fields';
  lessonCount?: Maybe<Scalars['Float']>;
  lessonCountThreshold?: Maybe<Scalars['Float']>;
  lessonDuration?: Maybe<Scalars['Float']>;
  lessonPrice?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Plans_Sum_Fields = {
  __typename?: 'plans_sum_fields';
  lessonCount?: Maybe<Scalars['Int']>;
  lessonCountThreshold?: Maybe<Scalars['Int']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  lessonPrice?: Maybe<Scalars['Int']>;
};

/** update columns of table "plans" */
export enum Plans_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllocationType = 'allocation_type',
  /** column name */
  Id = 'id',
  /** column name */
  LessonCount = 'lessonCount',
  /** column name */
  LessonCountThreshold = 'lessonCountThreshold',
  /** column name */
  LessonDuration = 'lessonDuration',
  /** column name */
  LessonPrice = 'lessonPrice',
  /** column name */
  Name = 'name',
  /** column name */
  NextPlanId = 'nextPlanId',
  /** column name */
  PrettyName = 'prettyName'
}

/** aggregate var_pop on columns */
export type Plans_Var_Pop_Fields = {
  __typename?: 'plans_var_pop_fields';
  lessonCount?: Maybe<Scalars['Float']>;
  lessonCountThreshold?: Maybe<Scalars['Float']>;
  lessonDuration?: Maybe<Scalars['Float']>;
  lessonPrice?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Plans_Var_Samp_Fields = {
  __typename?: 'plans_var_samp_fields';
  lessonCount?: Maybe<Scalars['Float']>;
  lessonCountThreshold?: Maybe<Scalars['Float']>;
  lessonDuration?: Maybe<Scalars['Float']>;
  lessonPrice?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Plans_Variance_Fields = {
  __typename?: 'plans_variance_fields';
  lessonCount?: Maybe<Scalars['Float']>;
  lessonCountThreshold?: Maybe<Scalars['Float']>;
  lessonDuration?: Maybe<Scalars['Float']>;
  lessonPrice?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "instruments" */
  instruments: Array<Instruments>;
  /** fetch aggregated fields from the table: "instruments" */
  instruments_aggregate: Instruments_Aggregate;
  /** fetch data from the table: "instruments" using primary key columns */
  instruments_by_pk?: Maybe<Instruments>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  /** fetch data from the table: "lessons" using primary key columns */
  lessons_by_pk?: Maybe<Lessons>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  plan_adjustments: Array<Plan_Adjustments>;
  /** An aggregate relationship */
  plan_adjustments_aggregate: Plan_Adjustments_Aggregate;
  /** fetch data from the table: "plan_adjustments" using primary key columns */
  plan_adjustments_by_pk?: Maybe<Plan_Adjustments>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** An array relationship */
  student_instruments: Array<Student_Instruments>;
  /** An aggregate relationship */
  student_instruments_aggregate: Student_Instruments_Aggregate;
  /** fetch data from the table: "student_instruments" using primary key columns */
  student_instruments_by_pk?: Maybe<Student_Instruments>;
  /** An array relationship */
  student_locations: Array<Student_Locations>;
  /** An aggregate relationship */
  student_locations_aggregate: Student_Locations_Aggregate;
  /** fetch data from the table: "student_locations" using primary key columns */
  student_locations_by_pk?: Maybe<Student_Locations>;
  /** fetch data from the table: "student_notes" */
  student_notes: Array<Student_Notes>;
  /** An aggregate relationship */
  student_notes_aggregate: Student_Notes_Aggregate;
  /** fetch data from the table: "student_notes" using primary key columns */
  student_notes_by_pk?: Maybe<Student_Notes>;
  /** An array relationship */
  student_plans: Array<Student_Plans>;
  /** An aggregate relationship */
  student_plans_aggregate: Student_Plans_Aggregate;
  /** fetch data from the table: "student_plans" using primary key columns */
  student_plans_by_pk?: Maybe<Student_Plans>;
  /** An array relationship */
  student_teachers: Array<Student_Teachers>;
  /** An aggregate relationship */
  student_teachers_aggregate: Student_Teachers_Aggregate;
  /** fetch data from the table: "student_teachers" using primary key columns */
  student_teachers_by_pk?: Maybe<Student_Teachers>;
  /** An array relationship */
  student_users: Array<Student_Users>;
  /** An aggregate relationship */
  student_users_aggregate: Student_Users_Aggregate;
  /** fetch data from the table: "student_users" using primary key columns */
  student_users_by_pk?: Maybe<Student_Users>;
  /** fetch data from the table: "students" */
  students: Array<Students>;
  /** fetch aggregated fields from the table: "students" */
  students_aggregate: Students_Aggregate;
  /** fetch data from the table: "students" using primary key columns */
  students_by_pk?: Maybe<Students>;
  /** An array relationship */
  teacher_instruments: Array<Teacher_Instruments>;
  /** An aggregate relationship */
  teacher_instruments_aggregate: Teacher_Instruments_Aggregate;
  /** fetch data from the table: "teacher_instruments" using primary key columns */
  teacher_instruments_by_pk?: Maybe<Teacher_Instruments>;
  /** An array relationship */
  teacher_locations: Array<Teacher_Locations>;
  /** An aggregate relationship */
  teacher_locations_aggregate: Teacher_Locations_Aggregate;
  /** fetch data from the table: "teacher_locations" using primary key columns */
  teacher_locations_by_pk?: Maybe<Teacher_Locations>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootInstrumentsArgs = {
  distinct_on?: Maybe<Array<Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Instruments_Order_By>>;
  where?: Maybe<Instruments_Bool_Exp>;
};


export type Query_RootInstruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Instruments_Order_By>>;
  where?: Maybe<Instruments_Bool_Exp>;
};


export type Query_RootInstruments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Query_RootInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Query_RootInvoices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLessonsArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


export type Query_RootLessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


export type Query_RootLessons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPlan_AdjustmentsArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


export type Query_RootPlan_Adjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


export type Query_RootPlan_Adjustments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPlansArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Query_RootPlans_AggregateArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Query_RootPlans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudent_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


export type Query_RootStudent_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


export type Query_RootStudent_Instruments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudent_LocationsArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


export type Query_RootStudent_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


export type Query_RootStudent_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudent_NotesArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


export type Query_RootStudent_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


export type Query_RootStudent_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudent_PlansArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


export type Query_RootStudent_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


export type Query_RootStudent_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudent_TeachersArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


export type Query_RootStudent_Teachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


export type Query_RootStudent_Teachers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudent_UsersArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};


export type Query_RootStudent_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};


export type Query_RootStudent_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudentsArgs = {
  distinct_on?: Maybe<Array<Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Students_Order_By>>;
  where?: Maybe<Students_Bool_Exp>;
};


export type Query_RootStudents_AggregateArgs = {
  distinct_on?: Maybe<Array<Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Students_Order_By>>;
  where?: Maybe<Students_Bool_Exp>;
};


export type Query_RootStudents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeacher_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};


export type Query_RootTeacher_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};


export type Query_RootTeacher_Instruments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeacher_LocationsArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};


export type Query_RootTeacher_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};


export type Query_RootTeacher_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "student_instruments" */
export type Student_Instruments = {
  __typename?: 'student_instruments';
  id: Scalars['uuid'];
  /** An object relationship */
  instrument: Instruments;
  instrument_id: Scalars['uuid'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
};

/** aggregated selection of "student_instruments" */
export type Student_Instruments_Aggregate = {
  __typename?: 'student_instruments_aggregate';
  aggregate?: Maybe<Student_Instruments_Aggregate_Fields>;
  nodes: Array<Student_Instruments>;
};

/** aggregate fields of "student_instruments" */
export type Student_Instruments_Aggregate_Fields = {
  __typename?: 'student_instruments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Student_Instruments_Max_Fields>;
  min?: Maybe<Student_Instruments_Min_Fields>;
};


/** aggregate fields of "student_instruments" */
export type Student_Instruments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Instruments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_instruments" */
export type Student_Instruments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Instruments_Max_Order_By>;
  min?: Maybe<Student_Instruments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "student_instruments" */
export type Student_Instruments_Arr_Rel_Insert_Input = {
  data: Array<Student_Instruments_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Student_Instruments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "student_instruments". All fields are combined with a logical 'AND'. */
export type Student_Instruments_Bool_Exp = {
  _and?: Maybe<Array<Student_Instruments_Bool_Exp>>;
  _not?: Maybe<Student_Instruments_Bool_Exp>;
  _or?: Maybe<Array<Student_Instruments_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instrument?: Maybe<Instruments_Bool_Exp>;
  instrument_id?: Maybe<Uuid_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_instruments" */
export enum Student_Instruments_Constraint {
  /** unique or primary key constraint */
  StudentInstrumentsPkey = 'student_instruments_pkey'
}

/** input type for inserting data into table "student_instruments" */
export type Student_Instruments_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  instrument?: Maybe<Instruments_Obj_Rel_Insert_Input>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Student_Instruments_Max_Fields = {
  __typename?: 'student_instruments_max_fields';
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "student_instruments" */
export type Student_Instruments_Max_Order_By = {
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Instruments_Min_Fields = {
  __typename?: 'student_instruments_min_fields';
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "student_instruments" */
export type Student_Instruments_Min_Order_By = {
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_instruments" */
export type Student_Instruments_Mutation_Response = {
  __typename?: 'student_instruments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Instruments>;
};

/** on conflict condition type for table "student_instruments" */
export type Student_Instruments_On_Conflict = {
  constraint: Student_Instruments_Constraint;
  update_columns?: Array<Student_Instruments_Update_Column>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};

/** Ordering options when selecting data from "student_instruments". */
export type Student_Instruments_Order_By = {
  id?: Maybe<Order_By>;
  instrument?: Maybe<Instruments_Order_By>;
  instrument_id?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_instruments */
export type Student_Instruments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "student_instruments" */
export enum Student_Instruments_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  StudentId = 'student_id'
}

/** input type for updating data in table "student_instruments" */
export type Student_Instruments_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "student_instruments" */
export enum Student_Instruments_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  StudentId = 'student_id'
}

/** columns and relationships of "student_locations" */
export type Student_Locations = {
  __typename?: 'student_locations';
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
};

/** aggregated selection of "student_locations" */
export type Student_Locations_Aggregate = {
  __typename?: 'student_locations_aggregate';
  aggregate?: Maybe<Student_Locations_Aggregate_Fields>;
  nodes: Array<Student_Locations>;
};

/** aggregate fields of "student_locations" */
export type Student_Locations_Aggregate_Fields = {
  __typename?: 'student_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Student_Locations_Max_Fields>;
  min?: Maybe<Student_Locations_Min_Fields>;
};


/** aggregate fields of "student_locations" */
export type Student_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_locations" */
export type Student_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Locations_Max_Order_By>;
  min?: Maybe<Student_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "student_locations" */
export type Student_Locations_Arr_Rel_Insert_Input = {
  data: Array<Student_Locations_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Student_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "student_locations". All fields are combined with a logical 'AND'. */
export type Student_Locations_Bool_Exp = {
  _and?: Maybe<Array<Student_Locations_Bool_Exp>>;
  _not?: Maybe<Student_Locations_Bool_Exp>;
  _or?: Maybe<Array<Student_Locations_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_locations" */
export enum Student_Locations_Constraint {
  /** unique or primary key constraint */
  StudentLocationsPkey = 'student_locations_pkey'
}

/** input type for inserting data into table "student_locations" */
export type Student_Locations_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Student_Locations_Max_Fields = {
  __typename?: 'student_locations_max_fields';
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "student_locations" */
export type Student_Locations_Max_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Locations_Min_Fields = {
  __typename?: 'student_locations_min_fields';
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "student_locations" */
export type Student_Locations_Min_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_locations" */
export type Student_Locations_Mutation_Response = {
  __typename?: 'student_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Locations>;
};

/** on conflict condition type for table "student_locations" */
export type Student_Locations_On_Conflict = {
  constraint: Student_Locations_Constraint;
  update_columns?: Array<Student_Locations_Update_Column>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "student_locations". */
export type Student_Locations_Order_By = {
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_locations */
export type Student_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "student_locations" */
export enum Student_Locations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  StudentId = 'student_id'
}

/** input type for updating data in table "student_locations" */
export type Student_Locations_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "student_locations" */
export enum Student_Locations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  StudentId = 'student_id'
}

/** columns and relationships of "student_notes" */
export type Student_Notes = {
  __typename?: 'student_notes';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lesson?: Maybe<Lessons>;
  lesson_id?: Maybe<Scalars['uuid']>;
  note_content: Scalars['String'];
  note_type: Scalars['String'];
  should_trigger_notification: Scalars['Boolean'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "student_notes" */
export type Student_Notes_Aggregate = {
  __typename?: 'student_notes_aggregate';
  aggregate?: Maybe<Student_Notes_Aggregate_Fields>;
  nodes: Array<Student_Notes>;
};

/** aggregate fields of "student_notes" */
export type Student_Notes_Aggregate_Fields = {
  __typename?: 'student_notes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Student_Notes_Max_Fields>;
  min?: Maybe<Student_Notes_Min_Fields>;
};


/** aggregate fields of "student_notes" */
export type Student_Notes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_notes" */
export type Student_Notes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Notes_Max_Order_By>;
  min?: Maybe<Student_Notes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "student_notes" */
export type Student_Notes_Arr_Rel_Insert_Input = {
  data: Array<Student_Notes_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Student_Notes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "student_notes". All fields are combined with a logical 'AND'. */
export type Student_Notes_Bool_Exp = {
  _and?: Maybe<Array<Student_Notes_Bool_Exp>>;
  _not?: Maybe<Student_Notes_Bool_Exp>;
  _or?: Maybe<Array<Student_Notes_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lesson?: Maybe<Lessons_Bool_Exp>;
  lesson_id?: Maybe<Uuid_Comparison_Exp>;
  note_content?: Maybe<String_Comparison_Exp>;
  note_type?: Maybe<String_Comparison_Exp>;
  should_trigger_notification?: Maybe<Boolean_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_notes" */
export enum Student_Notes_Constraint {
  /** unique or primary key constraint */
  StudentNotesPkey = 'student_notes_pkey'
}

/** input type for inserting data into table "student_notes" */
export type Student_Notes_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson?: Maybe<Lessons_Obj_Rel_Insert_Input>;
  lesson_id?: Maybe<Scalars['uuid']>;
  note_content?: Maybe<Scalars['String']>;
  note_type?: Maybe<Scalars['String']>;
  should_trigger_notification?: Maybe<Scalars['Boolean']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Student_Notes_Max_Fields = {
  __typename?: 'student_notes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  note_content?: Maybe<Scalars['String']>;
  note_type?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "student_notes" */
export type Student_Notes_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lesson_id?: Maybe<Order_By>;
  note_content?: Maybe<Order_By>;
  note_type?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Notes_Min_Fields = {
  __typename?: 'student_notes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  note_content?: Maybe<Scalars['String']>;
  note_type?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "student_notes" */
export type Student_Notes_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lesson_id?: Maybe<Order_By>;
  note_content?: Maybe<Order_By>;
  note_type?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_notes" */
export type Student_Notes_Mutation_Response = {
  __typename?: 'student_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Notes>;
};

/** on conflict condition type for table "student_notes" */
export type Student_Notes_On_Conflict = {
  constraint: Student_Notes_Constraint;
  update_columns?: Array<Student_Notes_Update_Column>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "student_notes". */
export type Student_Notes_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lesson?: Maybe<Lessons_Order_By>;
  lesson_id?: Maybe<Order_By>;
  note_content?: Maybe<Order_By>;
  note_type?: Maybe<Order_By>;
  should_trigger_notification?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_notes */
export type Student_Notes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "student_notes" */
export enum Student_Notes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  NoteContent = 'note_content',
  /** column name */
  NoteType = 'note_type',
  /** column name */
  ShouldTriggerNotification = 'should_trigger_notification',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "student_notes" */
export type Student_Notes_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['uuid']>;
  note_content?: Maybe<Scalars['String']>;
  note_type?: Maybe<Scalars['String']>;
  should_trigger_notification?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "student_notes" */
export enum Student_Notes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  NoteContent = 'note_content',
  /** column name */
  NoteType = 'note_type',
  /** column name */
  ShouldTriggerNotification = 'should_trigger_notification',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "student_plans" */
export type Student_Plans = {
  __typename?: 'student_plans';
  auto_renew: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  current_count: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  instrument: Instruments;
  instrument_id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  next_student_plan_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  plan: Plans;
  plan_id: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
  teacher_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "student_plans" */
export type Student_Plans_Aggregate = {
  __typename?: 'student_plans_aggregate';
  aggregate?: Maybe<Student_Plans_Aggregate_Fields>;
  nodes: Array<Student_Plans>;
};

/** aggregate fields of "student_plans" */
export type Student_Plans_Aggregate_Fields = {
  __typename?: 'student_plans_aggregate_fields';
  avg?: Maybe<Student_Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Plans_Max_Fields>;
  min?: Maybe<Student_Plans_Min_Fields>;
  stddev?: Maybe<Student_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Plans_Sum_Fields>;
  var_pop?: Maybe<Student_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Plans_Var_Samp_Fields>;
  variance?: Maybe<Student_Plans_Variance_Fields>;
};


/** aggregate fields of "student_plans" */
export type Student_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_plans" */
export type Student_Plans_Aggregate_Order_By = {
  avg?: Maybe<Student_Plans_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Plans_Max_Order_By>;
  min?: Maybe<Student_Plans_Min_Order_By>;
  stddev?: Maybe<Student_Plans_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Plans_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Plans_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Plans_Sum_Order_By>;
  var_pop?: Maybe<Student_Plans_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Plans_Var_Samp_Order_By>;
  variance?: Maybe<Student_Plans_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_plans" */
export type Student_Plans_Arr_Rel_Insert_Input = {
  data: Array<Student_Plans_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Student_Plans_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Plans_Avg_Fields = {
  __typename?: 'student_plans_avg_fields';
  current_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_plans" */
export type Student_Plans_Avg_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_plans". All fields are combined with a logical 'AND'. */
export type Student_Plans_Bool_Exp = {
  _and?: Maybe<Array<Student_Plans_Bool_Exp>>;
  _not?: Maybe<Student_Plans_Bool_Exp>;
  _or?: Maybe<Array<Student_Plans_Bool_Exp>>;
  auto_renew?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_count?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instrument?: Maybe<Instruments_Bool_Exp>;
  instrument_id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  next_student_plan_id?: Maybe<Uuid_Comparison_Exp>;
  plan?: Maybe<Plans_Bool_Exp>;
  plan_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
  teacher_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "student_plans" */
export enum Student_Plans_Constraint {
  /** unique or primary key constraint */
  StudentPlansPkey = 'student_plans_pkey'
}

/** input type for incrementing numeric columns in table "student_plans" */
export type Student_Plans_Inc_Input = {
  current_count?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_plans" */
export type Student_Plans_Insert_Input = {
  auto_renew?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instrument?: Maybe<Instruments_Obj_Rel_Insert_Input>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  next_student_plan_id?: Maybe<Scalars['uuid']>;
  plan?: Maybe<Plans_Obj_Rel_Insert_Input>;
  plan_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Student_Plans_Max_Fields = {
  __typename?: 'student_plans_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  next_student_plan_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "student_plans" */
export type Student_Plans_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  current_count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  next_student_plan_id?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Plans_Min_Fields = {
  __typename?: 'student_plans_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  next_student_plan_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "student_plans" */
export type Student_Plans_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  current_count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  next_student_plan_id?: Maybe<Order_By>;
  plan_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_plans" */
export type Student_Plans_Mutation_Response = {
  __typename?: 'student_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Plans>;
};

/** on conflict condition type for table "student_plans" */
export type Student_Plans_On_Conflict = {
  constraint: Student_Plans_Constraint;
  update_columns?: Array<Student_Plans_Update_Column>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "student_plans". */
export type Student_Plans_Order_By = {
  auto_renew?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instrument?: Maybe<Instruments_Order_By>;
  instrument_id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  next_student_plan_id?: Maybe<Order_By>;
  plan?: Maybe<Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: student_plans */
export type Student_Plans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "student_plans" */
export enum Student_Plans_Select_Column {
  /** column name */
  AutoRenew = 'auto_renew',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentCount = 'current_count',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  NextStudentPlanId = 'next_student_plan_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "student_plans" */
export type Student_Plans_Set_Input = {
  auto_renew?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  next_student_plan_id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Student_Plans_Stddev_Fields = {
  __typename?: 'student_plans_stddev_fields';
  current_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_plans" */
export type Student_Plans_Stddev_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Plans_Stddev_Pop_Fields = {
  __typename?: 'student_plans_stddev_pop_fields';
  current_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_plans" */
export type Student_Plans_Stddev_Pop_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Plans_Stddev_Samp_Fields = {
  __typename?: 'student_plans_stddev_samp_fields';
  current_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_plans" */
export type Student_Plans_Stddev_Samp_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Plans_Sum_Fields = {
  __typename?: 'student_plans_sum_fields';
  current_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student_plans" */
export type Student_Plans_Sum_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** update columns of table "student_plans" */
export enum Student_Plans_Update_Column {
  /** column name */
  AutoRenew = 'auto_renew',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentCount = 'current_count',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  NextStudentPlanId = 'next_student_plan_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Status = 'status',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Student_Plans_Var_Pop_Fields = {
  __typename?: 'student_plans_var_pop_fields';
  current_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_plans" */
export type Student_Plans_Var_Pop_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Plans_Var_Samp_Fields = {
  __typename?: 'student_plans_var_samp_fields';
  current_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_plans" */
export type Student_Plans_Var_Samp_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Plans_Variance_Fields = {
  __typename?: 'student_plans_variance_fields';
  current_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_plans" */
export type Student_Plans_Variance_Order_By = {
  current_count?: Maybe<Order_By>;
};

/** columns and relationships of "student_teachers" */
export type Student_Teachers = {
  __typename?: 'student_teachers';
  id: Scalars['uuid'];
  instrument_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
  teacher_id: Scalars['String'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "student_teachers" */
export type Student_Teachers_Aggregate = {
  __typename?: 'student_teachers_aggregate';
  aggregate?: Maybe<Student_Teachers_Aggregate_Fields>;
  nodes: Array<Student_Teachers>;
};

/** aggregate fields of "student_teachers" */
export type Student_Teachers_Aggregate_Fields = {
  __typename?: 'student_teachers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Student_Teachers_Max_Fields>;
  min?: Maybe<Student_Teachers_Min_Fields>;
};


/** aggregate fields of "student_teachers" */
export type Student_Teachers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Teachers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_teachers" */
export type Student_Teachers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Teachers_Max_Order_By>;
  min?: Maybe<Student_Teachers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "student_teachers" */
export type Student_Teachers_Arr_Rel_Insert_Input = {
  data: Array<Student_Teachers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Student_Teachers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "student_teachers". All fields are combined with a logical 'AND'. */
export type Student_Teachers_Bool_Exp = {
  _and?: Maybe<Array<Student_Teachers_Bool_Exp>>;
  _not?: Maybe<Student_Teachers_Bool_Exp>;
  _or?: Maybe<Array<Student_Teachers_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instrument_id?: Maybe<Uuid_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
  teacher_id?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "student_teachers" */
export enum Student_Teachers_Constraint {
  /** unique or primary key constraint */
  StudentsTeachersPkey = 'students_teachers_pkey'
}

/** input type for inserting data into table "student_teachers" */
export type Student_Teachers_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Student_Teachers_Max_Fields = {
  __typename?: 'student_teachers_max_fields';
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "student_teachers" */
export type Student_Teachers_Max_Order_By = {
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Teachers_Min_Fields = {
  __typename?: 'student_teachers_min_fields';
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "student_teachers" */
export type Student_Teachers_Min_Order_By = {
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_teachers" */
export type Student_Teachers_Mutation_Response = {
  __typename?: 'student_teachers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Teachers>;
};

/** on conflict condition type for table "student_teachers" */
export type Student_Teachers_On_Conflict = {
  constraint: Student_Teachers_Constraint;
  update_columns?: Array<Student_Teachers_Update_Column>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};

/** Ordering options when selecting data from "student_teachers". */
export type Student_Teachers_Order_By = {
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: student_teachers */
export type Student_Teachers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "student_teachers" */
export enum Student_Teachers_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id'
}

/** input type for updating data in table "student_teachers" */
export type Student_Teachers_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** update columns of table "student_teachers" */
export enum Student_Teachers_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id'
}

/** columns and relationships of "student_users" */
export type Student_Users = {
  __typename?: 'student_users';
  id: Scalars['uuid'];
  /** An object relationship */
  student: Students;
  student_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "student_users" */
export type Student_Users_Aggregate = {
  __typename?: 'student_users_aggregate';
  aggregate?: Maybe<Student_Users_Aggregate_Fields>;
  nodes: Array<Student_Users>;
};

/** aggregate fields of "student_users" */
export type Student_Users_Aggregate_Fields = {
  __typename?: 'student_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Student_Users_Max_Fields>;
  min?: Maybe<Student_Users_Min_Fields>;
};


/** aggregate fields of "student_users" */
export type Student_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_users" */
export type Student_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Users_Max_Order_By>;
  min?: Maybe<Student_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "student_users" */
export type Student_Users_Arr_Rel_Insert_Input = {
  data: Array<Student_Users_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Student_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "student_users". All fields are combined with a logical 'AND'. */
export type Student_Users_Bool_Exp = {
  _and?: Maybe<Array<Student_Users_Bool_Exp>>;
  _not?: Maybe<Student_Users_Bool_Exp>;
  _or?: Maybe<Array<Student_Users_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  student?: Maybe<Students_Bool_Exp>;
  student_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_users" */
export enum Student_Users_Constraint {
  /** unique or primary key constraint */
  StudentUsersPkey = 'student_users_pkey'
}

/** input type for inserting data into table "student_users" */
export type Student_Users_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  student?: Maybe<Students_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Student_Users_Max_Fields = {
  __typename?: 'student_users_max_fields';
  id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "student_users" */
export type Student_Users_Max_Order_By = {
  id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Users_Min_Fields = {
  __typename?: 'student_users_min_fields';
  id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "student_users" */
export type Student_Users_Min_Order_By = {
  id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_users" */
export type Student_Users_Mutation_Response = {
  __typename?: 'student_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Users>;
};

/** on conflict condition type for table "student_users" */
export type Student_Users_On_Conflict = {
  constraint: Student_Users_Constraint;
  update_columns?: Array<Student_Users_Update_Column>;
  where?: Maybe<Student_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "student_users". */
export type Student_Users_Order_By = {
  id?: Maybe<Order_By>;
  student?: Maybe<Students_Order_By>;
  student_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_users */
export type Student_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "student_users" */
export enum Student_Users_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "student_users" */
export type Student_Users_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  student_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "student_users" */
export enum Student_Users_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "students" */
export type Students = {
  __typename?: 'students';
  class_code?: Maybe<Scalars['String']>;
  class_name?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  /** A computed field, executes function "students_gdpr_name" */
  gdpr_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  last_name: Scalars['String'];
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  plan_adjustments: Array<Plan_Adjustments>;
  /** An aggregate relationship */
  plan_adjustments_aggregate: Plan_Adjustments_Aggregate;
  premium_percent_guardian?: Maybe<Scalars['Int']>;
  premium_percent_school?: Maybe<Scalars['Int']>;
  premium_pupil: Scalars['Boolean'];
  /** An array relationship */
  student_instruments: Array<Student_Instruments>;
  /** An aggregate relationship */
  student_instruments_aggregate: Student_Instruments_Aggregate;
  /** An array relationship */
  student_locations: Array<Student_Locations>;
  /** An aggregate relationship */
  student_locations_aggregate: Student_Locations_Aggregate;
  /** fetch data from the table: "student_notes" */
  student_notes: Array<Student_Notes>;
  /** An aggregate relationship */
  student_notes_aggregate: Student_Notes_Aggregate;
  /** An array relationship */
  student_plans: Array<Student_Plans>;
  /** An aggregate relationship */
  student_plans_aggregate: Student_Plans_Aggregate;
  /** An array relationship */
  student_teachers: Array<Student_Teachers>;
  /** An aggregate relationship */
  student_teachers_aggregate: Student_Teachers_Aggregate;
  /** An array relationship */
  student_users: Array<Student_Users>;
  /** An aggregate relationship */
  student_users_aggregate: Student_Users_Aggregate;
};


/** columns and relationships of "students" */
export type StudentsInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsLessonsArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsLessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsPlan_AdjustmentsArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsPlan_Adjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_LocationsArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_NotesArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_PlansArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_TeachersArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_Teachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_UsersArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};


/** columns and relationships of "students" */
export type StudentsStudent_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};

/** aggregated selection of "students" */
export type Students_Aggregate = {
  __typename?: 'students_aggregate';
  aggregate?: Maybe<Students_Aggregate_Fields>;
  nodes: Array<Students>;
};

/** aggregate fields of "students" */
export type Students_Aggregate_Fields = {
  __typename?: 'students_aggregate_fields';
  avg?: Maybe<Students_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Students_Max_Fields>;
  min?: Maybe<Students_Min_Fields>;
  stddev?: Maybe<Students_Stddev_Fields>;
  stddev_pop?: Maybe<Students_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Students_Stddev_Samp_Fields>;
  sum?: Maybe<Students_Sum_Fields>;
  var_pop?: Maybe<Students_Var_Pop_Fields>;
  var_samp?: Maybe<Students_Var_Samp_Fields>;
  variance?: Maybe<Students_Variance_Fields>;
};


/** aggregate fields of "students" */
export type Students_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Students_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Students_Avg_Fields = {
  __typename?: 'students_avg_fields';
  premium_percent_guardian?: Maybe<Scalars['Float']>;
  premium_percent_school?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "students". All fields are combined with a logical 'AND'. */
export type Students_Bool_Exp = {
  _and?: Maybe<Array<Students_Bool_Exp>>;
  _not?: Maybe<Students_Bool_Exp>;
  _or?: Maybe<Array<Students_Bool_Exp>>;
  class_code?: Maybe<String_Comparison_Exp>;
  class_name?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gdpr_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoices?: Maybe<Invoices_Bool_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  lessons?: Maybe<Lessons_Bool_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  plan_adjustments?: Maybe<Plan_Adjustments_Bool_Exp>;
  premium_percent_guardian?: Maybe<Int_Comparison_Exp>;
  premium_percent_school?: Maybe<Int_Comparison_Exp>;
  premium_pupil?: Maybe<Boolean_Comparison_Exp>;
  student_instruments?: Maybe<Student_Instruments_Bool_Exp>;
  student_locations?: Maybe<Student_Locations_Bool_Exp>;
  student_notes?: Maybe<Student_Notes_Bool_Exp>;
  student_plans?: Maybe<Student_Plans_Bool_Exp>;
  student_teachers?: Maybe<Student_Teachers_Bool_Exp>;
  student_users?: Maybe<Student_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "students" */
export enum Students_Constraint {
  /** unique or primary key constraint */
  StudentsPkey = 'students_pkey'
}

/** input type for incrementing numeric columns in table "students" */
export type Students_Inc_Input = {
  premium_percent_guardian?: Maybe<Scalars['Int']>;
  premium_percent_school?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "students" */
export type Students_Insert_Input = {
  class_code?: Maybe<Scalars['String']>;
  class_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoices?: Maybe<Invoices_Arr_Rel_Insert_Input>;
  last_name?: Maybe<Scalars['String']>;
  lessons?: Maybe<Lessons_Arr_Rel_Insert_Input>;
  notes?: Maybe<Scalars['String']>;
  plan_adjustments?: Maybe<Plan_Adjustments_Arr_Rel_Insert_Input>;
  premium_percent_guardian?: Maybe<Scalars['Int']>;
  premium_percent_school?: Maybe<Scalars['Int']>;
  premium_pupil?: Maybe<Scalars['Boolean']>;
  student_instruments?: Maybe<Student_Instruments_Arr_Rel_Insert_Input>;
  student_locations?: Maybe<Student_Locations_Arr_Rel_Insert_Input>;
  student_notes?: Maybe<Student_Notes_Arr_Rel_Insert_Input>;
  student_plans?: Maybe<Student_Plans_Arr_Rel_Insert_Input>;
  student_teachers?: Maybe<Student_Teachers_Arr_Rel_Insert_Input>;
  student_users?: Maybe<Student_Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Students_Max_Fields = {
  __typename?: 'students_max_fields';
  class_code?: Maybe<Scalars['String']>;
  class_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  premium_percent_guardian?: Maybe<Scalars['Int']>;
  premium_percent_school?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Students_Min_Fields = {
  __typename?: 'students_min_fields';
  class_code?: Maybe<Scalars['String']>;
  class_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  premium_percent_guardian?: Maybe<Scalars['Int']>;
  premium_percent_school?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "students" */
export type Students_Mutation_Response = {
  __typename?: 'students_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Students>;
};

/** input type for inserting object relation for remote table "students" */
export type Students_Obj_Rel_Insert_Input = {
  data: Students_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Students_On_Conflict>;
};

/** on conflict condition type for table "students" */
export type Students_On_Conflict = {
  constraint: Students_Constraint;
  update_columns?: Array<Students_Update_Column>;
  where?: Maybe<Students_Bool_Exp>;
};

/** Ordering options when selecting data from "students". */
export type Students_Order_By = {
  class_code?: Maybe<Order_By>;
  class_name?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gdpr_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoices_Aggregate_Order_By>;
  last_name?: Maybe<Order_By>;
  lessons_aggregate?: Maybe<Lessons_Aggregate_Order_By>;
  notes?: Maybe<Order_By>;
  plan_adjustments_aggregate?: Maybe<Plan_Adjustments_Aggregate_Order_By>;
  premium_percent_guardian?: Maybe<Order_By>;
  premium_percent_school?: Maybe<Order_By>;
  premium_pupil?: Maybe<Order_By>;
  student_instruments_aggregate?: Maybe<Student_Instruments_Aggregate_Order_By>;
  student_locations_aggregate?: Maybe<Student_Locations_Aggregate_Order_By>;
  student_notes_aggregate?: Maybe<Student_Notes_Aggregate_Order_By>;
  student_plans_aggregate?: Maybe<Student_Plans_Aggregate_Order_By>;
  student_teachers_aggregate?: Maybe<Student_Teachers_Aggregate_Order_By>;
  student_users_aggregate?: Maybe<Student_Users_Aggregate_Order_By>;
};

/** primary key columns input for table: students */
export type Students_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "students" */
export enum Students_Select_Column {
  /** column name */
  ClassCode = 'class_code',
  /** column name */
  ClassName = 'class_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PremiumPercentGuardian = 'premium_percent_guardian',
  /** column name */
  PremiumPercentSchool = 'premium_percent_school',
  /** column name */
  PremiumPupil = 'premium_pupil'
}

/** input type for updating data in table "students" */
export type Students_Set_Input = {
  class_code?: Maybe<Scalars['String']>;
  class_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  premium_percent_guardian?: Maybe<Scalars['Int']>;
  premium_percent_school?: Maybe<Scalars['Int']>;
  premium_pupil?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Students_Stddev_Fields = {
  __typename?: 'students_stddev_fields';
  premium_percent_guardian?: Maybe<Scalars['Float']>;
  premium_percent_school?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Students_Stddev_Pop_Fields = {
  __typename?: 'students_stddev_pop_fields';
  premium_percent_guardian?: Maybe<Scalars['Float']>;
  premium_percent_school?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Students_Stddev_Samp_Fields = {
  __typename?: 'students_stddev_samp_fields';
  premium_percent_guardian?: Maybe<Scalars['Float']>;
  premium_percent_school?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Students_Sum_Fields = {
  __typename?: 'students_sum_fields';
  premium_percent_guardian?: Maybe<Scalars['Int']>;
  premium_percent_school?: Maybe<Scalars['Int']>;
};

/** update columns of table "students" */
export enum Students_Update_Column {
  /** column name */
  ClassCode = 'class_code',
  /** column name */
  ClassName = 'class_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PremiumPercentGuardian = 'premium_percent_guardian',
  /** column name */
  PremiumPercentSchool = 'premium_percent_school',
  /** column name */
  PremiumPupil = 'premium_pupil'
}

/** aggregate var_pop on columns */
export type Students_Var_Pop_Fields = {
  __typename?: 'students_var_pop_fields';
  premium_percent_guardian?: Maybe<Scalars['Float']>;
  premium_percent_school?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Students_Var_Samp_Fields = {
  __typename?: 'students_var_samp_fields';
  premium_percent_guardian?: Maybe<Scalars['Float']>;
  premium_percent_school?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Students_Variance_Fields = {
  __typename?: 'students_variance_fields';
  premium_percent_guardian?: Maybe<Scalars['Float']>;
  premium_percent_school?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "instruments" */
  instruments: Array<Instruments>;
  /** fetch aggregated fields from the table: "instruments" */
  instruments_aggregate: Instruments_Aggregate;
  /** fetch data from the table: "instruments" using primary key columns */
  instruments_by_pk?: Maybe<Instruments>;
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  /** fetch data from the table: "invoices" using primary key columns */
  invoices_by_pk?: Maybe<Invoices>;
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  /** fetch data from the table: "lessons" using primary key columns */
  lessons_by_pk?: Maybe<Lessons>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** An array relationship */
  plan_adjustments: Array<Plan_Adjustments>;
  /** An aggregate relationship */
  plan_adjustments_aggregate: Plan_Adjustments_Aggregate;
  /** fetch data from the table: "plan_adjustments" using primary key columns */
  plan_adjustments_by_pk?: Maybe<Plan_Adjustments>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** An array relationship */
  student_instruments: Array<Student_Instruments>;
  /** An aggregate relationship */
  student_instruments_aggregate: Student_Instruments_Aggregate;
  /** fetch data from the table: "student_instruments" using primary key columns */
  student_instruments_by_pk?: Maybe<Student_Instruments>;
  /** An array relationship */
  student_locations: Array<Student_Locations>;
  /** An aggregate relationship */
  student_locations_aggregate: Student_Locations_Aggregate;
  /** fetch data from the table: "student_locations" using primary key columns */
  student_locations_by_pk?: Maybe<Student_Locations>;
  /** fetch data from the table: "student_notes" */
  student_notes: Array<Student_Notes>;
  /** An aggregate relationship */
  student_notes_aggregate: Student_Notes_Aggregate;
  /** fetch data from the table: "student_notes" using primary key columns */
  student_notes_by_pk?: Maybe<Student_Notes>;
  /** An array relationship */
  student_plans: Array<Student_Plans>;
  /** An aggregate relationship */
  student_plans_aggregate: Student_Plans_Aggregate;
  /** fetch data from the table: "student_plans" using primary key columns */
  student_plans_by_pk?: Maybe<Student_Plans>;
  /** An array relationship */
  student_teachers: Array<Student_Teachers>;
  /** An aggregate relationship */
  student_teachers_aggregate: Student_Teachers_Aggregate;
  /** fetch data from the table: "student_teachers" using primary key columns */
  student_teachers_by_pk?: Maybe<Student_Teachers>;
  /** An array relationship */
  student_users: Array<Student_Users>;
  /** An aggregate relationship */
  student_users_aggregate: Student_Users_Aggregate;
  /** fetch data from the table: "student_users" using primary key columns */
  student_users_by_pk?: Maybe<Student_Users>;
  /** fetch data from the table: "students" */
  students: Array<Students>;
  /** fetch aggregated fields from the table: "students" */
  students_aggregate: Students_Aggregate;
  /** fetch data from the table: "students" using primary key columns */
  students_by_pk?: Maybe<Students>;
  /** An array relationship */
  teacher_instruments: Array<Teacher_Instruments>;
  /** An aggregate relationship */
  teacher_instruments_aggregate: Teacher_Instruments_Aggregate;
  /** fetch data from the table: "teacher_instruments" using primary key columns */
  teacher_instruments_by_pk?: Maybe<Teacher_Instruments>;
  /** An array relationship */
  teacher_locations: Array<Teacher_Locations>;
  /** An aggregate relationship */
  teacher_locations_aggregate: Teacher_Locations_Aggregate;
  /** fetch data from the table: "teacher_locations" using primary key columns */
  teacher_locations_by_pk?: Maybe<Teacher_Locations>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Subscription_RootInstrumentsArgs = {
  distinct_on?: Maybe<Array<Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Instruments_Order_By>>;
  where?: Maybe<Instruments_Bool_Exp>;
};


export type Subscription_RootInstruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Instruments_Order_By>>;
  where?: Maybe<Instruments_Bool_Exp>;
};


export type Subscription_RootInstruments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Subscription_RootInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


export type Subscription_RootInvoices_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLessonsArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


export type Subscription_RootLessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


export type Subscription_RootLessons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPlan_AdjustmentsArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


export type Subscription_RootPlan_Adjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


export type Subscription_RootPlan_Adjustments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPlansArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Subscription_RootPlans_AggregateArgs = {
  distinct_on?: Maybe<Array<Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plans_Order_By>>;
  where?: Maybe<Plans_Bool_Exp>;
};


export type Subscription_RootPlans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudent_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


export type Subscription_RootStudent_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Instruments_Order_By>>;
  where?: Maybe<Student_Instruments_Bool_Exp>;
};


export type Subscription_RootStudent_Instruments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudent_LocationsArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


export type Subscription_RootStudent_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Locations_Order_By>>;
  where?: Maybe<Student_Locations_Bool_Exp>;
};


export type Subscription_RootStudent_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudent_NotesArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


export type Subscription_RootStudent_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


export type Subscription_RootStudent_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudent_PlansArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


export type Subscription_RootStudent_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


export type Subscription_RootStudent_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudent_TeachersArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


export type Subscription_RootStudent_Teachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


export type Subscription_RootStudent_Teachers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudent_UsersArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};


export type Subscription_RootStudent_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};


export type Subscription_RootStudent_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudentsArgs = {
  distinct_on?: Maybe<Array<Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Students_Order_By>>;
  where?: Maybe<Students_Bool_Exp>;
};


export type Subscription_RootStudents_AggregateArgs = {
  distinct_on?: Maybe<Array<Students_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Students_Order_By>>;
  where?: Maybe<Students_Bool_Exp>;
};


export type Subscription_RootStudents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeacher_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};


export type Subscription_RootTeacher_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};


export type Subscription_RootTeacher_Instruments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeacher_LocationsArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};


export type Subscription_RootTeacher_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};


export type Subscription_RootTeacher_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "teacher_instruments" */
export type Teacher_Instruments = {
  __typename?: 'teacher_instruments';
  id: Scalars['uuid'];
  /** An object relationship */
  instrument: Instruments;
  instrument_id: Scalars['uuid'];
  teacher_id: Scalars['String'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "teacher_instruments" */
export type Teacher_Instruments_Aggregate = {
  __typename?: 'teacher_instruments_aggregate';
  aggregate?: Maybe<Teacher_Instruments_Aggregate_Fields>;
  nodes: Array<Teacher_Instruments>;
};

/** aggregate fields of "teacher_instruments" */
export type Teacher_Instruments_Aggregate_Fields = {
  __typename?: 'teacher_instruments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teacher_Instruments_Max_Fields>;
  min?: Maybe<Teacher_Instruments_Min_Fields>;
};


/** aggregate fields of "teacher_instruments" */
export type Teacher_Instruments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher_instruments" */
export type Teacher_Instruments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Instruments_Max_Order_By>;
  min?: Maybe<Teacher_Instruments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teacher_instruments" */
export type Teacher_Instruments_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Instruments_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Teacher_Instruments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teacher_instruments". All fields are combined with a logical 'AND'. */
export type Teacher_Instruments_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Instruments_Bool_Exp>>;
  _not?: Maybe<Teacher_Instruments_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Instruments_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instrument?: Maybe<Instruments_Bool_Exp>;
  instrument_id?: Maybe<Uuid_Comparison_Exp>;
  teacher_id?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "teacher_instruments" */
export enum Teacher_Instruments_Constraint {
  /** unique or primary key constraint */
  TeacherInstrumentsPkey = 'teacher_instruments_pkey'
}

/** input type for inserting data into table "teacher_instruments" */
export type Teacher_Instruments_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  instrument?: Maybe<Instruments_Obj_Rel_Insert_Input>;
  instrument_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Teacher_Instruments_Max_Fields = {
  __typename?: 'teacher_instruments_max_fields';
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "teacher_instruments" */
export type Teacher_Instruments_Max_Order_By = {
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Instruments_Min_Fields = {
  __typename?: 'teacher_instruments_min_fields';
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "teacher_instruments" */
export type Teacher_Instruments_Min_Order_By = {
  id?: Maybe<Order_By>;
  instrument_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher_instruments" */
export type Teacher_Instruments_Mutation_Response = {
  __typename?: 'teacher_instruments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Instruments>;
};

/** on conflict condition type for table "teacher_instruments" */
export type Teacher_Instruments_On_Conflict = {
  constraint: Teacher_Instruments_Constraint;
  update_columns?: Array<Teacher_Instruments_Update_Column>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_instruments". */
export type Teacher_Instruments_Order_By = {
  id?: Maybe<Order_By>;
  instrument?: Maybe<Instruments_Order_By>;
  instrument_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: teacher_instruments */
export type Teacher_Instruments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teacher_instruments" */
export enum Teacher_Instruments_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  TeacherId = 'teacher_id'
}

/** input type for updating data in table "teacher_instruments" */
export type Teacher_Instruments_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  instrument_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** update columns of table "teacher_instruments" */
export enum Teacher_Instruments_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentId = 'instrument_id',
  /** column name */
  TeacherId = 'teacher_id'
}

/** columns and relationships of "teacher_locations" */
export type Teacher_Locations = {
  __typename?: 'teacher_locations';
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  teacher_id: Scalars['String'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "teacher_locations" */
export type Teacher_Locations_Aggregate = {
  __typename?: 'teacher_locations_aggregate';
  aggregate?: Maybe<Teacher_Locations_Aggregate_Fields>;
  nodes: Array<Teacher_Locations>;
};

/** aggregate fields of "teacher_locations" */
export type Teacher_Locations_Aggregate_Fields = {
  __typename?: 'teacher_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teacher_Locations_Max_Fields>;
  min?: Maybe<Teacher_Locations_Min_Fields>;
};


/** aggregate fields of "teacher_locations" */
export type Teacher_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher_locations" */
export type Teacher_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Locations_Max_Order_By>;
  min?: Maybe<Teacher_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teacher_locations" */
export type Teacher_Locations_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Locations_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Teacher_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teacher_locations". All fields are combined with a logical 'AND'. */
export type Teacher_Locations_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Locations_Bool_Exp>>;
  _not?: Maybe<Teacher_Locations_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Locations_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  teacher_id?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "teacher_locations" */
export enum Teacher_Locations_Constraint {
  /** unique or primary key constraint */
  TeacherLocationsPkey = 'teacher_locations_pkey'
}

/** input type for inserting data into table "teacher_locations" */
export type Teacher_Locations_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Teacher_Locations_Max_Fields = {
  __typename?: 'teacher_locations_max_fields';
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "teacher_locations" */
export type Teacher_Locations_Max_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Locations_Min_Fields = {
  __typename?: 'teacher_locations_min_fields';
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "teacher_locations" */
export type Teacher_Locations_Min_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher_locations" */
export type Teacher_Locations_Mutation_Response = {
  __typename?: 'teacher_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Locations>;
};

/** on conflict condition type for table "teacher_locations" */
export type Teacher_Locations_On_Conflict = {
  constraint: Teacher_Locations_Constraint;
  update_columns?: Array<Teacher_Locations_Update_Column>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_locations". */
export type Teacher_Locations_Order_By = {
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: teacher_locations */
export type Teacher_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teacher_locations" */
export enum Teacher_Locations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  TeacherId = 'teacher_id'
}

/** input type for updating data in table "teacher_locations" */
export type Teacher_Locations_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['String']>;
};

/** update columns of table "teacher_locations" */
export enum Teacher_Locations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  TeacherId = 'teacher_id'
}


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** A computed field, executes function "user_age" */
  age?: Maybe<Scalars['String']>;
  /** An array relationship */
  audit_plan_adjustments: Array<Plan_Adjustments>;
  /** An aggregate relationship */
  audit_plan_adjustments_aggregate: Plan_Adjustments_Aggregate;
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "users_full_name" */
  full_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "users_gdpr_name" */
  gdpr_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An array relationship */
  invoices: Array<Invoices>;
  /** An aggregate relationship */
  invoices_aggregate: Invoices_Aggregate;
  isGuardian: Scalars['Boolean'];
  isTeacher: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  phone?: Maybe<Scalars['String']>;
  /** fetch data from the table: "student_notes" */
  student_notes: Array<Student_Notes>;
  /** An aggregate relationship */
  student_notes_aggregate: Student_Notes_Aggregate;
  /** An array relationship */
  student_plans: Array<Student_Plans>;
  /** An aggregate relationship */
  student_plans_aggregate: Student_Plans_Aggregate;
  /** An array relationship */
  student_teachers: Array<Student_Teachers>;
  /** An aggregate relationship */
  student_teachers_aggregate: Student_Teachers_Aggregate;
  /** An array relationship */
  student_users: Array<Student_Users>;
  /** An aggregate relationship */
  student_users_aggregate: Student_Users_Aggregate;
  /** An array relationship */
  teacher_instruments: Array<Teacher_Instruments>;
  /** An aggregate relationship */
  teacher_instruments_aggregate: Teacher_Instruments_Aggregate;
  /** An array relationship */
  teacher_locations: Array<Teacher_Locations>;
  /** An aggregate relationship */
  teacher_locations_aggregate: Teacher_Locations_Aggregate;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersAudit_Plan_AdjustmentsArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAudit_Plan_Adjustments_AggregateArgs = {
  distinct_on?: Maybe<Array<Plan_Adjustments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Plan_Adjustments_Order_By>>;
  where?: Maybe<Plan_Adjustments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInvoicesArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersInvoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Invoices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invoices_Order_By>>;
  where?: Maybe<Invoices_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLessonsArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lessons_Order_By>>;
  where?: Maybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_NotesArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Notes_Order_By>>;
  where?: Maybe<Student_Notes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_PlansArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Plans_Order_By>>;
  where?: Maybe<Student_Plans_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_TeachersArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_Teachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Teachers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Teachers_Order_By>>;
  where?: Maybe<Student_Teachers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_UsersArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStudent_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Users_Order_By>>;
  where?: Maybe<Student_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeacher_InstrumentsArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeacher_Instruments_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Instruments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Instruments_Order_By>>;
  where?: Maybe<Teacher_Instruments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeacher_LocationsArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeacher_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Locations_Order_By>>;
  where?: Maybe<Teacher_Locations_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  age?: Maybe<String_Comparison_Exp>;
  audit_plan_adjustments?: Maybe<Plan_Adjustments_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  gdpr_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  invoices?: Maybe<Invoices_Bool_Exp>;
  isGuardian?: Maybe<Boolean_Comparison_Exp>;
  isTeacher?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  lessons?: Maybe<Lessons_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  student_notes?: Maybe<Student_Notes_Bool_Exp>;
  student_plans?: Maybe<Student_Plans_Bool_Exp>;
  student_teachers?: Maybe<Student_Teachers_Bool_Exp>;
  student_users?: Maybe<Student_Users_Bool_Exp>;
  teacher_instruments?: Maybe<Teacher_Instruments_Bool_Exp>;
  teacher_locations?: Maybe<Teacher_Locations_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  audit_plan_adjustments?: Maybe<Plan_Adjustments_Arr_Rel_Insert_Input>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoices?: Maybe<Invoices_Arr_Rel_Insert_Input>;
  isGuardian?: Maybe<Scalars['Boolean']>;
  isTeacher?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  lessons?: Maybe<Lessons_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  student_notes?: Maybe<Student_Notes_Arr_Rel_Insert_Input>;
  student_plans?: Maybe<Student_Plans_Arr_Rel_Insert_Input>;
  student_teachers?: Maybe<Student_Teachers_Arr_Rel_Insert_Input>;
  student_users?: Maybe<Student_Users_Arr_Rel_Insert_Input>;
  teacher_instruments?: Maybe<Teacher_Instruments_Arr_Rel_Insert_Input>;
  teacher_locations?: Maybe<Teacher_Locations_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  age?: Maybe<Order_By>;
  audit_plan_adjustments_aggregate?: Maybe<Plan_Adjustments_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  gdpr_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoices_aggregate?: Maybe<Invoices_Aggregate_Order_By>;
  isGuardian?: Maybe<Order_By>;
  isTeacher?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  lessons_aggregate?: Maybe<Lessons_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  student_notes_aggregate?: Maybe<Student_Notes_Aggregate_Order_By>;
  student_plans_aggregate?: Maybe<Student_Plans_Aggregate_Order_By>;
  student_teachers_aggregate?: Maybe<Student_Teachers_Aggregate_Order_By>;
  student_users_aggregate?: Maybe<Student_Users_Aggregate_Order_By>;
  teacher_instruments_aggregate?: Maybe<Teacher_Instruments_Aggregate_Order_By>;
  teacher_locations_aggregate?: Maybe<Teacher_Locations_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsGuardian = 'isGuardian',
  /** column name */
  IsTeacher = 'isTeacher',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isGuardian?: Maybe<Scalars['Boolean']>;
  isTeacher?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsGuardian = 'isGuardian',
  /** column name */
  IsTeacher = 'isTeacher',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  Title = 'title'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AutocompleteGuardiansQueryVariables = Exact<{ [key: string]: never; }>;


export type AutocompleteGuardiansQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string> }> };

export type AutocompleteInstrumentQueryVariables = Exact<{ [key: string]: never; }>;


export type AutocompleteInstrumentQuery = { __typename?: 'query_root', instruments: Array<{ __typename?: 'instruments', id: any, name: string, prettyName?: Maybe<string> }> };

export type AutocompleteLocationQueryVariables = Exact<{ [key: string]: never; }>;


export type AutocompleteLocationQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'locations', id: any, name: string, location_type: string }> };

export type AutocompleteStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AutocompleteStudentsQuery = { __typename?: 'query_root', students: Array<{ __typename?: 'students', id: any, first_name: string, last_name: string }> };

export type AutocompleteTeachersQueryVariables = Exact<{ [key: string]: never; }>;


export type AutocompleteTeachersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string> }> };

export type CopyScheduleMutationVariables = Exact<{
  input: Array<Lessons_Insert_Input> | Lessons_Insert_Input;
}>;


export type CopyScheduleMutation = { __typename?: 'mutation_root', insert_lessons?: Maybe<{ __typename?: 'lessons_mutation_response', affected_rows: number }> };

export type GetSourceLessonsQueryVariables = Exact<{
  teacher_id: Scalars['String'];
  date: Scalars['String'];
}>;


export type GetSourceLessonsQuery = { __typename?: 'query_root', lessons: Array<{ __typename?: 'lessons', dmy?: Maybe<string>, start_time?: Maybe<any>, end_time?: Maybe<any>, teacher_id: string, student_id: any, location_id: any, instrument_id?: Maybe<any> }> };

export type GetAllTeachersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTeachersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string> }> };

export type LessonCreateMutationVariables = Exact<{
  input: Lessons_Insert_Input;
}>;


export type LessonCreateMutation = { __typename?: 'mutation_root', insert_lessons_one?: Maybe<{ __typename?: 'lessons', id: any }> };

export type DeleteLessonMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLessonMutation = { __typename?: 'mutation_root', delete_lessons?: Maybe<{ __typename?: 'lessons_mutation_response', returning: Array<{ __typename?: 'lessons', id: any }> }> };

export type GetLessonQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLessonQuery = { __typename?: 'query_root', lessons_by_pk?: Maybe<{ __typename?: 'lessons', id: any, start_time?: Maybe<any>, end_time?: Maybe<any>, instrument_id?: Maybe<any>, location_id: any, student_id: any, teacher_id: string, status: string, instrument?: Maybe<{ __typename?: 'instruments', id: any, name: string, prettyName?: Maybe<string> }>, location: { __typename?: 'locations', id: any, name: string }, student: { __typename?: 'students', id: any, first_name: string, last_name: string, gdpr_name?: Maybe<string> }, user: { __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, gdpr_name?: Maybe<string> } }> };

export type UpdateLessonMutationVariables = Exact<{
  id: Scalars['uuid'];
  start_time: Scalars['timestamptz'];
  end_time: Scalars['timestamptz'];
  instrument_id: Scalars['uuid'];
  location_id: Scalars['uuid'];
  student_id: Scalars['uuid'];
  teacher_id: Scalars['String'];
}>;


export type UpdateLessonMutation = { __typename?: 'mutation_root', update_lessons_by_pk?: Maybe<{ __typename?: 'lessons', id: any }> };

export type UpdateLessonStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  status: Scalars['String'];
}>;


export type UpdateLessonStatusMutation = { __typename?: 'mutation_root', update_lessons_by_pk?: Maybe<{ __typename?: 'lessons', id: any }> };

export type UpdateLessonTimeMutationVariables = Exact<{
  id: Scalars['uuid'];
  start_time: Scalars['timestamptz'];
  end_time: Scalars['timestamptz'];
}>;


export type UpdateLessonTimeMutation = { __typename?: 'mutation_root', update_lessons_by_pk?: Maybe<{ __typename?: 'lessons', id: any, start_time?: Maybe<any>, end_time?: Maybe<any> }> };

export type GuardianCreateMutationVariables = Exact<{
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
}>;


export type GuardianCreateMutation = { __typename?: 'mutation_root', insert_users_one?: Maybe<{ __typename?: 'users', id: string }> };

export type GuardianPlansQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GuardianPlansQuery = { __typename?: 'query_root', users_by_pk?: Maybe<{ __typename?: 'users', student_users: Array<{ __typename?: 'student_users', id: any, student: { __typename?: 'students', student_plans: Array<{ __typename?: 'student_plans', plan: { __typename?: 'plans', id: any, active: boolean, lessonCount: number, lessonCountThreshold: number, lessonPrice: number, prettyName: string }, user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> }, student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string> } }>, student_plans_aggregate: { __typename?: 'student_plans_aggregate', aggregate?: Maybe<{ __typename?: 'student_plans_aggregate_fields', count: number }> } } }> }> };

export type GuardiansQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Users_Order_By;
}>;


export type GuardiansQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, email: string, phone?: Maybe<string> }>, users_aggregate: { __typename?: 'users_aggregate', aggregate?: Maybe<{ __typename?: 'users_aggregate_fields', count: number }> } };

export type GetGuardianQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetGuardianQuery = { __typename?: 'query_root', users_by_pk?: Maybe<{ __typename?: 'users', id: string, email: string, first_name?: Maybe<string>, last_name?: Maybe<string>, full_name?: Maybe<string>, title?: Maybe<string>, phone?: Maybe<string> }> };

export type UpdateGuardianMutationVariables = Exact<{
  uid: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone: Scalars['String'];
}>;


export type UpdateGuardianMutation = { __typename?: 'mutation_root', update_users_by_pk?: Maybe<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, phone?: Maybe<string> }> };

export type CreateInstrumentMutationVariables = Exact<{
  name: Scalars['String'];
  prettyName: Scalars['String'];
}>;


export type CreateInstrumentMutation = { __typename?: 'mutation_root', insert_instruments_one?: Maybe<{ __typename?: 'instruments', id: any, name: string, prettyName?: Maybe<string> }> };

export type DeleteInstrumentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteInstrumentMutation = { __typename?: 'mutation_root', delete_instruments?: Maybe<{ __typename?: 'instruments_mutation_response', returning: Array<{ __typename?: 'instruments', id: any }> }> };

export type GetInstrumentQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetInstrumentQuery = { __typename?: 'query_root', instruments_by_pk?: Maybe<{ __typename?: 'instruments', id: any, name: string, prettyName?: Maybe<string> }> };

export type AssignStudentsToInstrumentMutationVariables = Exact<{
  studentIds: Array<Student_Instruments_Insert_Input> | Student_Instruments_Insert_Input;
}>;


export type AssignStudentsToInstrumentMutation = { __typename?: 'mutation_root', insert_student_instruments?: Maybe<{ __typename?: 'student_instruments_mutation_response', affected_rows: number }> };

export type GetStudentsAndInstrumentsQueryVariables = Exact<{
  instrumentId: Scalars['uuid'];
}>;


export type GetStudentsAndInstrumentsQuery = { __typename?: 'query_root', students: Array<{ __typename?: 'students', id: any, first_name: string, last_name: string }>, instruments_by_pk?: Maybe<{ __typename?: 'instruments', student_instruments: Array<{ __typename?: 'student_instruments', student_id: any }> }> };

export type AssignTeachersToInstrumentMutationVariables = Exact<{
  teacherIds: Array<Teacher_Instruments_Insert_Input> | Teacher_Instruments_Insert_Input;
}>;


export type AssignTeachersToInstrumentMutation = { __typename?: 'mutation_root', insert_teacher_instruments?: Maybe<{ __typename?: 'teacher_instruments_mutation_response', affected_rows: number }> };

export type GetTeachersAndInstrumentsQueryVariables = Exact<{
  instrumentId: Scalars['uuid'];
}>;


export type GetTeachersAndInstrumentsQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string> }>, instruments_by_pk?: Maybe<{ __typename?: 'instruments', teacher_instruments: Array<{ __typename?: 'teacher_instruments', teacher_id: string }> }> };

export type InstrumentStudentsQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type InstrumentStudentsQuery = { __typename?: 'query_root', instruments_by_pk?: Maybe<{ __typename?: 'instruments', id: any, student_instruments: Array<{ __typename?: 'student_instruments', student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string>, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', name: string, id: any } }> } }>, student_instruments_aggregate: { __typename?: 'student_instruments_aggregate', aggregate?: Maybe<{ __typename?: 'student_instruments_aggregate_fields', count: number }> } }> };

export type InstrumentTeachersQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type InstrumentTeachersQuery = { __typename?: 'query_root', instruments_by_pk?: Maybe<{ __typename?: 'instruments', id: any, teacher_instruments: Array<{ __typename?: 'teacher_instruments', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> } }>, teacher_instruments_aggregate: { __typename?: 'teacher_instruments_aggregate', aggregate?: Maybe<{ __typename?: 'teacher_instruments_aggregate_fields', count: number }> } }> };

export type UpdateInstrumentMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  prettyName: Scalars['String'];
}>;


export type UpdateInstrumentMutation = { __typename?: 'mutation_root', update_instruments_by_pk?: Maybe<{ __typename?: 'instruments', id: any, name: string, prettyName?: Maybe<string> }> };

export type GetInstrumentsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Instruments_Order_By;
}>;


export type GetInstrumentsQuery = { __typename?: 'query_root', instruments: Array<{ __typename?: 'instruments', id: any, name: string, prettyName?: Maybe<string> }>, instruments_aggregate: { __typename?: 'instruments_aggregate', aggregate?: Maybe<{ __typename?: 'instruments_aggregate_fields', count: number }> } };

export type CreateInvoiceMutationVariables = Exact<{
  student: Scalars['uuid'];
  teacher: Scalars['String'];
  location: Scalars['uuid'];
  issuedAt: Scalars['timestamptz'];
  dueAt: Scalars['timestamptz'];
  amount?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
}>;


export type CreateInvoiceMutation = { __typename?: 'mutation_root', insert_invoices_one?: Maybe<{ __typename?: 'invoices', id: any }> };

export type InvoicesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Invoices_Order_By;
}>;


export type InvoicesQuery = { __typename?: 'query_root', invoices: Array<{ __typename?: 'invoices', id: any }>, invoices_aggregate: { __typename?: 'invoices_aggregate', aggregate?: Maybe<{ __typename?: 'invoices_aggregate_fields', count: number }> } };

export type CreateLessonsMutationVariables = Exact<{
  objects: Array<Lessons_Insert_Input> | Lessons_Insert_Input;
}>;


export type CreateLessonsMutation = { __typename?: 'mutation_root', insert_lessons?: Maybe<{ __typename?: 'lessons_mutation_response', affected_rows: number }> };

export type GetAllLessonsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Lessons_Order_By;
}>;


export type GetAllLessonsQuery = { __typename?: 'query_root', lessons: Array<{ __typename?: 'lessons', id: any, start_time?: Maybe<any>, end_time?: Maybe<any>, status: string, instrument?: Maybe<{ __typename?: 'instruments', id: any, prettyName?: Maybe<string> }>, location: { __typename?: 'locations', id: any, name: string }, user: { __typename?: 'users', id: string, full_name?: Maybe<string> }, student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string> } }>, lessons_aggregate: { __typename?: 'lessons_aggregate', aggregate?: Maybe<{ __typename?: 'lessons_aggregate_fields', count: number }> } };

export type CreateLocationMutationVariables = Exact<{
  name: Scalars['String'];
  address: Scalars['String'];
  phone: Scalars['String'];
  schoolContact: Scalars['String'];
  schoolEmail: Scalars['String'];
}>;


export type CreateLocationMutation = { __typename?: 'mutation_root', insert_locations_one?: Maybe<{ __typename?: 'locations', id: any }> };

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteLocationMutation = { __typename?: 'mutation_root', delete_locations?: Maybe<{ __typename?: 'locations_mutation_response', affected_rows: number }> };

export type GetLocationStudentsQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetLocationStudentsQuery = { __typename?: 'query_root', locations_by_pk?: Maybe<{ __typename?: 'locations', id: any, student_locations: Array<{ __typename?: 'student_locations', student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string>, student_instruments: Array<{ __typename?: 'student_instruments', instrument: { __typename?: 'instruments', prettyName?: Maybe<string> } }>, student_teachers: Array<{ __typename?: 'student_teachers', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> } }> } }>, student_locations_aggregate: { __typename?: 'student_locations_aggregate', aggregate?: Maybe<{ __typename?: 'student_locations_aggregate_fields', count: number }> } }> };

export type GetLocationTeachersQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetLocationTeachersQuery = { __typename?: 'query_root', locations_by_pk?: Maybe<{ __typename?: 'locations', id: any, teacher_locations: Array<{ __typename?: 'teacher_locations', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string>, teacher_instruments: Array<{ __typename?: 'teacher_instruments', instrument: { __typename?: 'instruments', prettyName?: Maybe<string> } }> } }>, teacher_locations_aggregate: { __typename?: 'teacher_locations_aggregate', aggregate?: Maybe<{ __typename?: 'teacher_locations_aggregate_fields', count: number }> } }> };

export type LocationsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Locations_Order_By;
}>;


export type LocationsQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'locations', id: any, name: string, address: string, phone?: Maybe<string>, school_contact?: Maybe<string>, school_email?: Maybe<string>, location_type: string }>, locations_aggregate: { __typename?: 'locations_aggregate', aggregate?: Maybe<{ __typename?: 'locations_aggregate_fields', count: number }> } };

export type GetLocationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLocationQuery = { __typename?: 'query_root', locations_by_pk?: Maybe<{ __typename?: 'locations', id: any, name: string, address: string, school_contact?: Maybe<string>, school_email?: Maybe<string>, phone?: Maybe<string>, location_type: string }> };

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  address: Scalars['String'];
  schoolContact: Scalars['String'];
  schoolEmail: Scalars['String'];
  phone: Scalars['String'];
}>;


export type UpdateLocationMutation = { __typename?: 'mutation_root', update_locations_by_pk?: Maybe<{ __typename?: 'locations', id: any, name: string, address: string, school_contact?: Maybe<string>, school_email?: Maybe<string>, phone?: Maybe<string> }> };

export type CreatePlanMutationVariables = Exact<{
  name: Scalars['String'];
  prettyName: Scalars['String'];
  lessonCount: Scalars['Int'];
  lessonPrice: Scalars['Int'];
  lessonCountThreshold: Scalars['Int'];
  lessonDuration: Scalars['Int'];
}>;


export type CreatePlanMutation = { __typename?: 'mutation_root', insert_plans_one?: Maybe<{ __typename?: 'plans', id: any }> };

export type DeletePlanMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePlanMutation = { __typename?: 'mutation_root', delete_plans?: Maybe<{ __typename?: 'plans_mutation_response', affected_rows: number }> };

export type GetPlanQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetPlanQuery = { __typename?: 'query_root', plans_by_pk?: Maybe<{ __typename?: 'plans', id: any, name: string, prettyName: string, active: boolean, lessonCount: number, lessonPrice: number, lessonCountThreshold: number, lessonDuration: number }> };

export type GetPlanLessonsQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetPlanLessonsQuery = { __typename?: 'query_root', plans_by_pk?: Maybe<{ __typename?: 'plans', id: any, lessons: Array<{ __typename?: 'lessons', id: any, start_time?: Maybe<any>, end_time?: Maybe<any>, student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string> }, user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> }, location: { __typename?: 'locations', id: any, name: string } }>, lessons_aggregate: { __typename?: 'lessons_aggregate', aggregate?: Maybe<{ __typename?: 'lessons_aggregate_fields', count: number }> } }> };

export type GetPlanStudentsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetPlanStudentsQuery = { __typename?: 'query_root', plans_by_pk?: Maybe<{ __typename?: 'plans', student_plans: Array<{ __typename?: 'student_plans', student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string> }, user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> }, location: { __typename?: 'locations', id: any, name: string } }>, student_plans_aggregate: { __typename?: 'student_plans_aggregate', aggregate?: Maybe<{ __typename?: 'student_plans_aggregate_fields', count: number }> } }> };

export type UpdatePlanMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  prettyName: Scalars['String'];
  lessonCount: Scalars['Int'];
  lessonPrice: Scalars['Int'];
  lessonCountThreshold: Scalars['Int'];
  lessonDuration: Scalars['Int'];
}>;


export type UpdatePlanMutation = { __typename?: 'mutation_root', update_plans_by_pk?: Maybe<{ __typename?: 'plans', id: any }> };

export type PlansQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Plans_Order_By;
}>;


export type PlansQuery = { __typename?: 'query_root', plans: Array<{ __typename?: 'plans', id: any, name: string, prettyName: string, lessonCount: number, lessonCountThreshold: number, lessonPrice: number, active: boolean }>, plans_aggregate: { __typename?: 'plans_aggregate', aggregate?: Maybe<{ __typename?: 'plans_aggregate_fields', count: number }> } };

export type StudentCreateMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  teacherIds: Array<Student_Teachers_Insert_Input> | Student_Teachers_Insert_Input;
  guardianIds: Array<Student_Users_Insert_Input> | Student_Users_Insert_Input;
  locationIds: Array<Student_Locations_Insert_Input> | Student_Locations_Insert_Input;
  instrumentIds: Array<Student_Instruments_Insert_Input> | Student_Instruments_Insert_Input;
}>;


export type StudentCreateMutation = { __typename?: 'mutation_root', insert_students_one?: Maybe<{ __typename?: 'students', id: any }> };

export type DeleteStudentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteStudentMutation = { __typename?: 'mutation_root', delete_students?: Maybe<{ __typename?: 'students_mutation_response', returning: Array<{ __typename?: 'students', id: any }> }> };

export type GetStudentInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetStudentInfoQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, first_name: string, last_name: string, class_code?: Maybe<string>, class_name?: Maybe<string>, notes?: Maybe<string>, student_users: Array<{ __typename?: 'student_users', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string>, email: string, phone?: Maybe<string> } }>, student_instruments: Array<{ __typename?: 'student_instruments', instrument: { __typename?: 'instruments', prettyName?: Maybe<string>, id: any } }>, student_teachers: Array<{ __typename?: 'student_teachers', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string>, email: string, phone?: Maybe<string> } }>, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', id: any, name: string } }> }>, instruments: Array<{ __typename?: 'instruments', prettyName?: Maybe<string>, id: any }> };

export type GetAdminStudentLessonsQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetAdminStudentLessonsQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, lessons: Array<{ __typename?: 'lessons', start_time?: Maybe<any>, end_time?: Maybe<any>, status: string, teacher_id: string, user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> }, location: { __typename?: 'locations', name: string }, instrument?: Maybe<{ __typename?: 'instruments', prettyName?: Maybe<string> }>, plan?: Maybe<{ __typename?: 'plans', id: any, prettyName: string }> }>, lessons_aggregate: { __typename?: 'lessons_aggregate', aggregate?: Maybe<{ __typename?: 'lessons_aggregate_fields', count: number }> } }> };

export type GetAdminStudentLocationsQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetAdminStudentLocationsQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', name: string, id: any } }>, student_locations_aggregate: { __typename?: 'student_locations_aggregate', aggregate?: Maybe<{ __typename?: 'student_locations_aggregate_fields', count: number }> } }> };

export type StudentPlanAssignMutationVariables = Exact<{
  studentId: Scalars['uuid'];
  planId: Scalars['uuid'];
  teacherId: Scalars['String'];
  instrumentId: Scalars['uuid'];
  locationId: Scalars['uuid'];
}>;


export type StudentPlanAssignMutation = { __typename?: 'mutation_root', insert_student_plans_one?: Maybe<{ __typename?: 'student_plans', id: any, student_id: any, plan_id: any, teacher_id: string, instrument_id: any, location_id: any }> };

export type GetAdminStudentPlansQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetAdminStudentPlansQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, student_plans: Array<{ __typename?: 'student_plans', id: any, current_count: number, next_student_plan_id?: Maybe<any>, auto_renew: boolean, status: string, user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> }, plan: { __typename?: 'plans', id: any, name: string, prettyName: string, lessonPrice: number, lessonCount: number, lessonCountThreshold: number, active: boolean }, instrument: { __typename?: 'instruments', id: any, prettyName?: Maybe<string>, name: string } }>, student_plans_aggregate: { __typename?: 'student_plans_aggregate', aggregate?: Maybe<{ __typename?: 'student_plans_aggregate_fields', count: number }> } }> };

export type GetStudentDetailTeachersQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetStudentDetailTeachersQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, student_teachers: Array<{ __typename?: 'student_teachers', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string>, teacher_instruments: Array<{ __typename?: 'teacher_instruments', instrument: { __typename?: 'instruments', prettyName?: Maybe<string> } }>, teacher_locations: Array<{ __typename?: 'teacher_locations', location: { __typename?: 'locations', name: string } }> } }>, student_teachers_aggregate: { __typename?: 'student_teachers_aggregate', aggregate?: Maybe<{ __typename?: 'student_teachers_aggregate_fields', count: number }> } }> };

export type UpdateStudentMutationVariables = Exact<{
  id: Scalars['uuid'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  notes: Scalars['String'];
  instrumentIds: Array<Student_Instruments_Insert_Input> | Student_Instruments_Insert_Input;
  locationIds: Array<Student_Locations_Insert_Input> | Student_Locations_Insert_Input;
  teacherIds: Array<Student_Teachers_Insert_Input> | Student_Teachers_Insert_Input;
  guardianIds: Array<Student_Users_Insert_Input> | Student_Users_Insert_Input;
}>;


export type UpdateStudentMutation = { __typename?: 'mutation_root', update_students_by_pk?: Maybe<{ __typename?: 'students', id: any, first_name: string, last_name: string, notes?: Maybe<string> }>, delete_student_instruments?: Maybe<{ __typename?: 'student_instruments_mutation_response', affected_rows: number }>, insert_student_instruments?: Maybe<{ __typename?: 'student_instruments_mutation_response', affected_rows: number }>, delete_student_locations?: Maybe<{ __typename?: 'student_locations_mutation_response', affected_rows: number }>, insert_student_locations?: Maybe<{ __typename?: 'student_locations_mutation_response', affected_rows: number }>, delete_student_teachers?: Maybe<{ __typename?: 'student_teachers_mutation_response', affected_rows: number }>, insert_student_teachers?: Maybe<{ __typename?: 'student_teachers_mutation_response', affected_rows: number }>, delete_student_users?: Maybe<{ __typename?: 'student_users_mutation_response', affected_rows: number }>, insert_student_users?: Maybe<{ __typename?: 'student_users_mutation_response', affected_rows: number }> };

export type StudentsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Students_Order_By;
}>;


export type StudentsQuery = { __typename?: 'query_root', students: Array<{ __typename?: 'students', id: any, first_name: string, last_name: string, gdpr_name?: Maybe<string>, student_users: Array<{ __typename?: 'student_users', user: { __typename?: 'users', full_name?: Maybe<string>, email: string, phone?: Maybe<string>, id: string } }>, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', name: string } }> }>, students_aggregate: { __typename?: 'students_aggregate', aggregate?: Maybe<{ __typename?: 'students_aggregate_fields', count: number }> } };

export type TeacherCreateMutationVariables = Exact<{
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
}>;


export type TeacherCreateMutation = { __typename?: 'mutation_root', insert_users_one?: Maybe<{ __typename?: 'users', id: string }> };

export type AssignStudentsToTeacherMutationVariables = Exact<{
  studentIds: Array<Student_Teachers_Insert_Input> | Student_Teachers_Insert_Input;
}>;


export type AssignStudentsToTeacherMutation = { __typename?: 'mutation_root', insert_student_teachers?: Maybe<{ __typename?: 'student_teachers_mutation_response', affected_rows: number }> };

export type GetTeacherLessonsQueryVariables = Exact<{
  uid: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetTeacherLessonsQuery = { __typename?: 'query_root', users_by_pk?: Maybe<{ __typename?: 'users', id: string, lessons: Array<{ __typename?: 'lessons', start_time?: Maybe<any>, end_time?: Maybe<any>, status: string, student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string> }, location: { __typename?: 'locations', name: string } }>, lessons_aggregate: { __typename?: 'lessons_aggregate', aggregate?: Maybe<{ __typename?: 'lessons_aggregate_fields', count: number }> } }> };

export type GetTeacherLocationsQueryVariables = Exact<{
  uid: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetTeacherLocationsQuery = { __typename?: 'query_root', users_by_pk?: Maybe<{ __typename?: 'users', teacher_locations: Array<{ __typename?: 'teacher_locations', location: { __typename?: 'locations', name: string, id: any } }>, teacher_locations_aggregate: { __typename?: 'teacher_locations_aggregate', aggregate?: Maybe<{ __typename?: 'teacher_locations_aggregate_fields', count: number }> } }> };

export type GetTeacherStudentsQueryVariables = Exact<{
  uid: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetTeacherStudentsQuery = { __typename?: 'query_root', users_by_pk?: Maybe<{ __typename?: 'users', student_teachers: Array<{ __typename?: 'student_teachers', student: { __typename?: 'students', id: any, gdpr_name?: Maybe<string>, student_instruments: Array<{ __typename?: 'student_instruments', instrument: { __typename?: 'instruments', prettyName?: Maybe<string> } }>, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', name: string } }> } }>, student_teachers_aggregate: { __typename?: 'student_teachers_aggregate', aggregate?: Maybe<{ __typename?: 'student_teachers_aggregate_fields', count: number }> } }>, students: Array<{ __typename?: 'students', id: any, first_name: string, last_name: string }> };

export type GetTeacherQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetTeacherQuery = { __typename?: 'query_root', users_by_pk?: Maybe<{ __typename?: 'users', id: string, email: string, first_name?: Maybe<string>, last_name?: Maybe<string>, full_name?: Maybe<string>, title?: Maybe<string>, phone?: Maybe<string>, teacher_instruments: Array<{ __typename?: 'teacher_instruments', instrument: { __typename?: 'instruments', id: any, name: string, prettyName?: Maybe<string> } }>, teacher_locations: Array<{ __typename?: 'teacher_locations', location: { __typename?: 'locations', id: any, name: string } }> }> };

export type UpdateTeacherMutationVariables = Exact<{
  uid: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone: Scalars['String'];
  instrumentIds: Array<Teacher_Instruments_Insert_Input> | Teacher_Instruments_Insert_Input;
  locationIds: Array<Teacher_Locations_Insert_Input> | Teacher_Locations_Insert_Input;
}>;


export type UpdateTeacherMutation = { __typename?: 'mutation_root', update_users_by_pk?: Maybe<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, phone?: Maybe<string> }>, delete_teacher_instruments?: Maybe<{ __typename?: 'teacher_instruments_mutation_response', affected_rows: number }>, insert_teacher_instruments?: Maybe<{ __typename?: 'teacher_instruments_mutation_response', affected_rows: number }>, delete_teacher_locations?: Maybe<{ __typename?: 'teacher_locations_mutation_response', affected_rows: number }>, insert_teacher_locations?: Maybe<{ __typename?: 'teacher_locations_mutation_response', affected_rows: number }> };

export type TeachersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Users_Order_By;
}>;


export type TeachersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, email: string, phone?: Maybe<string> }>, users_aggregate: { __typename?: 'users_aggregate', aggregate?: Maybe<{ __typename?: 'users_aggregate_fields', count: number }> } };

export type CreateUserMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'mutation_root', insert_users_one?: Maybe<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, full_name?: Maybe<string> }> };

export type UsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Users_Order_By;
}>;


export type UsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, title?: Maybe<string>, full_name?: Maybe<string>, email: string, phone?: Maybe<string> }>, users_aggregate: { __typename?: 'users_aggregate', aggregate?: Maybe<{ __typename?: 'users_aggregate_fields', count: number }> } };

export type GetUserQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'query_root', users_by_pk?: Maybe<{ __typename?: 'users', id: string, email: string, first_name?: Maybe<string>, last_name?: Maybe<string>, full_name?: Maybe<string>, title?: Maybe<string>, phone?: Maybe<string> }> };

export type UpdateUserMutationVariables = Exact<{
  uid: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone: Scalars['String'];
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_users_by_pk?: Maybe<{ __typename?: 'users', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, phone?: Maybe<string> }> };

export type GetAllScopedLessonsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAllScopedLessonsQuery = { __typename?: 'query_root', lessons: Array<{ __typename?: 'lessons', start_time?: Maybe<any>, end_time?: Maybe<any>, status: string, instrument?: Maybe<{ __typename?: 'instruments', prettyName?: Maybe<string> }>, location: { __typename?: 'locations', name: string }, student: { __typename?: 'students', gdpr_name?: Maybe<string> } }>, lessons_aggregate: { __typename?: 'lessons_aggregate', aggregate?: Maybe<{ __typename?: 'lessons_aggregate_fields', count: number }> } };

export type GetUpcomingScopedLessonsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUpcomingScopedLessonsQuery = { __typename?: 'query_root', lessons: Array<{ __typename?: 'lessons', id: any, start_time?: Maybe<any>, status: string, end_time?: Maybe<any>, instrument?: Maybe<{ __typename?: 'instruments', prettyName?: Maybe<string> }>, user: { __typename?: 'users', id: string }, location: { __typename?: 'locations', name: string }, student: { __typename?: 'students', gdpr_name?: Maybe<string> } }> };

export type GetStudentQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetStudentQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, first_name: string, last_name: string, class_code?: Maybe<string>, class_name?: Maybe<string>, notes?: Maybe<string>, student_users: Array<{ __typename?: 'student_users', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string>, email: string, phone?: Maybe<string> } }>, student_instruments: Array<{ __typename?: 'student_instruments', instrument: { __typename?: 'instruments', prettyName?: Maybe<string>, id: any } }> }>, instruments: Array<{ __typename?: 'instruments', prettyName?: Maybe<string>, id: any }> };

export type StudentLessonCreateScopedTeacherMutationVariables = Exact<{
  teacherId: Scalars['String'];
  studentId: Scalars['uuid'];
  instrumentId: Scalars['uuid'];
  locationId: Scalars['uuid'];
  planId: Scalars['uuid'];
  startTime: Scalars['timestamptz'];
  endTime: Scalars['timestamptz'];
}>;


export type StudentLessonCreateScopedTeacherMutation = { __typename?: 'mutation_root', insert_lessons_one?: Maybe<{ __typename?: 'lessons', id: any }> };

export type StudentLessonCreateValuesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type StudentLessonCreateValuesQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, student_instruments: Array<{ __typename?: 'student_instruments', instrument: { __typename?: 'instruments', id: any, prettyName?: Maybe<string> } }>, student_plans: Array<{ __typename?: 'student_plans', plan: { __typename?: 'plans', id: any } }>, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', id: any, name: string } }> }> };

export type GetStudentLessonsQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetStudentLessonsQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, lessons: Array<{ __typename?: 'lessons', start_time?: Maybe<any>, status: string, end_time?: Maybe<any>, teacher_id: string, user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> }, location: { __typename?: 'locations', name: string }, instrument?: Maybe<{ __typename?: 'instruments', prettyName?: Maybe<string> }>, plan?: Maybe<{ __typename?: 'plans', id: any, prettyName: string }> }>, lessons_aggregate: { __typename?: 'lessons_aggregate', aggregate?: Maybe<{ __typename?: 'lessons_aggregate_fields', count: number }> } }> };

export type GetStudentLocationsQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetStudentLocationsQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', name: string, id: any } }>, student_locations_aggregate: { __typename?: 'student_locations_aggregate', aggregate?: Maybe<{ __typename?: 'student_locations_aggregate_fields', count: number }> } }> };

export type GetStudentPlansQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetStudentPlansQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, student_plans: Array<{ __typename?: 'student_plans', id: any, user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string> }, plan: { __typename?: 'plans', id: any, lessonPrice: number, lessonCount: number, lessonCountThreshold: number, active: boolean } }>, student_plans_aggregate: { __typename?: 'student_plans_aggregate', aggregate?: Maybe<{ __typename?: 'student_plans_aggregate_fields', count: number }> } }> };

export type GetStudentTeachersQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetStudentTeachersQuery = { __typename?: 'query_root', students_by_pk?: Maybe<{ __typename?: 'students', id: any, student_teachers: Array<{ __typename?: 'student_teachers', user: { __typename?: 'users', id: string, gdpr_name?: Maybe<string>, teacher_instruments: Array<{ __typename?: 'teacher_instruments', instrument: { __typename?: 'instruments', prettyName?: Maybe<string> } }>, teacher_locations: Array<{ __typename?: 'teacher_locations', location: { __typename?: 'locations', name: string } }> } }>, student_teachers_aggregate: { __typename?: 'student_teachers_aggregate', aggregate?: Maybe<{ __typename?: 'student_teachers_aggregate_fields', count: number }> } }> };

export type StudentUpdateMutationVariables = Exact<{
  id: Scalars['uuid'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  notes: Scalars['String'];
}>;


export type StudentUpdateMutation = { __typename?: 'mutation_root', update_students_by_pk?: Maybe<{ __typename?: 'students', id: any, first_name: string, last_name: string, notes?: Maybe<string> }> };

export type GetScopedStudentsQueryVariables = Exact<{
  scopeId: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: Students_Order_By;
}>;


export type GetScopedStudentsQuery = { __typename?: 'query_root', students: Array<{ __typename?: 'students', id: any, first_name: string, last_name: string, gdpr_name?: Maybe<string>, student_users: Array<{ __typename?: 'student_users', user: { __typename?: 'users', full_name?: Maybe<string>, email: string, phone?: Maybe<string>, id: string } }>, student_locations: Array<{ __typename?: 'student_locations', location: { __typename?: 'locations', name: string } }> }>, students_aggregate: { __typename?: 'students_aggregate', aggregate?: Maybe<{ __typename?: 'students_aggregate_fields', count: number }> } };


export const AutocompleteGuardiansDocument = gql`
    query AutocompleteGuardians {
  users(where: {isGuardian: {_eq: true}}) {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useAutocompleteGuardiansQuery__
 *
 * To run a query within a React component, call `useAutocompleteGuardiansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteGuardiansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteGuardiansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutocompleteGuardiansQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteGuardiansQuery, AutocompleteGuardiansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteGuardiansQuery, AutocompleteGuardiansQueryVariables>(AutocompleteGuardiansDocument, options);
      }
export function useAutocompleteGuardiansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteGuardiansQuery, AutocompleteGuardiansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteGuardiansQuery, AutocompleteGuardiansQueryVariables>(AutocompleteGuardiansDocument, options);
        }
export type AutocompleteGuardiansQueryHookResult = ReturnType<typeof useAutocompleteGuardiansQuery>;
export type AutocompleteGuardiansLazyQueryHookResult = ReturnType<typeof useAutocompleteGuardiansLazyQuery>;
export type AutocompleteGuardiansQueryResult = Apollo.QueryResult<AutocompleteGuardiansQuery, AutocompleteGuardiansQueryVariables>;
export const AutocompleteInstrumentDocument = gql`
    query AutocompleteInstrument {
  instruments {
    id
    name
    prettyName
  }
}
    `;

/**
 * __useAutocompleteInstrumentQuery__
 *
 * To run a query within a React component, call `useAutocompleteInstrumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteInstrumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteInstrumentQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutocompleteInstrumentQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteInstrumentQuery, AutocompleteInstrumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteInstrumentQuery, AutocompleteInstrumentQueryVariables>(AutocompleteInstrumentDocument, options);
      }
export function useAutocompleteInstrumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteInstrumentQuery, AutocompleteInstrumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteInstrumentQuery, AutocompleteInstrumentQueryVariables>(AutocompleteInstrumentDocument, options);
        }
export type AutocompleteInstrumentQueryHookResult = ReturnType<typeof useAutocompleteInstrumentQuery>;
export type AutocompleteInstrumentLazyQueryHookResult = ReturnType<typeof useAutocompleteInstrumentLazyQuery>;
export type AutocompleteInstrumentQueryResult = Apollo.QueryResult<AutocompleteInstrumentQuery, AutocompleteInstrumentQueryVariables>;
export const AutocompleteLocationDocument = gql`
    query AutocompleteLocation {
  locations {
    id
    name
    location_type
  }
}
    `;

/**
 * __useAutocompleteLocationQuery__
 *
 * To run a query within a React component, call `useAutocompleteLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutocompleteLocationQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteLocationQuery, AutocompleteLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteLocationQuery, AutocompleteLocationQueryVariables>(AutocompleteLocationDocument, options);
      }
export function useAutocompleteLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteLocationQuery, AutocompleteLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteLocationQuery, AutocompleteLocationQueryVariables>(AutocompleteLocationDocument, options);
        }
export type AutocompleteLocationQueryHookResult = ReturnType<typeof useAutocompleteLocationQuery>;
export type AutocompleteLocationLazyQueryHookResult = ReturnType<typeof useAutocompleteLocationLazyQuery>;
export type AutocompleteLocationQueryResult = Apollo.QueryResult<AutocompleteLocationQuery, AutocompleteLocationQueryVariables>;
export const AutocompleteStudentsDocument = gql`
    query AutocompleteStudents {
  students {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useAutocompleteStudentsQuery__
 *
 * To run a query within a React component, call `useAutocompleteStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteStudentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutocompleteStudentsQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteStudentsQuery, AutocompleteStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteStudentsQuery, AutocompleteStudentsQueryVariables>(AutocompleteStudentsDocument, options);
      }
export function useAutocompleteStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteStudentsQuery, AutocompleteStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteStudentsQuery, AutocompleteStudentsQueryVariables>(AutocompleteStudentsDocument, options);
        }
export type AutocompleteStudentsQueryHookResult = ReturnType<typeof useAutocompleteStudentsQuery>;
export type AutocompleteStudentsLazyQueryHookResult = ReturnType<typeof useAutocompleteStudentsLazyQuery>;
export type AutocompleteStudentsQueryResult = Apollo.QueryResult<AutocompleteStudentsQuery, AutocompleteStudentsQueryVariables>;
export const AutocompleteTeachersDocument = gql`
    query AutocompleteTeachers {
  users(where: {isTeacher: {_eq: true}}) {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useAutocompleteTeachersQuery__
 *
 * To run a query within a React component, call `useAutocompleteTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteTeachersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutocompleteTeachersQuery(baseOptions?: Apollo.QueryHookOptions<AutocompleteTeachersQuery, AutocompleteTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteTeachersQuery, AutocompleteTeachersQueryVariables>(AutocompleteTeachersDocument, options);
      }
export function useAutocompleteTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteTeachersQuery, AutocompleteTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteTeachersQuery, AutocompleteTeachersQueryVariables>(AutocompleteTeachersDocument, options);
        }
export type AutocompleteTeachersQueryHookResult = ReturnType<typeof useAutocompleteTeachersQuery>;
export type AutocompleteTeachersLazyQueryHookResult = ReturnType<typeof useAutocompleteTeachersLazyQuery>;
export type AutocompleteTeachersQueryResult = Apollo.QueryResult<AutocompleteTeachersQuery, AutocompleteTeachersQueryVariables>;
export const CopyScheduleDocument = gql`
    mutation CopySchedule($input: [lessons_insert_input!]!) {
  insert_lessons(objects: $input) {
    affected_rows
  }
}
    `;
export type CopyScheduleMutationFn = Apollo.MutationFunction<CopyScheduleMutation, CopyScheduleMutationVariables>;

/**
 * __useCopyScheduleMutation__
 *
 * To run a mutation, you first call `useCopyScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyScheduleMutation, { data, loading, error }] = useCopyScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CopyScheduleMutation, CopyScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyScheduleMutation, CopyScheduleMutationVariables>(CopyScheduleDocument, options);
      }
export type CopyScheduleMutationHookResult = ReturnType<typeof useCopyScheduleMutation>;
export type CopyScheduleMutationResult = Apollo.MutationResult<CopyScheduleMutation>;
export type CopyScheduleMutationOptions = Apollo.BaseMutationOptions<CopyScheduleMutation, CopyScheduleMutationVariables>;
export const GetSourceLessonsDocument = gql`
    query GetSourceLessons($teacher_id: String!, $date: String!) {
  lessons(where: {_and: {teacher_id: {_eq: $teacher_id}, dmy: {_eq: $date}}}) {
    dmy
    start_time
    end_time
    teacher_id
    student_id
    location_id
    instrument_id
  }
}
    `;

/**
 * __useGetSourceLessonsQuery__
 *
 * To run a query within a React component, call `useGetSourceLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceLessonsQuery({
 *   variables: {
 *      teacher_id: // value for 'teacher_id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetSourceLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetSourceLessonsQuery, GetSourceLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourceLessonsQuery, GetSourceLessonsQueryVariables>(GetSourceLessonsDocument, options);
      }
export function useGetSourceLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourceLessonsQuery, GetSourceLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourceLessonsQuery, GetSourceLessonsQueryVariables>(GetSourceLessonsDocument, options);
        }
export type GetSourceLessonsQueryHookResult = ReturnType<typeof useGetSourceLessonsQuery>;
export type GetSourceLessonsLazyQueryHookResult = ReturnType<typeof useGetSourceLessonsLazyQuery>;
export type GetSourceLessonsQueryResult = Apollo.QueryResult<GetSourceLessonsQuery, GetSourceLessonsQueryVariables>;
export const GetAllTeachersDocument = gql`
    query GetAllTeachers {
  users(where: {isTeacher: {_eq: true}}) {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useGetAllTeachersQuery__
 *
 * To run a query within a React component, call `useGetAllTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTeachersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTeachersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTeachersQuery, GetAllTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTeachersQuery, GetAllTeachersQueryVariables>(GetAllTeachersDocument, options);
      }
export function useGetAllTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTeachersQuery, GetAllTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTeachersQuery, GetAllTeachersQueryVariables>(GetAllTeachersDocument, options);
        }
export type GetAllTeachersQueryHookResult = ReturnType<typeof useGetAllTeachersQuery>;
export type GetAllTeachersLazyQueryHookResult = ReturnType<typeof useGetAllTeachersLazyQuery>;
export type GetAllTeachersQueryResult = Apollo.QueryResult<GetAllTeachersQuery, GetAllTeachersQueryVariables>;
export const LessonCreateDocument = gql`
    mutation LessonCreate($input: lessons_insert_input!) {
  insert_lessons_one(object: $input) {
    id
  }
}
    `;
export type LessonCreateMutationFn = Apollo.MutationFunction<LessonCreateMutation, LessonCreateMutationVariables>;

/**
 * __useLessonCreateMutation__
 *
 * To run a mutation, you first call `useLessonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonCreateMutation, { data, loading, error }] = useLessonCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLessonCreateMutation(baseOptions?: Apollo.MutationHookOptions<LessonCreateMutation, LessonCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonCreateMutation, LessonCreateMutationVariables>(LessonCreateDocument, options);
      }
export type LessonCreateMutationHookResult = ReturnType<typeof useLessonCreateMutation>;
export type LessonCreateMutationResult = Apollo.MutationResult<LessonCreateMutation>;
export type LessonCreateMutationOptions = Apollo.BaseMutationOptions<LessonCreateMutation, LessonCreateMutationVariables>;
export const DeleteLessonDocument = gql`
    mutation DeleteLesson($id: uuid!) {
  delete_lessons(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteLessonMutationFn = Apollo.MutationFunction<DeleteLessonMutation, DeleteLessonMutationVariables>;

/**
 * __useDeleteLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonMutation, { data, loading, error }] = useDeleteLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLessonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLessonMutation, DeleteLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLessonMutation, DeleteLessonMutationVariables>(DeleteLessonDocument, options);
      }
export type DeleteLessonMutationHookResult = ReturnType<typeof useDeleteLessonMutation>;
export type DeleteLessonMutationResult = Apollo.MutationResult<DeleteLessonMutation>;
export type DeleteLessonMutationOptions = Apollo.BaseMutationOptions<DeleteLessonMutation, DeleteLessonMutationVariables>;
export const GetLessonDocument = gql`
    query GetLesson($id: uuid!) {
  lessons_by_pk(id: $id) {
    id
    start_time
    end_time
    instrument_id
    location_id
    student_id
    teacher_id
    status
    instrument {
      id
      name
      prettyName
    }
    location {
      id
      name
    }
    student {
      id
      first_name
      last_name
      gdpr_name
    }
    user {
      id
      first_name
      last_name
      gdpr_name
    }
  }
}
    `;

/**
 * __useGetLessonQuery__
 *
 * To run a query within a React component, call `useGetLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLessonQuery(baseOptions: Apollo.QueryHookOptions<GetLessonQuery, GetLessonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonQuery, GetLessonQueryVariables>(GetLessonDocument, options);
      }
export function useGetLessonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonQuery, GetLessonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonQuery, GetLessonQueryVariables>(GetLessonDocument, options);
        }
export type GetLessonQueryHookResult = ReturnType<typeof useGetLessonQuery>;
export type GetLessonLazyQueryHookResult = ReturnType<typeof useGetLessonLazyQuery>;
export type GetLessonQueryResult = Apollo.QueryResult<GetLessonQuery, GetLessonQueryVariables>;
export const UpdateLessonDocument = gql`
    mutation UpdateLesson($id: uuid!, $start_time: timestamptz!, $end_time: timestamptz!, $instrument_id: uuid!, $location_id: uuid!, $student_id: uuid!, $teacher_id: String!) {
  update_lessons_by_pk(
    pk_columns: {id: $id}
    _set: {start_time: $start_time, end_time: $end_time, instrument_id: $instrument_id, location_id: $location_id, student_id: $student_id, teacher_id: $teacher_id}
  ) {
    id
  }
}
    `;
export type UpdateLessonMutationFn = Apollo.MutationFunction<UpdateLessonMutation, UpdateLessonMutationVariables>;

/**
 * __useUpdateLessonMutation__
 *
 * To run a mutation, you first call `useUpdateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonMutation, { data, loading, error }] = useUpdateLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      instrument_id: // value for 'instrument_id'
 *      location_id: // value for 'location_id'
 *      student_id: // value for 'student_id'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useUpdateLessonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLessonMutation, UpdateLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLessonMutation, UpdateLessonMutationVariables>(UpdateLessonDocument, options);
      }
export type UpdateLessonMutationHookResult = ReturnType<typeof useUpdateLessonMutation>;
export type UpdateLessonMutationResult = Apollo.MutationResult<UpdateLessonMutation>;
export type UpdateLessonMutationOptions = Apollo.BaseMutationOptions<UpdateLessonMutation, UpdateLessonMutationVariables>;
export const UpdateLessonStatusDocument = gql`
    mutation UpdateLessonStatus($id: uuid!, $status: String!) {
  update_lessons_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
  }
}
    `;
export type UpdateLessonStatusMutationFn = Apollo.MutationFunction<UpdateLessonStatusMutation, UpdateLessonStatusMutationVariables>;

/**
 * __useUpdateLessonStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLessonStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonStatusMutation, { data, loading, error }] = useUpdateLessonStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateLessonStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLessonStatusMutation, UpdateLessonStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLessonStatusMutation, UpdateLessonStatusMutationVariables>(UpdateLessonStatusDocument, options);
      }
export type UpdateLessonStatusMutationHookResult = ReturnType<typeof useUpdateLessonStatusMutation>;
export type UpdateLessonStatusMutationResult = Apollo.MutationResult<UpdateLessonStatusMutation>;
export type UpdateLessonStatusMutationOptions = Apollo.BaseMutationOptions<UpdateLessonStatusMutation, UpdateLessonStatusMutationVariables>;
export const UpdateLessonTimeDocument = gql`
    mutation UpdateLessonTime($id: uuid!, $start_time: timestamptz!, $end_time: timestamptz!) {
  update_lessons_by_pk(
    pk_columns: {id: $id}
    _set: {start_time: $start_time, end_time: $end_time}
  ) {
    id
    start_time
    end_time
  }
}
    `;
export type UpdateLessonTimeMutationFn = Apollo.MutationFunction<UpdateLessonTimeMutation, UpdateLessonTimeMutationVariables>;

/**
 * __useUpdateLessonTimeMutation__
 *
 * To run a mutation, you first call `useUpdateLessonTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonTimeMutation, { data, loading, error }] = useUpdateLessonTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *   },
 * });
 */
export function useUpdateLessonTimeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLessonTimeMutation, UpdateLessonTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLessonTimeMutation, UpdateLessonTimeMutationVariables>(UpdateLessonTimeDocument, options);
      }
export type UpdateLessonTimeMutationHookResult = ReturnType<typeof useUpdateLessonTimeMutation>;
export type UpdateLessonTimeMutationResult = Apollo.MutationResult<UpdateLessonTimeMutation>;
export type UpdateLessonTimeMutationOptions = Apollo.BaseMutationOptions<UpdateLessonTimeMutation, UpdateLessonTimeMutationVariables>;
export const GuardianCreateDocument = gql`
    mutation GuardianCreate($id: String!, $firstName: String!, $lastName: String!, $email: String!, $phone: String) {
  insert_users_one(
    object: {id: $id, first_name: $firstName, last_name: $lastName, email: $email, phone: $phone, isGuardian: true}
  ) {
    id
  }
}
    `;
export type GuardianCreateMutationFn = Apollo.MutationFunction<GuardianCreateMutation, GuardianCreateMutationVariables>;

/**
 * __useGuardianCreateMutation__
 *
 * To run a mutation, you first call `useGuardianCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuardianCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guardianCreateMutation, { data, loading, error }] = useGuardianCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useGuardianCreateMutation(baseOptions?: Apollo.MutationHookOptions<GuardianCreateMutation, GuardianCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuardianCreateMutation, GuardianCreateMutationVariables>(GuardianCreateDocument, options);
      }
export type GuardianCreateMutationHookResult = ReturnType<typeof useGuardianCreateMutation>;
export type GuardianCreateMutationResult = Apollo.MutationResult<GuardianCreateMutation>;
export type GuardianCreateMutationOptions = Apollo.BaseMutationOptions<GuardianCreateMutation, GuardianCreateMutationVariables>;
export const GuardianPlansDocument = gql`
    query GuardianPlans($uid: String!) {
  users_by_pk(id: $uid) {
    student_users {
      id
      student {
        student_plans {
          plan {
            id
            active
            lessonCount
            lessonCountThreshold
            lessonPrice
            prettyName
          }
          user {
            id
            gdpr_name
          }
          student {
            id
            gdpr_name
          }
        }
        student_plans_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGuardianPlansQuery__
 *
 * To run a query within a React component, call `useGuardianPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuardianPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuardianPlansQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGuardianPlansQuery(baseOptions: Apollo.QueryHookOptions<GuardianPlansQuery, GuardianPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuardianPlansQuery, GuardianPlansQueryVariables>(GuardianPlansDocument, options);
      }
export function useGuardianPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuardianPlansQuery, GuardianPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuardianPlansQuery, GuardianPlansQueryVariables>(GuardianPlansDocument, options);
        }
export type GuardianPlansQueryHookResult = ReturnType<typeof useGuardianPlansQuery>;
export type GuardianPlansLazyQueryHookResult = ReturnType<typeof useGuardianPlansLazyQuery>;
export type GuardianPlansQueryResult = Apollo.QueryResult<GuardianPlansQuery, GuardianPlansQueryVariables>;
export const GuardiansDocument = gql`
    query Guardians($limit: Int!, $offset: Int!, $order_by: users_order_by!) {
  users(
    where: {isGuardian: {_eq: true}}
    order_by: [$order_by]
    limit: $limit
    offset: $offset
  ) {
    id
    first_name
    last_name
    email
    phone
  }
  users_aggregate(where: {isGuardian: {_eq: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGuardiansQuery__
 *
 * To run a query within a React component, call `useGuardiansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuardiansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuardiansQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGuardiansQuery(baseOptions: Apollo.QueryHookOptions<GuardiansQuery, GuardiansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuardiansQuery, GuardiansQueryVariables>(GuardiansDocument, options);
      }
export function useGuardiansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuardiansQuery, GuardiansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuardiansQuery, GuardiansQueryVariables>(GuardiansDocument, options);
        }
export type GuardiansQueryHookResult = ReturnType<typeof useGuardiansQuery>;
export type GuardiansLazyQueryHookResult = ReturnType<typeof useGuardiansLazyQuery>;
export type GuardiansQueryResult = Apollo.QueryResult<GuardiansQuery, GuardiansQueryVariables>;
export const GetGuardianDocument = gql`
    query GetGuardian($uid: String!) {
  users_by_pk(id: $uid) {
    id
    email
    first_name
    last_name
    full_name
    title
    phone
  }
}
    `;

/**
 * __useGetGuardianQuery__
 *
 * To run a query within a React component, call `useGetGuardianQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuardianQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuardianQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetGuardianQuery(baseOptions: Apollo.QueryHookOptions<GetGuardianQuery, GetGuardianQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuardianQuery, GetGuardianQueryVariables>(GetGuardianDocument, options);
      }
export function useGetGuardianLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuardianQuery, GetGuardianQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuardianQuery, GetGuardianQueryVariables>(GetGuardianDocument, options);
        }
export type GetGuardianQueryHookResult = ReturnType<typeof useGetGuardianQuery>;
export type GetGuardianLazyQueryHookResult = ReturnType<typeof useGetGuardianLazyQuery>;
export type GetGuardianQueryResult = Apollo.QueryResult<GetGuardianQuery, GetGuardianQueryVariables>;
export const UpdateGuardianDocument = gql`
    mutation UpdateGuardian($uid: String!, $first_name: String!, $last_name: String!, $phone: String!) {
  update_users_by_pk(
    pk_columns: {id: $uid}
    _set: {first_name: $first_name, last_name: $last_name, phone: $phone}
  ) {
    id
    first_name
    last_name
    phone
  }
}
    `;
export type UpdateGuardianMutationFn = Apollo.MutationFunction<UpdateGuardianMutation, UpdateGuardianMutationVariables>;

/**
 * __useUpdateGuardianMutation__
 *
 * To run a mutation, you first call `useUpdateGuardianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGuardianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGuardianMutation, { data, loading, error }] = useUpdateGuardianMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateGuardianMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGuardianMutation, UpdateGuardianMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGuardianMutation, UpdateGuardianMutationVariables>(UpdateGuardianDocument, options);
      }
export type UpdateGuardianMutationHookResult = ReturnType<typeof useUpdateGuardianMutation>;
export type UpdateGuardianMutationResult = Apollo.MutationResult<UpdateGuardianMutation>;
export type UpdateGuardianMutationOptions = Apollo.BaseMutationOptions<UpdateGuardianMutation, UpdateGuardianMutationVariables>;
export const CreateInstrumentDocument = gql`
    mutation CreateInstrument($name: String!, $prettyName: String!) {
  insert_instruments_one(object: {name: $name, prettyName: $prettyName}) {
    id
    name
    prettyName
  }
}
    `;
export type CreateInstrumentMutationFn = Apollo.MutationFunction<CreateInstrumentMutation, CreateInstrumentMutationVariables>;

/**
 * __useCreateInstrumentMutation__
 *
 * To run a mutation, you first call `useCreateInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstrumentMutation, { data, loading, error }] = useCreateInstrumentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      prettyName: // value for 'prettyName'
 *   },
 * });
 */
export function useCreateInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstrumentMutation, CreateInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstrumentMutation, CreateInstrumentMutationVariables>(CreateInstrumentDocument, options);
      }
export type CreateInstrumentMutationHookResult = ReturnType<typeof useCreateInstrumentMutation>;
export type CreateInstrumentMutationResult = Apollo.MutationResult<CreateInstrumentMutation>;
export type CreateInstrumentMutationOptions = Apollo.BaseMutationOptions<CreateInstrumentMutation, CreateInstrumentMutationVariables>;
export const DeleteInstrumentDocument = gql`
    mutation DeleteInstrument($id: uuid!) {
  delete_instruments(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteInstrumentMutationFn = Apollo.MutationFunction<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>;

/**
 * __useDeleteInstrumentMutation__
 *
 * To run a mutation, you first call `useDeleteInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstrumentMutation, { data, loading, error }] = useDeleteInstrumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>(DeleteInstrumentDocument, options);
      }
export type DeleteInstrumentMutationHookResult = ReturnType<typeof useDeleteInstrumentMutation>;
export type DeleteInstrumentMutationResult = Apollo.MutationResult<DeleteInstrumentMutation>;
export type DeleteInstrumentMutationOptions = Apollo.BaseMutationOptions<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>;
export const GetInstrumentDocument = gql`
    query GetInstrument($id: uuid!) {
  instruments_by_pk(id: $id) {
    id
    name
    prettyName
  }
}
    `;

/**
 * __useGetInstrumentQuery__
 *
 * To run a query within a React component, call `useGetInstrumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstrumentQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentQuery, GetInstrumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentQuery, GetInstrumentQueryVariables>(GetInstrumentDocument, options);
      }
export function useGetInstrumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentQuery, GetInstrumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentQuery, GetInstrumentQueryVariables>(GetInstrumentDocument, options);
        }
export type GetInstrumentQueryHookResult = ReturnType<typeof useGetInstrumentQuery>;
export type GetInstrumentLazyQueryHookResult = ReturnType<typeof useGetInstrumentLazyQuery>;
export type GetInstrumentQueryResult = Apollo.QueryResult<GetInstrumentQuery, GetInstrumentQueryVariables>;
export const AssignStudentsToInstrumentDocument = gql`
    mutation AssignStudentsToInstrument($studentIds: [student_instruments_insert_input!]!) {
  insert_student_instruments(objects: $studentIds) {
    affected_rows
  }
}
    `;
export type AssignStudentsToInstrumentMutationFn = Apollo.MutationFunction<AssignStudentsToInstrumentMutation, AssignStudentsToInstrumentMutationVariables>;

/**
 * __useAssignStudentsToInstrumentMutation__
 *
 * To run a mutation, you first call `useAssignStudentsToInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStudentsToInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStudentsToInstrumentMutation, { data, loading, error }] = useAssignStudentsToInstrumentMutation({
 *   variables: {
 *      studentIds: // value for 'studentIds'
 *   },
 * });
 */
export function useAssignStudentsToInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<AssignStudentsToInstrumentMutation, AssignStudentsToInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStudentsToInstrumentMutation, AssignStudentsToInstrumentMutationVariables>(AssignStudentsToInstrumentDocument, options);
      }
export type AssignStudentsToInstrumentMutationHookResult = ReturnType<typeof useAssignStudentsToInstrumentMutation>;
export type AssignStudentsToInstrumentMutationResult = Apollo.MutationResult<AssignStudentsToInstrumentMutation>;
export type AssignStudentsToInstrumentMutationOptions = Apollo.BaseMutationOptions<AssignStudentsToInstrumentMutation, AssignStudentsToInstrumentMutationVariables>;
export const GetStudentsAndInstrumentsDocument = gql`
    query GetStudentsAndInstruments($instrumentId: uuid!) {
  students {
    id
    first_name
    last_name
  }
  instruments_by_pk(id: $instrumentId) {
    student_instruments {
      student_id
    }
  }
}
    `;

/**
 * __useGetStudentsAndInstrumentsQuery__
 *
 * To run a query within a React component, call `useGetStudentsAndInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsAndInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsAndInstrumentsQuery({
 *   variables: {
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useGetStudentsAndInstrumentsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentsAndInstrumentsQuery, GetStudentsAndInstrumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsAndInstrumentsQuery, GetStudentsAndInstrumentsQueryVariables>(GetStudentsAndInstrumentsDocument, options);
      }
export function useGetStudentsAndInstrumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsAndInstrumentsQuery, GetStudentsAndInstrumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsAndInstrumentsQuery, GetStudentsAndInstrumentsQueryVariables>(GetStudentsAndInstrumentsDocument, options);
        }
export type GetStudentsAndInstrumentsQueryHookResult = ReturnType<typeof useGetStudentsAndInstrumentsQuery>;
export type GetStudentsAndInstrumentsLazyQueryHookResult = ReturnType<typeof useGetStudentsAndInstrumentsLazyQuery>;
export type GetStudentsAndInstrumentsQueryResult = Apollo.QueryResult<GetStudentsAndInstrumentsQuery, GetStudentsAndInstrumentsQueryVariables>;
export const AssignTeachersToInstrumentDocument = gql`
    mutation AssignTeachersToInstrument($teacherIds: [teacher_instruments_insert_input!]!) {
  insert_teacher_instruments(objects: $teacherIds) {
    affected_rows
  }
}
    `;
export type AssignTeachersToInstrumentMutationFn = Apollo.MutationFunction<AssignTeachersToInstrumentMutation, AssignTeachersToInstrumentMutationVariables>;

/**
 * __useAssignTeachersToInstrumentMutation__
 *
 * To run a mutation, you first call `useAssignTeachersToInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTeachersToInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTeachersToInstrumentMutation, { data, loading, error }] = useAssignTeachersToInstrumentMutation({
 *   variables: {
 *      teacherIds: // value for 'teacherIds'
 *   },
 * });
 */
export function useAssignTeachersToInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<AssignTeachersToInstrumentMutation, AssignTeachersToInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTeachersToInstrumentMutation, AssignTeachersToInstrumentMutationVariables>(AssignTeachersToInstrumentDocument, options);
      }
export type AssignTeachersToInstrumentMutationHookResult = ReturnType<typeof useAssignTeachersToInstrumentMutation>;
export type AssignTeachersToInstrumentMutationResult = Apollo.MutationResult<AssignTeachersToInstrumentMutation>;
export type AssignTeachersToInstrumentMutationOptions = Apollo.BaseMutationOptions<AssignTeachersToInstrumentMutation, AssignTeachersToInstrumentMutationVariables>;
export const GetTeachersAndInstrumentsDocument = gql`
    query GetTeachersAndInstruments($instrumentId: uuid!) {
  users(where: {isTeacher: {_eq: true}}) {
    id
    first_name
    last_name
  }
  instruments_by_pk(id: $instrumentId) {
    teacher_instruments {
      teacher_id
    }
  }
}
    `;

/**
 * __useGetTeachersAndInstrumentsQuery__
 *
 * To run a query within a React component, call `useGetTeachersAndInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeachersAndInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeachersAndInstrumentsQuery({
 *   variables: {
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useGetTeachersAndInstrumentsQuery(baseOptions: Apollo.QueryHookOptions<GetTeachersAndInstrumentsQuery, GetTeachersAndInstrumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeachersAndInstrumentsQuery, GetTeachersAndInstrumentsQueryVariables>(GetTeachersAndInstrumentsDocument, options);
      }
export function useGetTeachersAndInstrumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeachersAndInstrumentsQuery, GetTeachersAndInstrumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeachersAndInstrumentsQuery, GetTeachersAndInstrumentsQueryVariables>(GetTeachersAndInstrumentsDocument, options);
        }
export type GetTeachersAndInstrumentsQueryHookResult = ReturnType<typeof useGetTeachersAndInstrumentsQuery>;
export type GetTeachersAndInstrumentsLazyQueryHookResult = ReturnType<typeof useGetTeachersAndInstrumentsLazyQuery>;
export type GetTeachersAndInstrumentsQueryResult = Apollo.QueryResult<GetTeachersAndInstrumentsQuery, GetTeachersAndInstrumentsQueryVariables>;
export const InstrumentStudentsDocument = gql`
    query InstrumentStudents($id: uuid!, $limit: Int!, $offset: Int!) {
  instruments_by_pk(id: $id) {
    id
    student_instruments(limit: $limit, offset: $offset) {
      student {
        id
        gdpr_name
        student_locations {
          location {
            name
            id
          }
        }
      }
    }
    student_instruments_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useInstrumentStudentsQuery__
 *
 * To run a query within a React component, call `useInstrumentStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstrumentStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstrumentStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useInstrumentStudentsQuery(baseOptions: Apollo.QueryHookOptions<InstrumentStudentsQuery, InstrumentStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstrumentStudentsQuery, InstrumentStudentsQueryVariables>(InstrumentStudentsDocument, options);
      }
export function useInstrumentStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstrumentStudentsQuery, InstrumentStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstrumentStudentsQuery, InstrumentStudentsQueryVariables>(InstrumentStudentsDocument, options);
        }
export type InstrumentStudentsQueryHookResult = ReturnType<typeof useInstrumentStudentsQuery>;
export type InstrumentStudentsLazyQueryHookResult = ReturnType<typeof useInstrumentStudentsLazyQuery>;
export type InstrumentStudentsQueryResult = Apollo.QueryResult<InstrumentStudentsQuery, InstrumentStudentsQueryVariables>;
export const InstrumentTeachersDocument = gql`
    query InstrumentTeachers($id: uuid!, $limit: Int!, $offset: Int!) {
  instruments_by_pk(id: $id) {
    id
    teacher_instruments(limit: $limit, offset: $offset) {
      user {
        id
        gdpr_name
      }
    }
    teacher_instruments_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useInstrumentTeachersQuery__
 *
 * To run a query within a React component, call `useInstrumentTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstrumentTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstrumentTeachersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useInstrumentTeachersQuery(baseOptions: Apollo.QueryHookOptions<InstrumentTeachersQuery, InstrumentTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstrumentTeachersQuery, InstrumentTeachersQueryVariables>(InstrumentTeachersDocument, options);
      }
export function useInstrumentTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstrumentTeachersQuery, InstrumentTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstrumentTeachersQuery, InstrumentTeachersQueryVariables>(InstrumentTeachersDocument, options);
        }
export type InstrumentTeachersQueryHookResult = ReturnType<typeof useInstrumentTeachersQuery>;
export type InstrumentTeachersLazyQueryHookResult = ReturnType<typeof useInstrumentTeachersLazyQuery>;
export type InstrumentTeachersQueryResult = Apollo.QueryResult<InstrumentTeachersQuery, InstrumentTeachersQueryVariables>;
export const UpdateInstrumentDocument = gql`
    mutation UpdateInstrument($id: uuid!, $name: String!, $prettyName: String!) {
  update_instruments_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, prettyName: $prettyName}
  ) {
    id
    name
    prettyName
  }
}
    `;
export type UpdateInstrumentMutationFn = Apollo.MutationFunction<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>;

/**
 * __useUpdateInstrumentMutation__
 *
 * To run a mutation, you first call `useUpdateInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstrumentMutation, { data, loading, error }] = useUpdateInstrumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      prettyName: // value for 'prettyName'
 *   },
 * });
 */
export function useUpdateInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>(UpdateInstrumentDocument, options);
      }
export type UpdateInstrumentMutationHookResult = ReturnType<typeof useUpdateInstrumentMutation>;
export type UpdateInstrumentMutationResult = Apollo.MutationResult<UpdateInstrumentMutation>;
export type UpdateInstrumentMutationOptions = Apollo.BaseMutationOptions<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>;
export const GetInstrumentsDocument = gql`
    query GetInstruments($limit: Int!, $offset: Int!, $order_by: instruments_order_by!) {
  instruments(limit: $limit, offset: $offset, order_by: [$order_by]) {
    id
    name
    prettyName
  }
  instruments_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetInstrumentsQuery__
 *
 * To run a query within a React component, call `useGetInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetInstrumentsQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentsQuery, GetInstrumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentsQuery, GetInstrumentsQueryVariables>(GetInstrumentsDocument, options);
      }
export function useGetInstrumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentsQuery, GetInstrumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentsQuery, GetInstrumentsQueryVariables>(GetInstrumentsDocument, options);
        }
export type GetInstrumentsQueryHookResult = ReturnType<typeof useGetInstrumentsQuery>;
export type GetInstrumentsLazyQueryHookResult = ReturnType<typeof useGetInstrumentsLazyQuery>;
export type GetInstrumentsQueryResult = Apollo.QueryResult<GetInstrumentsQuery, GetInstrumentsQueryVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($student: uuid!, $teacher: String!, $location: uuid!, $issuedAt: timestamptz!, $dueAt: timestamptz!, $amount: Int, $type: String!) {
  insert_invoices_one(
    object: {student_id: $student, teacher_id: $teacher, location_id: $location, issued_at: $issuedAt, due_at: $dueAt, amount: $amount, type: $type}
  ) {
    id
  }
}
    `;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      student: // value for 'student'
 *      teacher: // value for 'teacher'
 *      location: // value for 'location'
 *      issuedAt: // value for 'issuedAt'
 *      dueAt: // value for 'dueAt'
 *      amount: // value for 'amount'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const InvoicesDocument = gql`
    query Invoices($limit: Int!, $offset: Int!, $order_by: invoices_order_by!) {
  invoices(order_by: [$order_by], limit: $limit, offset: $offset) {
    id
  }
  invoices_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const CreateLessonsDocument = gql`
    mutation CreateLessons($objects: [lessons_insert_input!]!) {
  insert_lessons(objects: $objects) {
    affected_rows
  }
}
    `;
export type CreateLessonsMutationFn = Apollo.MutationFunction<CreateLessonsMutation, CreateLessonsMutationVariables>;

/**
 * __useCreateLessonsMutation__
 *
 * To run a mutation, you first call `useCreateLessonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonsMutation, { data, loading, error }] = useCreateLessonsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useCreateLessonsMutation(baseOptions?: Apollo.MutationHookOptions<CreateLessonsMutation, CreateLessonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLessonsMutation, CreateLessonsMutationVariables>(CreateLessonsDocument, options);
      }
export type CreateLessonsMutationHookResult = ReturnType<typeof useCreateLessonsMutation>;
export type CreateLessonsMutationResult = Apollo.MutationResult<CreateLessonsMutation>;
export type CreateLessonsMutationOptions = Apollo.BaseMutationOptions<CreateLessonsMutation, CreateLessonsMutationVariables>;
export const GetAllLessonsDocument = gql`
    query GetAllLessons($limit: Int!, $offset: Int!, $order_by: lessons_order_by!) {
  lessons(order_by: [$order_by], limit: $limit, offset: $offset) {
    id
    start_time
    end_time
    status
    instrument {
      id
      prettyName
    }
    location {
      id
      name
    }
    user {
      id
      full_name
    }
    student {
      id
      gdpr_name
    }
  }
  lessons_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllLessonsQuery__
 *
 * To run a query within a React component, call `useGetAllLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLessonsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAllLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetAllLessonsQuery, GetAllLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLessonsQuery, GetAllLessonsQueryVariables>(GetAllLessonsDocument, options);
      }
export function useGetAllLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLessonsQuery, GetAllLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLessonsQuery, GetAllLessonsQueryVariables>(GetAllLessonsDocument, options);
        }
export type GetAllLessonsQueryHookResult = ReturnType<typeof useGetAllLessonsQuery>;
export type GetAllLessonsLazyQueryHookResult = ReturnType<typeof useGetAllLessonsLazyQuery>;
export type GetAllLessonsQueryResult = Apollo.QueryResult<GetAllLessonsQuery, GetAllLessonsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($name: String!, $address: String!, $phone: String!, $schoolContact: String!, $schoolEmail: String!) {
  insert_locations_one(
    object: {name: $name, address: $address, phone: $phone, school_contact: $schoolContact, school_email: $schoolEmail}
  ) {
    id
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      phone: // value for 'phone'
 *      schoolContact: // value for 'schoolContact'
 *      schoolEmail: // value for 'schoolEmail'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: uuid!) {
  delete_locations(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const GetLocationStudentsDocument = gql`
    query GetLocationStudents($id: uuid!, $limit: Int!, $offset: Int!) {
  locations_by_pk(id: $id) {
    id
    student_locations(limit: $limit, offset: $offset) {
      student {
        id
        gdpr_name
        student_instruments {
          instrument {
            prettyName
          }
        }
        student_teachers {
          user {
            id
            gdpr_name
          }
        }
      }
    }
    student_locations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetLocationStudentsQuery__
 *
 * To run a query within a React component, call `useGetLocationStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetLocationStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationStudentsQuery, GetLocationStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationStudentsQuery, GetLocationStudentsQueryVariables>(GetLocationStudentsDocument, options);
      }
export function useGetLocationStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationStudentsQuery, GetLocationStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationStudentsQuery, GetLocationStudentsQueryVariables>(GetLocationStudentsDocument, options);
        }
export type GetLocationStudentsQueryHookResult = ReturnType<typeof useGetLocationStudentsQuery>;
export type GetLocationStudentsLazyQueryHookResult = ReturnType<typeof useGetLocationStudentsLazyQuery>;
export type GetLocationStudentsQueryResult = Apollo.QueryResult<GetLocationStudentsQuery, GetLocationStudentsQueryVariables>;
export const GetLocationTeachersDocument = gql`
    query GetLocationTeachers($id: uuid!, $limit: Int!, $offset: Int!) {
  locations_by_pk(id: $id) {
    id
    teacher_locations(limit: $limit, offset: $offset) {
      user {
        id
        gdpr_name
        teacher_instruments {
          instrument {
            prettyName
          }
        }
      }
    }
    teacher_locations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetLocationTeachersQuery__
 *
 * To run a query within a React component, call `useGetLocationTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationTeachersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetLocationTeachersQuery(baseOptions: Apollo.QueryHookOptions<GetLocationTeachersQuery, GetLocationTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationTeachersQuery, GetLocationTeachersQueryVariables>(GetLocationTeachersDocument, options);
      }
export function useGetLocationTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationTeachersQuery, GetLocationTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationTeachersQuery, GetLocationTeachersQueryVariables>(GetLocationTeachersDocument, options);
        }
export type GetLocationTeachersQueryHookResult = ReturnType<typeof useGetLocationTeachersQuery>;
export type GetLocationTeachersLazyQueryHookResult = ReturnType<typeof useGetLocationTeachersLazyQuery>;
export type GetLocationTeachersQueryResult = Apollo.QueryResult<GetLocationTeachersQuery, GetLocationTeachersQueryVariables>;
export const LocationsDocument = gql`
    query Locations($limit: Int!, $offset: Int!, $order_by: locations_order_by!) {
  locations(order_by: [$order_by], limit: $limit, offset: $offset) {
    id
    name
    address
    phone
    school_contact
    school_email
    location_type
  }
  locations_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const GetLocationDocument = gql`
    query GetLocation($id: uuid!) {
  locations_by_pk(id: $id) {
    id
    name
    address
    school_contact
    school_email
    phone
    location_type
  }
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($id: uuid!, $name: String!, $address: String!, $schoolContact: String!, $schoolEmail: String!, $phone: String!) {
  update_locations_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, address: $address, school_contact: $schoolContact, school_email: $schoolEmail, phone: $phone}
  ) {
    id
    name
    address
    school_contact
    school_email
    phone
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      schoolContact: // value for 'schoolContact'
 *      schoolEmail: // value for 'schoolEmail'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const CreatePlanDocument = gql`
    mutation CreatePlan($name: String!, $prettyName: String!, $lessonCount: Int!, $lessonPrice: Int!, $lessonCountThreshold: Int!, $lessonDuration: Int!) {
  insert_plans_one(
    object: {name: $name, prettyName: $prettyName, lessonCount: $lessonCount, lessonPrice: $lessonPrice, lessonCountThreshold: $lessonCountThreshold, lessonDuration: $lessonDuration}
  ) {
    id
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      name: // value for 'name'
 *      prettyName: // value for 'prettyName'
 *      lessonCount: // value for 'lessonCount'
 *      lessonPrice: // value for 'lessonPrice'
 *      lessonCountThreshold: // value for 'lessonCountThreshold'
 *      lessonDuration: // value for 'lessonDuration'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const DeletePlanDocument = gql`
    mutation DeletePlan($id: uuid!) {
  delete_plans(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, options);
      }
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const GetPlanDocument = gql`
    query GetPlan($id: uuid!) {
  plans_by_pk(id: $id) {
    id
    name
    prettyName
    active
    lessonCount
    lessonPrice
    lessonCountThreshold
    lessonDuration
  }
}
    `;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const GetPlanLessonsDocument = gql`
    query GetPlanLessons($id: uuid!, $limit: Int!, $offset: Int!) {
  plans_by_pk(id: $id) {
    id
    lessons(limit: $limit, offset: $offset) {
      id
      start_time
      end_time
      student {
        id
        gdpr_name
      }
      user {
        id
        gdpr_name
      }
      location {
        id
        name
      }
    }
    lessons_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetPlanLessonsQuery__
 *
 * To run a query within a React component, call `useGetPlanLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanLessonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPlanLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanLessonsQuery, GetPlanLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanLessonsQuery, GetPlanLessonsQueryVariables>(GetPlanLessonsDocument, options);
      }
export function useGetPlanLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanLessonsQuery, GetPlanLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanLessonsQuery, GetPlanLessonsQueryVariables>(GetPlanLessonsDocument, options);
        }
export type GetPlanLessonsQueryHookResult = ReturnType<typeof useGetPlanLessonsQuery>;
export type GetPlanLessonsLazyQueryHookResult = ReturnType<typeof useGetPlanLessonsLazyQuery>;
export type GetPlanLessonsQueryResult = Apollo.QueryResult<GetPlanLessonsQuery, GetPlanLessonsQueryVariables>;
export const GetPlanStudentsDocument = gql`
    query GetPlanStudents($id: uuid!) {
  plans_by_pk(id: $id) {
    student_plans {
      student {
        id
        gdpr_name
      }
      user {
        id
        gdpr_name
      }
      location {
        id
        name
      }
    }
    student_plans_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetPlanStudentsQuery__
 *
 * To run a query within a React component, call `useGetPlanStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlanStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanStudentsQuery, GetPlanStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanStudentsQuery, GetPlanStudentsQueryVariables>(GetPlanStudentsDocument, options);
      }
export function useGetPlanStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanStudentsQuery, GetPlanStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanStudentsQuery, GetPlanStudentsQueryVariables>(GetPlanStudentsDocument, options);
        }
export type GetPlanStudentsQueryHookResult = ReturnType<typeof useGetPlanStudentsQuery>;
export type GetPlanStudentsLazyQueryHookResult = ReturnType<typeof useGetPlanStudentsLazyQuery>;
export type GetPlanStudentsQueryResult = Apollo.QueryResult<GetPlanStudentsQuery, GetPlanStudentsQueryVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($id: uuid!, $name: String!, $prettyName: String!, $lessonCount: Int!, $lessonPrice: Int!, $lessonCountThreshold: Int!, $lessonDuration: Int!) {
  update_plans_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, prettyName: $prettyName, lessonCount: $lessonCount, lessonPrice: $lessonPrice, lessonCountThreshold: $lessonCountThreshold, lessonDuration: $lessonDuration}
  ) {
    id
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      prettyName: // value for 'prettyName'
 *      lessonCount: // value for 'lessonCount'
 *      lessonPrice: // value for 'lessonPrice'
 *      lessonCountThreshold: // value for 'lessonCountThreshold'
 *      lessonDuration: // value for 'lessonDuration'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const PlansDocument = gql`
    query Plans($limit: Int!, $offset: Int!, $order_by: plans_order_by!) {
  plans(order_by: [$order_by], limit: $limit, offset: $offset) {
    id
    name
    prettyName
    lessonCount
    lessonCountThreshold
    lessonPrice
    active
  }
  plans_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function usePlansQuery(baseOptions: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const StudentCreateDocument = gql`
    mutation StudentCreate($firstName: String!, $lastName: String!, $notes: String, $teacherIds: [student_teachers_insert_input!]!, $guardianIds: [student_users_insert_input!]!, $locationIds: [student_locations_insert_input!]!, $instrumentIds: [student_instruments_insert_input!]!) {
  insert_students_one(
    object: {first_name: $firstName, last_name: $lastName, notes: $notes, student_locations: {data: $locationIds}, student_teachers: {data: $teacherIds}, student_users: {data: $guardianIds}, student_instruments: {data: $instrumentIds}}
  ) {
    id
  }
}
    `;
export type StudentCreateMutationFn = Apollo.MutationFunction<StudentCreateMutation, StudentCreateMutationVariables>;

/**
 * __useStudentCreateMutation__
 *
 * To run a mutation, you first call `useStudentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentCreateMutation, { data, loading, error }] = useStudentCreateMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      notes: // value for 'notes'
 *      teacherIds: // value for 'teacherIds'
 *      guardianIds: // value for 'guardianIds'
 *      locationIds: // value for 'locationIds'
 *      instrumentIds: // value for 'instrumentIds'
 *   },
 * });
 */
export function useStudentCreateMutation(baseOptions?: Apollo.MutationHookOptions<StudentCreateMutation, StudentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentCreateMutation, StudentCreateMutationVariables>(StudentCreateDocument, options);
      }
export type StudentCreateMutationHookResult = ReturnType<typeof useStudentCreateMutation>;
export type StudentCreateMutationResult = Apollo.MutationResult<StudentCreateMutation>;
export type StudentCreateMutationOptions = Apollo.BaseMutationOptions<StudentCreateMutation, StudentCreateMutationVariables>;
export const DeleteStudentDocument = gql`
    mutation DeleteStudent($id: uuid!) {
  delete_students(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteStudentMutationFn = Apollo.MutationFunction<DeleteStudentMutation, DeleteStudentMutationVariables>;

/**
 * __useDeleteStudentMutation__
 *
 * To run a mutation, you first call `useDeleteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentMutation, { data, loading, error }] = useDeleteStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStudentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentMutation, DeleteStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentMutation, DeleteStudentMutationVariables>(DeleteStudentDocument, options);
      }
export type DeleteStudentMutationHookResult = ReturnType<typeof useDeleteStudentMutation>;
export type DeleteStudentMutationResult = Apollo.MutationResult<DeleteStudentMutation>;
export type DeleteStudentMutationOptions = Apollo.BaseMutationOptions<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const GetStudentInfoDocument = gql`
    query GetStudentInfo($id: uuid!) {
  students_by_pk(id: $id) {
    id
    first_name
    last_name
    class_code
    class_name
    notes
    student_users {
      user {
        id
        gdpr_name
        email
        phone
      }
    }
    student_instruments {
      instrument {
        prettyName
        id
      }
    }
    student_teachers {
      user {
        id
        gdpr_name
        email
        phone
      }
    }
    student_locations {
      location {
        id
        name
      }
    }
  }
  instruments {
    prettyName
    id
  }
}
    `;

/**
 * __useGetStudentInfoQuery__
 *
 * To run a query within a React component, call `useGetStudentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudentInfoQuery(baseOptions: Apollo.QueryHookOptions<GetStudentInfoQuery, GetStudentInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentInfoQuery, GetStudentInfoQueryVariables>(GetStudentInfoDocument, options);
      }
export function useGetStudentInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentInfoQuery, GetStudentInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentInfoQuery, GetStudentInfoQueryVariables>(GetStudentInfoDocument, options);
        }
export type GetStudentInfoQueryHookResult = ReturnType<typeof useGetStudentInfoQuery>;
export type GetStudentInfoLazyQueryHookResult = ReturnType<typeof useGetStudentInfoLazyQuery>;
export type GetStudentInfoQueryResult = Apollo.QueryResult<GetStudentInfoQuery, GetStudentInfoQueryVariables>;
export const GetAdminStudentLessonsDocument = gql`
    query GetAdminStudentLessons($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    lessons(
      limit: $limit
      offset: $offset
      order_by: {start_time: asc}
      where: {start_time: {_is_null: false}}
    ) {
      start_time
      end_time
      status
      teacher_id
      user {
        id
        gdpr_name
      }
      location {
        name
      }
      instrument {
        prettyName
      }
      plan {
        id
        prettyName
      }
    }
    lessons_aggregate(where: {start_time: {_is_null: false}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetAdminStudentLessonsQuery__
 *
 * To run a query within a React component, call `useGetAdminStudentLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStudentLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStudentLessonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAdminStudentLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminStudentLessonsQuery, GetAdminStudentLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminStudentLessonsQuery, GetAdminStudentLessonsQueryVariables>(GetAdminStudentLessonsDocument, options);
      }
export function useGetAdminStudentLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminStudentLessonsQuery, GetAdminStudentLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminStudentLessonsQuery, GetAdminStudentLessonsQueryVariables>(GetAdminStudentLessonsDocument, options);
        }
export type GetAdminStudentLessonsQueryHookResult = ReturnType<typeof useGetAdminStudentLessonsQuery>;
export type GetAdminStudentLessonsLazyQueryHookResult = ReturnType<typeof useGetAdminStudentLessonsLazyQuery>;
export type GetAdminStudentLessonsQueryResult = Apollo.QueryResult<GetAdminStudentLessonsQuery, GetAdminStudentLessonsQueryVariables>;
export const GetAdminStudentLocationsDocument = gql`
    query GetAdminStudentLocations($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    student_locations(limit: $limit, offset: $offset) {
      location {
        name
        id
      }
    }
    student_locations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetAdminStudentLocationsQuery__
 *
 * To run a query within a React component, call `useGetAdminStudentLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStudentLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStudentLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAdminStudentLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminStudentLocationsQuery, GetAdminStudentLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminStudentLocationsQuery, GetAdminStudentLocationsQueryVariables>(GetAdminStudentLocationsDocument, options);
      }
export function useGetAdminStudentLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminStudentLocationsQuery, GetAdminStudentLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminStudentLocationsQuery, GetAdminStudentLocationsQueryVariables>(GetAdminStudentLocationsDocument, options);
        }
export type GetAdminStudentLocationsQueryHookResult = ReturnType<typeof useGetAdminStudentLocationsQuery>;
export type GetAdminStudentLocationsLazyQueryHookResult = ReturnType<typeof useGetAdminStudentLocationsLazyQuery>;
export type GetAdminStudentLocationsQueryResult = Apollo.QueryResult<GetAdminStudentLocationsQuery, GetAdminStudentLocationsQueryVariables>;
export const StudentPlanAssignDocument = gql`
    mutation StudentPlanAssign($studentId: uuid!, $planId: uuid!, $teacherId: String!, $instrumentId: uuid!, $locationId: uuid!) {
  insert_student_plans_one(
    object: {student_id: $studentId, plan_id: $planId, teacher_id: $teacherId, instrument_id: $instrumentId, location_id: $locationId}
  ) {
    id
    student_id
    plan_id
    teacher_id
    instrument_id
    location_id
  }
}
    `;
export type StudentPlanAssignMutationFn = Apollo.MutationFunction<StudentPlanAssignMutation, StudentPlanAssignMutationVariables>;

/**
 * __useStudentPlanAssignMutation__
 *
 * To run a mutation, you first call `useStudentPlanAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentPlanAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentPlanAssignMutation, { data, loading, error }] = useStudentPlanAssignMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      planId: // value for 'planId'
 *      teacherId: // value for 'teacherId'
 *      instrumentId: // value for 'instrumentId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useStudentPlanAssignMutation(baseOptions?: Apollo.MutationHookOptions<StudentPlanAssignMutation, StudentPlanAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentPlanAssignMutation, StudentPlanAssignMutationVariables>(StudentPlanAssignDocument, options);
      }
export type StudentPlanAssignMutationHookResult = ReturnType<typeof useStudentPlanAssignMutation>;
export type StudentPlanAssignMutationResult = Apollo.MutationResult<StudentPlanAssignMutation>;
export type StudentPlanAssignMutationOptions = Apollo.BaseMutationOptions<StudentPlanAssignMutation, StudentPlanAssignMutationVariables>;
export const GetAdminStudentPlansDocument = gql`
    query GetAdminStudentPlans($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    student_plans(limit: $limit, offset: $offset) {
      id
      user {
        id
        gdpr_name
      }
      plan {
        id
        name
        prettyName
        lessonPrice
        lessonCount
        lessonCountThreshold
        active
      }
      current_count
      next_student_plan_id
      auto_renew
      status
      instrument {
        id
        prettyName
        name
      }
    }
    student_plans_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetAdminStudentPlansQuery__
 *
 * To run a query within a React component, call `useGetAdminStudentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminStudentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminStudentPlansQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAdminStudentPlansQuery(baseOptions: Apollo.QueryHookOptions<GetAdminStudentPlansQuery, GetAdminStudentPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminStudentPlansQuery, GetAdminStudentPlansQueryVariables>(GetAdminStudentPlansDocument, options);
      }
export function useGetAdminStudentPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminStudentPlansQuery, GetAdminStudentPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminStudentPlansQuery, GetAdminStudentPlansQueryVariables>(GetAdminStudentPlansDocument, options);
        }
export type GetAdminStudentPlansQueryHookResult = ReturnType<typeof useGetAdminStudentPlansQuery>;
export type GetAdminStudentPlansLazyQueryHookResult = ReturnType<typeof useGetAdminStudentPlansLazyQuery>;
export type GetAdminStudentPlansQueryResult = Apollo.QueryResult<GetAdminStudentPlansQuery, GetAdminStudentPlansQueryVariables>;
export const GetStudentDetailTeachersDocument = gql`
    query GetStudentDetailTeachers($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    student_teachers(limit: $limit, offset: $offset) {
      user {
        id
        gdpr_name
        teacher_instruments {
          instrument {
            prettyName
          }
        }
        teacher_locations {
          location {
            name
          }
        }
      }
    }
    student_teachers_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetStudentDetailTeachersQuery__
 *
 * To run a query within a React component, call `useGetStudentDetailTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentDetailTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentDetailTeachersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetStudentDetailTeachersQuery(baseOptions: Apollo.QueryHookOptions<GetStudentDetailTeachersQuery, GetStudentDetailTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentDetailTeachersQuery, GetStudentDetailTeachersQueryVariables>(GetStudentDetailTeachersDocument, options);
      }
export function useGetStudentDetailTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentDetailTeachersQuery, GetStudentDetailTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentDetailTeachersQuery, GetStudentDetailTeachersQueryVariables>(GetStudentDetailTeachersDocument, options);
        }
export type GetStudentDetailTeachersQueryHookResult = ReturnType<typeof useGetStudentDetailTeachersQuery>;
export type GetStudentDetailTeachersLazyQueryHookResult = ReturnType<typeof useGetStudentDetailTeachersLazyQuery>;
export type GetStudentDetailTeachersQueryResult = Apollo.QueryResult<GetStudentDetailTeachersQuery, GetStudentDetailTeachersQueryVariables>;
export const UpdateStudentDocument = gql`
    mutation UpdateStudent($id: uuid!, $first_name: String!, $last_name: String!, $notes: String!, $instrumentIds: [student_instruments_insert_input!]!, $locationIds: [student_locations_insert_input!]!, $teacherIds: [student_teachers_insert_input!]!, $guardianIds: [student_users_insert_input!]!) {
  update_students_by_pk(
    pk_columns: {id: $id}
    _set: {first_name: $first_name, last_name: $last_name, notes: $notes}
  ) {
    id
    first_name
    last_name
    notes
  }
  delete_student_instruments(where: {student_id: {_eq: $id}}) {
    affected_rows
  }
  insert_student_instruments(objects: $instrumentIds) {
    affected_rows
  }
  delete_student_locations(where: {student_id: {_eq: $id}}) {
    affected_rows
  }
  insert_student_locations(objects: $locationIds) {
    affected_rows
  }
  delete_student_teachers(where: {student_id: {_eq: $id}}) {
    affected_rows
  }
  insert_student_teachers(objects: $teacherIds) {
    affected_rows
  }
  delete_student_users(where: {student_id: {_eq: $id}}) {
    affected_rows
  }
  insert_student_users(objects: $guardianIds) {
    affected_rows
  }
}
    `;
export type UpdateStudentMutationFn = Apollo.MutationFunction<UpdateStudentMutation, UpdateStudentMutationVariables>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      notes: // value for 'notes'
 *      instrumentIds: // value for 'instrumentIds'
 *      locationIds: // value for 'locationIds'
 *      teacherIds: // value for 'teacherIds'
 *      guardianIds: // value for 'guardianIds'
 *   },
 * });
 */
export function useUpdateStudentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentMutation, UpdateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(UpdateStudentDocument, options);
      }
export type UpdateStudentMutationHookResult = ReturnType<typeof useUpdateStudentMutation>;
export type UpdateStudentMutationResult = Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<UpdateStudentMutation, UpdateStudentMutationVariables>;
export const StudentsDocument = gql`
    query Students($limit: Int!, $offset: Int!, $order_by: students_order_by!) {
  students(order_by: [$order_by], limit: $limit, offset: $offset) {
    id
    first_name
    last_name
    gdpr_name
    student_users {
      user {
        full_name
        email
        phone
        id
      }
    }
    student_locations {
      location {
        name
      }
    }
  }
  students_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useStudentsQuery(baseOptions: Apollo.QueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
      }
export function useStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
        }
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>;
export type StudentsLazyQueryHookResult = ReturnType<typeof useStudentsLazyQuery>;
export type StudentsQueryResult = Apollo.QueryResult<StudentsQuery, StudentsQueryVariables>;
export const TeacherCreateDocument = gql`
    mutation TeacherCreate($id: String!, $firstName: String!, $lastName: String!, $email: String!, $phone: String) {
  insert_users_one(
    object: {id: $id, first_name: $firstName, last_name: $lastName, email: $email, phone: $phone, isTeacher: true}
  ) {
    id
  }
}
    `;
export type TeacherCreateMutationFn = Apollo.MutationFunction<TeacherCreateMutation, TeacherCreateMutationVariables>;

/**
 * __useTeacherCreateMutation__
 *
 * To run a mutation, you first call `useTeacherCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherCreateMutation, { data, loading, error }] = useTeacherCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useTeacherCreateMutation(baseOptions?: Apollo.MutationHookOptions<TeacherCreateMutation, TeacherCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeacherCreateMutation, TeacherCreateMutationVariables>(TeacherCreateDocument, options);
      }
export type TeacherCreateMutationHookResult = ReturnType<typeof useTeacherCreateMutation>;
export type TeacherCreateMutationResult = Apollo.MutationResult<TeacherCreateMutation>;
export type TeacherCreateMutationOptions = Apollo.BaseMutationOptions<TeacherCreateMutation, TeacherCreateMutationVariables>;
export const AssignStudentsToTeacherDocument = gql`
    mutation AssignStudentsToTeacher($studentIds: [student_teachers_insert_input!]!) {
  insert_student_teachers(objects: $studentIds) {
    affected_rows
  }
}
    `;
export type AssignStudentsToTeacherMutationFn = Apollo.MutationFunction<AssignStudentsToTeacherMutation, AssignStudentsToTeacherMutationVariables>;

/**
 * __useAssignStudentsToTeacherMutation__
 *
 * To run a mutation, you first call `useAssignStudentsToTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStudentsToTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStudentsToTeacherMutation, { data, loading, error }] = useAssignStudentsToTeacherMutation({
 *   variables: {
 *      studentIds: // value for 'studentIds'
 *   },
 * });
 */
export function useAssignStudentsToTeacherMutation(baseOptions?: Apollo.MutationHookOptions<AssignStudentsToTeacherMutation, AssignStudentsToTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStudentsToTeacherMutation, AssignStudentsToTeacherMutationVariables>(AssignStudentsToTeacherDocument, options);
      }
export type AssignStudentsToTeacherMutationHookResult = ReturnType<typeof useAssignStudentsToTeacherMutation>;
export type AssignStudentsToTeacherMutationResult = Apollo.MutationResult<AssignStudentsToTeacherMutation>;
export type AssignStudentsToTeacherMutationOptions = Apollo.BaseMutationOptions<AssignStudentsToTeacherMutation, AssignStudentsToTeacherMutationVariables>;
export const GetTeacherLessonsDocument = gql`
    query GetTeacherLessons($uid: String!, $limit: Int!, $offset: Int!) {
  users_by_pk(id: $uid) {
    id
    lessons(limit: $limit, offset: $offset) {
      start_time
      end_time
      status
      student {
        id
        gdpr_name
      }
      location {
        name
      }
    }
    lessons_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetTeacherLessonsQuery__
 *
 * To run a query within a React component, call `useGetTeacherLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherLessonsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTeacherLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetTeacherLessonsQuery, GetTeacherLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherLessonsQuery, GetTeacherLessonsQueryVariables>(GetTeacherLessonsDocument, options);
      }
export function useGetTeacherLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherLessonsQuery, GetTeacherLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherLessonsQuery, GetTeacherLessonsQueryVariables>(GetTeacherLessonsDocument, options);
        }
export type GetTeacherLessonsQueryHookResult = ReturnType<typeof useGetTeacherLessonsQuery>;
export type GetTeacherLessonsLazyQueryHookResult = ReturnType<typeof useGetTeacherLessonsLazyQuery>;
export type GetTeacherLessonsQueryResult = Apollo.QueryResult<GetTeacherLessonsQuery, GetTeacherLessonsQueryVariables>;
export const GetTeacherLocationsDocument = gql`
    query GetTeacherLocations($uid: String!, $limit: Int!, $offset: Int!) {
  users_by_pk(id: $uid) {
    teacher_locations(limit: $limit, offset: $offset) {
      location {
        name
        id
      }
    }
    teacher_locations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetTeacherLocationsQuery__
 *
 * To run a query within a React component, call `useGetTeacherLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherLocationsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTeacherLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetTeacherLocationsQuery, GetTeacherLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherLocationsQuery, GetTeacherLocationsQueryVariables>(GetTeacherLocationsDocument, options);
      }
export function useGetTeacherLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherLocationsQuery, GetTeacherLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherLocationsQuery, GetTeacherLocationsQueryVariables>(GetTeacherLocationsDocument, options);
        }
export type GetTeacherLocationsQueryHookResult = ReturnType<typeof useGetTeacherLocationsQuery>;
export type GetTeacherLocationsLazyQueryHookResult = ReturnType<typeof useGetTeacherLocationsLazyQuery>;
export type GetTeacherLocationsQueryResult = Apollo.QueryResult<GetTeacherLocationsQuery, GetTeacherLocationsQueryVariables>;
export const GetTeacherStudentsDocument = gql`
    query GetTeacherStudents($uid: String!, $limit: Int!, $offset: Int!) {
  users_by_pk(id: $uid) {
    student_teachers(limit: $limit, offset: $offset) {
      student {
        id
        gdpr_name
        student_instruments {
          instrument {
            prettyName
          }
        }
        student_locations {
          location {
            name
          }
        }
      }
    }
    student_teachers_aggregate {
      aggregate {
        count
      }
    }
  }
  students {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useGetTeacherStudentsQuery__
 *
 * To run a query within a React component, call `useGetTeacherStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherStudentsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTeacherStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetTeacherStudentsQuery, GetTeacherStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherStudentsQuery, GetTeacherStudentsQueryVariables>(GetTeacherStudentsDocument, options);
      }
export function useGetTeacherStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherStudentsQuery, GetTeacherStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherStudentsQuery, GetTeacherStudentsQueryVariables>(GetTeacherStudentsDocument, options);
        }
export type GetTeacherStudentsQueryHookResult = ReturnType<typeof useGetTeacherStudentsQuery>;
export type GetTeacherStudentsLazyQueryHookResult = ReturnType<typeof useGetTeacherStudentsLazyQuery>;
export type GetTeacherStudentsQueryResult = Apollo.QueryResult<GetTeacherStudentsQuery, GetTeacherStudentsQueryVariables>;
export const GetTeacherDocument = gql`
    query GetTeacher($uid: String!) {
  users_by_pk(id: $uid) {
    id
    email
    first_name
    last_name
    full_name
    title
    phone
    teacher_instruments {
      instrument {
        id
        name
        prettyName
      }
    }
    teacher_locations {
      location {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetTeacherQuery__
 *
 * To run a query within a React component, call `useGetTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetTeacherQuery(baseOptions: Apollo.QueryHookOptions<GetTeacherQuery, GetTeacherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherQuery, GetTeacherQueryVariables>(GetTeacherDocument, options);
      }
export function useGetTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherQuery, GetTeacherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherQuery, GetTeacherQueryVariables>(GetTeacherDocument, options);
        }
export type GetTeacherQueryHookResult = ReturnType<typeof useGetTeacherQuery>;
export type GetTeacherLazyQueryHookResult = ReturnType<typeof useGetTeacherLazyQuery>;
export type GetTeacherQueryResult = Apollo.QueryResult<GetTeacherQuery, GetTeacherQueryVariables>;
export const UpdateTeacherDocument = gql`
    mutation UpdateTeacher($uid: String!, $first_name: String!, $last_name: String!, $phone: String!, $instrumentIds: [teacher_instruments_insert_input!]!, $locationIds: [teacher_locations_insert_input!]!) {
  update_users_by_pk(
    pk_columns: {id: $uid}
    _set: {first_name: $first_name, last_name: $last_name, phone: $phone}
  ) {
    id
    first_name
    last_name
    phone
  }
  delete_teacher_instruments(where: {teacher_id: {_eq: $uid}}) {
    affected_rows
  }
  insert_teacher_instruments(objects: $instrumentIds) {
    affected_rows
  }
  delete_teacher_locations(where: {teacher_id: {_eq: $uid}}) {
    affected_rows
  }
  insert_teacher_locations(objects: $locationIds) {
    affected_rows
  }
}
    `;
export type UpdateTeacherMutationFn = Apollo.MutationFunction<UpdateTeacherMutation, UpdateTeacherMutationVariables>;

/**
 * __useUpdateTeacherMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherMutation, { data, loading, error }] = useUpdateTeacherMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      phone: // value for 'phone'
 *      instrumentIds: // value for 'instrumentIds'
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useUpdateTeacherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeacherMutation, UpdateTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeacherMutation, UpdateTeacherMutationVariables>(UpdateTeacherDocument, options);
      }
export type UpdateTeacherMutationHookResult = ReturnType<typeof useUpdateTeacherMutation>;
export type UpdateTeacherMutationResult = Apollo.MutationResult<UpdateTeacherMutation>;
export type UpdateTeacherMutationOptions = Apollo.BaseMutationOptions<UpdateTeacherMutation, UpdateTeacherMutationVariables>;
export const TeachersDocument = gql`
    query Teachers($limit: Int!, $offset: Int!, $order_by: users_order_by!) {
  users(
    where: {isTeacher: {_eq: true}}
    order_by: [$order_by]
    limit: $limit
    offset: $offset
  ) {
    id
    first_name
    last_name
    email
    phone
  }
  users_aggregate(where: {isTeacher: {_eq: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useTeachersQuery__
 *
 * To run a query within a React component, call `useTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useTeachersQuery(baseOptions: Apollo.QueryHookOptions<TeachersQuery, TeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeachersQuery, TeachersQueryVariables>(TeachersDocument, options);
      }
export function useTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeachersQuery, TeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeachersQuery, TeachersQueryVariables>(TeachersDocument, options);
        }
export type TeachersQueryHookResult = ReturnType<typeof useTeachersQuery>;
export type TeachersLazyQueryHookResult = ReturnType<typeof useTeachersLazyQuery>;
export type TeachersQueryResult = Apollo.QueryResult<TeachersQuery, TeachersQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($first_name: String!, $last_name: String!) {
  insert_users_one(object: {first_name: $first_name, last_name: $last_name}) {
    id
    first_name
    last_name
    full_name
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UsersDocument = gql`
    query Users($limit: Int!, $offset: Int!, $order_by: users_order_by!) {
  users(order_by: [$order_by], limit: $limit, offset: $offset) {
    id
    first_name
    last_name
    title
    full_name
    email
    phone
  }
  users_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($uid: String!) {
  users_by_pk(id: $uid) {
    id
    email
    first_name
    last_name
    full_name
    title
    phone
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($uid: String!, $first_name: String!, $last_name: String!, $phone: String!) {
  update_users_by_pk(
    pk_columns: {id: $uid}
    _set: {first_name: $first_name, last_name: $last_name, phone: $phone}
  ) {
    id
    first_name
    last_name
    phone
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetAllScopedLessonsDocument = gql`
    query GetAllScopedLessons($id: String!) {
  lessons(order_by: {start_time: asc}, where: {teacher_id: {_eq: $id}}) {
    start_time
    end_time
    status
    instrument {
      prettyName
    }
    location {
      name
    }
    student {
      gdpr_name
    }
  }
  lessons_aggregate(where: {teacher_id: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllScopedLessonsQuery__
 *
 * To run a query within a React component, call `useGetAllScopedLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllScopedLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllScopedLessonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllScopedLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetAllScopedLessonsQuery, GetAllScopedLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllScopedLessonsQuery, GetAllScopedLessonsQueryVariables>(GetAllScopedLessonsDocument, options);
      }
export function useGetAllScopedLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllScopedLessonsQuery, GetAllScopedLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllScopedLessonsQuery, GetAllScopedLessonsQueryVariables>(GetAllScopedLessonsDocument, options);
        }
export type GetAllScopedLessonsQueryHookResult = ReturnType<typeof useGetAllScopedLessonsQuery>;
export type GetAllScopedLessonsLazyQueryHookResult = ReturnType<typeof useGetAllScopedLessonsLazyQuery>;
export type GetAllScopedLessonsQueryResult = Apollo.QueryResult<GetAllScopedLessonsQuery, GetAllScopedLessonsQueryVariables>;
export const GetUpcomingScopedLessonsDocument = gql`
    query GetUpcomingScopedLessons($id: String!) {
  lessons(order_by: {start_time: asc}, where: {teacher_id: {_eq: $id}}) {
    id
    start_time
    status
    end_time
    instrument {
      prettyName
    }
    user {
      id
    }
    location {
      name
    }
    student {
      gdpr_name
    }
  }
}
    `;

/**
 * __useGetUpcomingScopedLessonsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingScopedLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingScopedLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingScopedLessonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUpcomingScopedLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetUpcomingScopedLessonsQuery, GetUpcomingScopedLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpcomingScopedLessonsQuery, GetUpcomingScopedLessonsQueryVariables>(GetUpcomingScopedLessonsDocument, options);
      }
export function useGetUpcomingScopedLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpcomingScopedLessonsQuery, GetUpcomingScopedLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpcomingScopedLessonsQuery, GetUpcomingScopedLessonsQueryVariables>(GetUpcomingScopedLessonsDocument, options);
        }
export type GetUpcomingScopedLessonsQueryHookResult = ReturnType<typeof useGetUpcomingScopedLessonsQuery>;
export type GetUpcomingScopedLessonsLazyQueryHookResult = ReturnType<typeof useGetUpcomingScopedLessonsLazyQuery>;
export type GetUpcomingScopedLessonsQueryResult = Apollo.QueryResult<GetUpcomingScopedLessonsQuery, GetUpcomingScopedLessonsQueryVariables>;
export const GetStudentDocument = gql`
    query GetStudent($id: uuid!) {
  students_by_pk(id: $id) {
    id
    first_name
    last_name
    class_code
    class_name
    notes
    student_users {
      user {
        id
        gdpr_name
        email
        phone
      }
    }
    student_instruments {
      instrument {
        prettyName
        id
      }
    }
  }
  instruments {
    prettyName
    id
  }
}
    `;

/**
 * __useGetStudentQuery__
 *
 * To run a query within a React component, call `useGetStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudentQuery(baseOptions: Apollo.QueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, options);
      }
export function useGetStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, options);
        }
export type GetStudentQueryHookResult = ReturnType<typeof useGetStudentQuery>;
export type GetStudentLazyQueryHookResult = ReturnType<typeof useGetStudentLazyQuery>;
export type GetStudentQueryResult = Apollo.QueryResult<GetStudentQuery, GetStudentQueryVariables>;
export const StudentLessonCreateScopedTeacherDocument = gql`
    mutation StudentLessonCreateScopedTeacher($teacherId: String!, $studentId: uuid!, $instrumentId: uuid!, $locationId: uuid!, $planId: uuid!, $startTime: timestamptz!, $endTime: timestamptz!) {
  insert_lessons_one(
    object: {instrument_id: $instrumentId, location_id: $locationId, plan_id: $planId, student_id: $studentId, teacher_id: $teacherId, start_time: $startTime, end_time: $endTime}
  ) {
    id
  }
}
    `;
export type StudentLessonCreateScopedTeacherMutationFn = Apollo.MutationFunction<StudentLessonCreateScopedTeacherMutation, StudentLessonCreateScopedTeacherMutationVariables>;

/**
 * __useStudentLessonCreateScopedTeacherMutation__
 *
 * To run a mutation, you first call `useStudentLessonCreateScopedTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentLessonCreateScopedTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentLessonCreateScopedTeacherMutation, { data, loading, error }] = useStudentLessonCreateScopedTeacherMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      studentId: // value for 'studentId'
 *      instrumentId: // value for 'instrumentId'
 *      locationId: // value for 'locationId'
 *      planId: // value for 'planId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useStudentLessonCreateScopedTeacherMutation(baseOptions?: Apollo.MutationHookOptions<StudentLessonCreateScopedTeacherMutation, StudentLessonCreateScopedTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentLessonCreateScopedTeacherMutation, StudentLessonCreateScopedTeacherMutationVariables>(StudentLessonCreateScopedTeacherDocument, options);
      }
export type StudentLessonCreateScopedTeacherMutationHookResult = ReturnType<typeof useStudentLessonCreateScopedTeacherMutation>;
export type StudentLessonCreateScopedTeacherMutationResult = Apollo.MutationResult<StudentLessonCreateScopedTeacherMutation>;
export type StudentLessonCreateScopedTeacherMutationOptions = Apollo.BaseMutationOptions<StudentLessonCreateScopedTeacherMutation, StudentLessonCreateScopedTeacherMutationVariables>;
export const StudentLessonCreateValuesDocument = gql`
    query StudentLessonCreateValues($id: uuid!) {
  students_by_pk(id: $id) {
    id
    student_instruments {
      instrument {
        id
        prettyName
      }
    }
    student_plans {
      plan {
        id
      }
    }
    student_locations {
      location {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useStudentLessonCreateValuesQuery__
 *
 * To run a query within a React component, call `useStudentLessonCreateValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentLessonCreateValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentLessonCreateValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentLessonCreateValuesQuery(baseOptions: Apollo.QueryHookOptions<StudentLessonCreateValuesQuery, StudentLessonCreateValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentLessonCreateValuesQuery, StudentLessonCreateValuesQueryVariables>(StudentLessonCreateValuesDocument, options);
      }
export function useStudentLessonCreateValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentLessonCreateValuesQuery, StudentLessonCreateValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentLessonCreateValuesQuery, StudentLessonCreateValuesQueryVariables>(StudentLessonCreateValuesDocument, options);
        }
export type StudentLessonCreateValuesQueryHookResult = ReturnType<typeof useStudentLessonCreateValuesQuery>;
export type StudentLessonCreateValuesLazyQueryHookResult = ReturnType<typeof useStudentLessonCreateValuesLazyQuery>;
export type StudentLessonCreateValuesQueryResult = Apollo.QueryResult<StudentLessonCreateValuesQuery, StudentLessonCreateValuesQueryVariables>;
export const GetStudentLessonsDocument = gql`
    query GetStudentLessons($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    lessons(
      limit: $limit
      offset: $offset
      order_by: {start_time: asc}
      where: {start_time: {_is_null: false}}
    ) {
      start_time
      status
      end_time
      teacher_id
      user {
        id
        gdpr_name
      }
      location {
        name
      }
      instrument {
        prettyName
      }
      plan {
        id
        prettyName
      }
    }
    lessons_aggregate(where: {start_time: {_is_null: false}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetStudentLessonsQuery__
 *
 * To run a query within a React component, call `useGetStudentLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentLessonsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetStudentLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentLessonsQuery, GetStudentLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentLessonsQuery, GetStudentLessonsQueryVariables>(GetStudentLessonsDocument, options);
      }
export function useGetStudentLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentLessonsQuery, GetStudentLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentLessonsQuery, GetStudentLessonsQueryVariables>(GetStudentLessonsDocument, options);
        }
export type GetStudentLessonsQueryHookResult = ReturnType<typeof useGetStudentLessonsQuery>;
export type GetStudentLessonsLazyQueryHookResult = ReturnType<typeof useGetStudentLessonsLazyQuery>;
export type GetStudentLessonsQueryResult = Apollo.QueryResult<GetStudentLessonsQuery, GetStudentLessonsQueryVariables>;
export const GetStudentLocationsDocument = gql`
    query GetStudentLocations($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    student_locations(limit: $limit, offset: $offset) {
      location {
        name
        id
      }
    }
    student_locations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetStudentLocationsQuery__
 *
 * To run a query within a React component, call `useGetStudentLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetStudentLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentLocationsQuery, GetStudentLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentLocationsQuery, GetStudentLocationsQueryVariables>(GetStudentLocationsDocument, options);
      }
export function useGetStudentLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentLocationsQuery, GetStudentLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentLocationsQuery, GetStudentLocationsQueryVariables>(GetStudentLocationsDocument, options);
        }
export type GetStudentLocationsQueryHookResult = ReturnType<typeof useGetStudentLocationsQuery>;
export type GetStudentLocationsLazyQueryHookResult = ReturnType<typeof useGetStudentLocationsLazyQuery>;
export type GetStudentLocationsQueryResult = Apollo.QueryResult<GetStudentLocationsQuery, GetStudentLocationsQueryVariables>;
export const GetStudentPlansDocument = gql`
    query GetStudentPlans($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    student_plans(limit: $limit, offset: $offset) {
      id
      user {
        id
        gdpr_name
      }
      plan {
        id
        lessonPrice
        lessonCount
        lessonCountThreshold
        active
      }
    }
    student_plans_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetStudentPlansQuery__
 *
 * To run a query within a React component, call `useGetStudentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentPlansQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetStudentPlansQuery(baseOptions: Apollo.QueryHookOptions<GetStudentPlansQuery, GetStudentPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentPlansQuery, GetStudentPlansQueryVariables>(GetStudentPlansDocument, options);
      }
export function useGetStudentPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentPlansQuery, GetStudentPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentPlansQuery, GetStudentPlansQueryVariables>(GetStudentPlansDocument, options);
        }
export type GetStudentPlansQueryHookResult = ReturnType<typeof useGetStudentPlansQuery>;
export type GetStudentPlansLazyQueryHookResult = ReturnType<typeof useGetStudentPlansLazyQuery>;
export type GetStudentPlansQueryResult = Apollo.QueryResult<GetStudentPlansQuery, GetStudentPlansQueryVariables>;
export const GetStudentTeachersDocument = gql`
    query GetStudentTeachers($id: uuid!, $limit: Int!, $offset: Int!) {
  students_by_pk(id: $id) {
    id
    student_teachers(limit: $limit, offset: $offset) {
      user {
        id
        gdpr_name
        teacher_instruments {
          instrument {
            prettyName
          }
        }
        teacher_locations {
          location {
            name
          }
        }
      }
    }
    student_teachers_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetStudentTeachersQuery__
 *
 * To run a query within a React component, call `useGetStudentTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentTeachersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetStudentTeachersQuery(baseOptions: Apollo.QueryHookOptions<GetStudentTeachersQuery, GetStudentTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentTeachersQuery, GetStudentTeachersQueryVariables>(GetStudentTeachersDocument, options);
      }
export function useGetStudentTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentTeachersQuery, GetStudentTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentTeachersQuery, GetStudentTeachersQueryVariables>(GetStudentTeachersDocument, options);
        }
export type GetStudentTeachersQueryHookResult = ReturnType<typeof useGetStudentTeachersQuery>;
export type GetStudentTeachersLazyQueryHookResult = ReturnType<typeof useGetStudentTeachersLazyQuery>;
export type GetStudentTeachersQueryResult = Apollo.QueryResult<GetStudentTeachersQuery, GetStudentTeachersQueryVariables>;
export const StudentUpdateDocument = gql`
    mutation StudentUpdate($id: uuid!, $first_name: String!, $last_name: String!, $notes: String!) {
  update_students_by_pk(
    pk_columns: {id: $id}
    _set: {first_name: $first_name, last_name: $last_name, notes: $notes}
  ) {
    id
    first_name
    last_name
    notes
  }
}
    `;
export type StudentUpdateMutationFn = Apollo.MutationFunction<StudentUpdateMutation, StudentUpdateMutationVariables>;

/**
 * __useStudentUpdateMutation__
 *
 * To run a mutation, you first call `useStudentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentUpdateMutation, { data, loading, error }] = useStudentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useStudentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StudentUpdateMutation, StudentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentUpdateMutation, StudentUpdateMutationVariables>(StudentUpdateDocument, options);
      }
export type StudentUpdateMutationHookResult = ReturnType<typeof useStudentUpdateMutation>;
export type StudentUpdateMutationResult = Apollo.MutationResult<StudentUpdateMutation>;
export type StudentUpdateMutationOptions = Apollo.BaseMutationOptions<StudentUpdateMutation, StudentUpdateMutationVariables>;
export const GetScopedStudentsDocument = gql`
    query GetScopedStudents($scopeId: String!, $limit: Int!, $offset: Int!, $order_by: students_order_by!) {
  students(
    where: {student_teachers: {teacher_id: {_eq: $scopeId}}}
    order_by: [$order_by]
    limit: $limit
    offset: $offset
  ) {
    id
    first_name
    last_name
    gdpr_name
    student_users {
      user {
        full_name
        email
        phone
        id
      }
    }
    student_locations {
      location {
        name
      }
    }
  }
  students_aggregate(where: {student_teachers: {teacher_id: {_eq: $scopeId}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetScopedStudentsQuery__
 *
 * To run a query within a React component, call `useGetScopedStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScopedStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScopedStudentsQuery({
 *   variables: {
 *      scopeId: // value for 'scopeId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetScopedStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetScopedStudentsQuery, GetScopedStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScopedStudentsQuery, GetScopedStudentsQueryVariables>(GetScopedStudentsDocument, options);
      }
export function useGetScopedStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScopedStudentsQuery, GetScopedStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScopedStudentsQuery, GetScopedStudentsQueryVariables>(GetScopedStudentsDocument, options);
        }
export type GetScopedStudentsQueryHookResult = ReturnType<typeof useGetScopedStudentsQuery>;
export type GetScopedStudentsLazyQueryHookResult = ReturnType<typeof useGetScopedStudentsLazyQuery>;
export type GetScopedStudentsQueryResult = Apollo.QueryResult<GetScopedStudentsQuery, GetScopedStudentsQueryVariables>;