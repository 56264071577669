import { gql } from '@apollo/client';

export const GET_INSTRUMENT_QUERY = gql`
  query GetInstrument($id: uuid!) {
    instruments_by_pk(id: $id) {
      id
      name
      prettyName
    }
  }
`;
