import { Fragment, SetStateAction, useState, Dispatch } from 'react';
import { Sidebar, TableSortPropertyState } from '@rr/ui';
import { usePlansQuery } from 'types/graphql';

const LIMIT = 20;

// TODO Extract to shared type

interface PlanListProps {
  handleCreate?: Dispatch<SetStateAction<boolean>>;
}

export const PlanList = ({ handleCreate }: PlanListProps) => {
  const [offset, setOffset] = useState(0);

  const [orderBy, setOrderBy] = useState<TableSortPropertyState>({
    name: 'asc',
  });

  const { error, data } = usePlansQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: { ...orderBy },
    },
  });

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const plans = data?.plans || [];
  const updateOffset = (number: number) => setOffset(number);

  return (
    <Fragment>
      <Sidebar.Header title="Plans" handleCreate={handleCreate} />
      <Sidebar.Content>
        {plans &&
          plans.map((plan) => (
            <Sidebar.Item key={plan.id} to={`/plans/${plan.id}`}>
              <div>{plan.prettyName}</div>
            </Sidebar.Item>
          ))}
      </Sidebar.Content>
      <Sidebar.Footer
        updateOffset={updateOffset}
        offset={offset}
        limit={LIMIT}
        count={data?.plans_aggregate.aggregate?.count}
      />
    </Fragment>
  );
};
