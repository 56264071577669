import { gql } from '@apollo/client';

export const GET_STUDENT_PLANS_QUERY = gql`
  query GetAdminStudentPlans($id: uuid!, $limit: Int!, $offset: Int!) {
    students_by_pk(id: $id) {
      id
      student_plans(limit: $limit, offset: $offset) {
        id
        user {
          id
          gdpr_name
        }
        plan {
          id
          name
          prettyName
          lessonPrice
          lessonCount
          lessonCountThreshold
          active
        }
        current_count
        next_student_plan_id
        auto_renew
        status
        instrument {
          id
          prettyName
          name
        }
      }
      student_plans_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
