import { css } from '@emotion/react';
import { Button, useMantineTheme } from '@mantine/core';
import { useMiniTable } from '../MiniTableWrapper';

export const MiniTablePagination = () => {
  const { offset, limit, updateOffset, count } = useMiniTable();
  const theme = useMantineTheme();

  const styles = css`
    height: 52px;
    background: white;
    padding: 11px 16px;
    box-sizing: border-box;
    border: 1px solid ${theme.colors.gray[2]};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ResultCount {
      font-size: 0.85rem;
      opacity: 0.7;
      position: relative;
      top: 2px;
    }

    .ButtonContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4px;
    }
  `;

  const handlePreviousPage = () => {
    if (typeof offset !== 'undefined') {
      if (offset > 0 && limit) {
        updateOffset && updateOffset(offset - limit);
      }
    }
  };

  const handleNextPage = () => {
    if (
      typeof count != 'undefined' &&
      typeof limit !== 'undefined' &&
      typeof offset !== 'undefined'
    ) {
      if (offset + limit < count) {
        updateOffset && updateOffset(offset + limit);
      }
    }
  };

  const renderResults = () => {
    if (
      typeof count == 'undefined' ||
      typeof offset == 'undefined' ||
      typeof limit == 'undefined'
    ) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="ResultCount">
          {offset + 1} - {offset + limit < count ? offset + limit : count} of{' '}
          {count} results
        </div>
      );
    }
  };

  const renderButtons = () => {
    if (
      typeof count == 'undefined' ||
      typeof offset == 'undefined' ||
      typeof limit == 'undefined'
    ) {
      return (
        <div className="ButtonContainer">
          <Button variant="subtle" onClick={handlePreviousPage}>
            Previous
          </Button>
          <Button variant="subtle" onClick={handleNextPage}>
            Next
          </Button>
        </div>
      );
    } else {
      return (
        <div className="ButtonContainer">
          <Button
            variant="subtle"
            disabled={offset < limit}
            onClick={handlePreviousPage}
          >
            Previous
          </Button>
          <Button
            variant="subtle"
            disabled={offset + limit >= count}
            onClick={handleNextPage}
          >
            Next
          </Button>
        </div>
      );
    }
  };

  const hasPages = count && limit && count > limit;

  return (
    <div css={styles}>
      {count !== 0 ? renderResults() : null}
      {hasPages ? renderButtons() : null}
    </div>
  );
};
