import { Fragment, useState, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { Sidebar, TableSortPropertyState } from '@rr/ui';
import { useLocationsQuery } from 'types/graphql';

const LIMIT = 20;

interface LocationListProps {
  handleCreate?: Dispatch<SetStateAction<boolean>>;
}

export const LocationList = ({ handleCreate }: LocationListProps) => {
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<TableSortPropertyState>({
    name: 'asc',
  });

  const { error, data } = useLocationsQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: { ...orderBy },
    },
  });

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const locations = data?.locations || [];
  const updateOffset = (number: number) => setOffset(number);

  const itemStyles = css`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;

    .locationName:last-of-type {
      width: 100%;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.active {
      svg {
        opacity: 1;
      }
    }

    svg {
      opacity: 0.5;
    }
  `;

  return (
    <Fragment>
      <Sidebar.Header title="Locations" handleCreate={handleCreate} />
      <Sidebar.Content>
        {locations &&
          locations.map((location) => {
            return (
              <Sidebar.Item
                key={location.id}
                to={`/locations/${location.id}`}
                style={itemStyles}
              >
                <div className="locationName">{location.name}</div>
              </Sidebar.Item>
            );
          })}
      </Sidebar.Content>
      <Sidebar.Footer
        updateOffset={updateOffset}
        offset={offset}
        limit={LIMIT}
        count={data?.locations_aggregate.aggregate?.count}
      />
    </Fragment>
  );
};
