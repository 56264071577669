import { css } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';

interface SectionTitleProps {
  title: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  children,
}) => {
  const theme = useMantineTheme();
  const styles = css`
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: ${theme.colors.gray[7]};
      font-size: 1.4rem;
      font-weight: 500;
    }
  `;

  return (
    <div css={styles}>
      <div className="title t1">{title}</div>
      {children && <div className="actions">{children}</div>}
    </div>
  );
};
