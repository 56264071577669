import { css } from '@emotion/react';
import { useSearchTable } from '../SearchTableWrapper';
const SearchTableHeader: React.FC = () => {
  const table = useSearchTable();
  const styles = css`
    background: #121212;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: rgba(255, 255, 255, 0.8);
    height: 48px;
    overflow: hidden;

    .TableCell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;

      &[data-sort-active] {
        background: #232323;
      }

      .hoverSort {
        opacity: 0;
      }

      &:hover {
        .hoverSort {
          opacity: 0.5;
        }
      }
    }
  `;
  return (
    <div css={styles} className="TableHead">
      {table &&
        table.headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <div className="TableCell" {...column.getHeaderProps()}>
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export { SearchTableHeader };
