import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { Form } from '@rr/ui';
import { getRandomThemeColor } from '@rr/utils';
import { AUTOCOMPLETE_GUARDIANS_QUERY } from './AutocompleteGuardian.query.gql';
import { AutocompleteGuardiansQuery } from 'types/graphql';
import { forwardRef } from 'react';
import { Avatar, SelectItemProps } from '@mantine/core';

interface ItemProps extends SelectItemProps {
  firstName: string;
  lastName: string;
}

const randomColor = getRandomThemeColor();

const AutocompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, firstName, lastName, ...rest }: ItemProps, ref) => {
    const styles = css`
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px;
    `;

    return (
      <div css={styles} ref={ref} {...rest}>
        <Avatar color="blue" radius="xl" size="sm">
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Avatar>
        <div>
          {firstName} {lastName}
        </div>
      </div>
    );
  }
);

//@ts-expect-error stupid
export const AutocompleteGuardian = ({ label, ...props }) => {
  const { data, error, loading } = useQuery<AutocompleteGuardiansQuery>(
    AUTOCOMPLETE_GUARDIANS_QUERY
  );
  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const guardians = data?.users?.map((guardian) => ({
    value: guardian.id,
    label: `${guardian.first_name} ${guardian.last_name}`,
    firstName: guardian.first_name,
    lastName: guardian.last_name,
  })) ?? ['- No data provided -'];

  return (
    <Form.MultiSelect
      label={label}
      itemComponent={AutocompleteItem}
      data={guardians}
      disabled={loading}
      searchable
      {...props}
    />
  );
};
