import { useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Button, Badge, Code } from '@mantine/core';
import { PlusIcon } from '@primer/octicons-react';

import { Table, TableSortPropertyState } from '@rr/ui';
import { humanDate } from '@rr/utils';

import * as ROUTES from 'config/routes';
import { useGetAllLessonsQuery } from 'types/graphql';

//TODO: Fix TypeScript errors you stupid mutt.
import { CellValue } from 'react-table';
import { withTheme } from '@emotion/react';

const LIMIT = 20;

interface LessonOverviewProps {
  handleCreate: Dispatch<SetStateAction<boolean>>;
}

const LessonOverview = ({ handleCreate }: LessonOverviewProps) => {
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<TableSortPropertyState>({
    start_time: 'asc',
  });

  const { error, data } = useGetAllLessonsQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: { ...orderBy },
    },
  });

  const lessons = data?.lessons || [];

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 168,
      // @ts-expect-error dumb
      Cell: ({ value }) => (
        <Code
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {value}
        </Code>
      ),
    },
    {
      Header: 'Time',
      accessor: 'start_time',
      // @ts-expect-error dumb
      Cell: ({ value }) =>
        value ? (
          humanDate(value, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            minute: '2-digit',
            hour: '2-digit',
            hour12: true,
          })
        ) : (
          <Badge color="red" variant="filled">
            Time not booked
          </Badge>
        ),
    },
    {
      Header: 'Student',
      accessor: 'student',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) => (
        <div>
          <Link to={`/students/${value.id}`}>{value.gdpr_name}</Link>
        </div>
      ),
    },
    {
      Header: 'Teacher',
      accessor: 'user',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) => {
        return (
          <div>
            <Link to={`/teachers/${value.id}`}>{value.full_name}</Link>
          </div>
        );
      },
    },
    {
      Header: 'Location',
      accessor: 'location',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) => {
        return (
          <div>
            <Link to={`/locations/${value.id}`}>{value.name}</Link>
          </div>
        );
      },
    },
    {
      Header: 'Instrument',
      accessor: 'instrument',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) =>
        value ? (
          <div>{value.prettyName}</div>
        ) : (
          <Badge color="red" variant="filled">
            Instrument missing
          </Badge>
        ),
    },
  ];

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const updateOffset = (number: number) => setOffset(number);

  const handleSort = (id: string) => {
    if (Object.keys(orderBy).includes(id)) {
      setOrderBy({
        [id]: orderBy[id] === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setOrderBy({
        [id]: 'asc',
      });
    }
  };

  return (
    <Table.Wrapper
      data={lessons}
      columns={columns}
      offset={offset}
      limit={LIMIT}
      count={data?.lessons_aggregate.aggregate?.count}
      updateOffset={updateOffset}
      handleSort={handleSort}
      activeSortProperty={orderBy}
    >
      <Table.Actions>
        <Button
          leftIcon={<PlusIcon />}
          onClick={(e: any) => handleCreate(true)}
          variant="white"
        >
          Create lesson
        </Button>
      </Table.Actions>
      <Table.Header />
      <Table.Body />
      <Table.Pagination />
    </Table.Wrapper>
  );
};

export { LessonOverview };
