import { useField } from 'formik';
import { MultiSelect as MultiSelectMantine } from '@mantine/core';

// @ts-expect-error dumb
export const MultiSelect = ({ label, data, ...props }) => {
  // @ts-expect-error dumb
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <MultiSelectMantine
      label={label}
      value={value}
      onChange={(e) => setValue(e)}
      error={meta.touched && meta.error}
      data={data}
      {...props}
    />
  );
};
