import { css } from '@emotion/react';

const styles = css`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
`;

const TableActions: React.FC = ({ children }) => {
  return <div css={styles}>{children}</div>;
};

export { TableActions };
