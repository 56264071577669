import { gql } from '@apollo/client';

export const STUDENT_CREATE_MUTATION = gql`
  mutation StudentCreate(
    $firstName: String!
    $lastName: String!
    $notes: String
    $teacherIds: [student_teachers_insert_input!]!
    $guardianIds: [student_users_insert_input!]!
    $locationIds: [student_locations_insert_input!]!
    $instrumentIds: [student_instruments_insert_input!]!
  ) {
    insert_students_one(
      object: {
        first_name: $firstName
        last_name: $lastName
        notes: $notes
        student_locations: { data: $locationIds }
        student_teachers: { data: $teacherIds }
        student_users: { data: $guardianIds }
        student_instruments: { data: $instrumentIds }
      }
    ) {
      id
    }
  }
`;
