import * as Yup from 'yup';
import { Form } from '@rr/ui';
import { Button, Group } from '@mantine/core';
import { GET_LESSON_QUERY } from '../GetLesson.query.gql';
import { useQuery, useMutation } from '@apollo/client';
import { GetLessonQuery } from 'types/graphql';
import { UPDATE_LESSON_STATUS_MUTATION } from './UpdateLessonStatus.mutation.gql';

interface ILessonStatusProps {
  lessonId: string | null;
}

const VALIDATION_SCHEMA = Yup.object({
  status: Yup.string().required('Required'),
});

const STATUSES = {
  COMPLETE: 'COMPLETE',
  CANCELLED: 'CANCELLED',
  SCHEDULED: 'SCHEDULED',
};

type Status = keyof typeof STATUSES;

export const LessonStatus = ({ lessonId }: ILessonStatusProps) => {
  const { data, loading } = useQuery<GetLessonQuery>(GET_LESSON_QUERY, {
    variables: {
      id: lessonId,
    },
  });

  const [updateLesson] = useMutation(UPDATE_LESSON_STATUS_MUTATION, {
    refetchQueries: [
      'Lessons',
      'GetAllLessons',
      'GetLesson',
      'GetAdminStudentLessons',
      'GetStudentLessons',
    ],
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const lesson = data?.lessons_by_pk;
  const status = lesson?.status || '';

  const changeStatus = (status: Status) => {
    updateLesson({
      variables: {
        id: lessonId,
        status,
      },
    });
  };

  return (
    <div>
      {status}
      <br />
      <Group grow>
        <Button color="green" onClick={() => changeStatus('COMPLETE')}>
          Complete
        </Button>
        <Button color="red" onClick={() => changeStatus('CANCELLED')}>
          Cancel
        </Button>
        <Button color="blue" onClick={() => changeStatus('SCHEDULED')}>
          Reset
        </Button>
      </Group>
    </div>
  );
};
