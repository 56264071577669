import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { Form } from '@rr/ui';
import { getRandomThemeColor } from '@rr/utils';
import { AUTOCOMPLETE_TEACHERS_QUERY } from './AutocompleteTeacher.query.gql';
import { AutocompleteTeachersQuery } from 'types/graphql';
import { forwardRef } from 'react';
import { Avatar, SelectItemProps } from '@mantine/core';

interface AutocompleteTeacherProps {
  label: string;
  name: string;
  value?: {
    value: string;
    label: string;
  }[];
  multiple?: boolean;
}
interface ItemProps extends SelectItemProps {
  firstName: string;
  lastName: string;
}

const randomColor = getRandomThemeColor();

const AutocompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, firstName, lastName, ...rest }: ItemProps, ref) => {
    const styles = css`
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px;
    `;

    return (
      <div css={styles} ref={ref} {...rest}>
        <Avatar color="blue" radius="xl" size="sm">
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Avatar>
        <div>
          {firstName} {lastName}
        </div>
      </div>
    );
  }
);

export const AutocompleteTeacher = ({
  label,
  value,
  multiple,
  ...props
}: AutocompleteTeacherProps) => {
  const { data, error, loading } = useQuery<AutocompleteTeachersQuery>(
    AUTOCOMPLETE_TEACHERS_QUERY
  );
  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const teachers = data?.users?.map((teacher) => ({
    value: teacher.id,
    label: `${teacher.first_name} ${teacher.last_name}`,
    firstName: teacher.first_name,
    lastName: teacher.last_name,
  })) ?? ['- No data provided -'];

  return multiple ? (
    <Form.MultiSelect
      label={label}
      itemComponent={AutocompleteItem}
      data={value ?? teachers}
      disabled={loading}
      searchable
      {...props}
    />
  ) : (
    <Form.ACField
      label={label}
      itemComponent={AutocompleteItem}
      data={value ?? teachers}
      disabled={loading}
      searchable
      {...props}
    />
  );
};
