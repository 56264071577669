import { css } from '@emotion/react';
import { useContext, createContext } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { useTable, useFlexLayout, Column, TableInstance } from 'react-table';
import { Loader } from '../../';

type SearchTableContextProps = null | TableInstance;

const searchTableContext = createContext<SearchTableContextProps>(null);

interface SearchTableProps {
  columns: Column[];
  hits: [];
}

const styles = css`
  height: calc(100vh - 64px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  // background: red;
`;

export const SearchTableWithoutHits: React.FC<SearchTableProps> = ({
  children,
  columns,
  hits,
}) => {
  const table = useTable({ data: hits, columns }, useFlexLayout);
  const searchTable = useProvideSearchTable(table);
  return (
    <searchTableContext.Provider value={searchTable}>
      <div css={styles} className="TableWrapper">
        {hits && hits.length ? children : <div />}
      </div>
    </searchTableContext.Provider>
  );
};

export const SearchTableWrapper = connectHits(SearchTableWithoutHits);

export const useSearchTable = () => useContext(searchTableContext);

function useProvideSearchTable(table: any) {
  return {
    ...table,
  };
}
