import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar, SplitLayout, SplitContainer, ZeroDetail } from '@rr/ui';
import * as ROUTES from 'config/routes';

import { TeacherDetail } from './TeacherDetail';
import { TeacherList } from './TeacherList';
import { TeacherCreate } from './TeacherCreate';

const Teachers = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastTeacher = localStorage.getItem('lastTeacher');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <TeacherList handleCreate={setCreateModalOpen} />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route path={ROUTES.TEACHERS_UPDATE} component={TeacherDetail} />
          {lastTeacher && <Redirect to={`/teachers/${lastTeacher}`} />}
          <Route>
            <ZeroDetail title="No teacher selected">
              Please select one
            </ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
      <TeacherCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </SplitLayout>
  );
};

export { Teachers };
