import { gql } from '@apollo/client';

export const GET_TEACHER_STUDENTS_QUERY = gql`
  query GetTeacherStudents($uid: String!, $limit: Int!, $offset: Int!) {
    users_by_pk(id: $uid) {
      student_teachers(limit: $limit, offset: $offset) {
        student {
          id
          gdpr_name
          student_instruments {
            instrument {
              prettyName
            }
          }
          student_locations {
            location {
              name
            }
          }
        }
      }
      student_teachers_aggregate {
        aggregate {
          count
        }
      }
    }
    students {
      id
      first_name
      last_name
    }
  }
`;
