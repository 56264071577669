import { gql } from '@apollo/client';

export const GET_LOCATION_STUDENTS_QUERY = gql`
  query GetLocationStudents($id: uuid!, $limit: Int!, $offset: Int!) {
    locations_by_pk(id: $id) {
      id
      student_locations(limit: $limit, offset: $offset) {
        student {
          id
          gdpr_name
          student_instruments {
            instrument {
              prettyName
            }
          }
          student_teachers {
            user {
              id
              gdpr_name
            }
          }
        }
      }
      student_locations_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
