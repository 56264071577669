import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { Avatar, SelectItemProps } from '@mantine/core';
import { Form } from '@rr/ui';
import { IconMusic } from '@tabler/icons';

import { AUTOCOMPLETE_INSTRUMENT_QUERY } from './AutocompleteInstrument.query.gql';
import { AutocompleteInstrumentQuery } from 'types/graphql';

import { forwardRef } from 'react';

interface ItemProps extends SelectItemProps {
  prettyName: string;
  name: string;
}

const AutocompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, prettyName, ...rest }: ItemProps, ref) => {
    const styles = css`
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
    `;

    return (
      <div css={styles} ref={ref} {...rest}>
        <Avatar color="blue" radius="xl" size="sm">
          <IconMusic size={17} />
        </Avatar>
        <div>{label}</div>
      </div>
    );
  }
);

interface AutocompleteInstrumentProps {
  label: string;
  name: string;
  value?: {
    value: string;
    label: string;
  }[];
  multiple?: boolean;
}

export const AutocompleteInstrument = ({
  label,
  value,
  multiple,
  ...props
}: AutocompleteInstrumentProps) => {
  const { data, error, loading } = useQuery<AutocompleteInstrumentQuery>(
    AUTOCOMPLETE_INSTRUMENT_QUERY
  );

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const instruments = data?.instruments.map((instrument) => ({
    value: instrument.id,
    label: instrument.prettyName,
  })) ?? ['- No data provided -'];

  return multiple ? (
    <Form.MultiSelect
      label={label}
      itemComponent={AutocompleteItem}
      data={instruments}
      disabled={loading}
      searchable
      {...props}
    />
  ) : (
    <Form.ACField
      label={label}
      itemComponent={AutocompleteItem}
      data={value ?? instruments}
      disabled={loading}
      searchable
      {...props}
    />
  );
};
