import { gql } from '@apollo/client';

export const UPDATE_LESSON_MUTATION = gql`
  mutation UpdateLesson(
    $id: uuid!
    $start_time: timestamptz!
    $end_time: timestamptz!
    $instrument_id: uuid!
    $location_id: uuid!
    $student_id: uuid!
    $teacher_id: String!
  ) {
    update_lessons_by_pk(
      pk_columns: { id: $id }
      _set: {
        start_time: $start_time
        end_time: $end_time
        instrument_id: $instrument_id
        location_id: $location_id
        student_id: $student_id
        teacher_id: $teacher_id
      }
    ) {
      id
    }
  }
`;
