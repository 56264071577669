import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { NoRoute, AppContainer } from '@rr/ui';

import * as ROUTES from 'config/routes';
import {
  Login,
  AuthLink,
  AccountCreate,
  Users,
  Students,
  Teachers,
  Guardians,
  Locations,
  Instruments,
  Plans,
  Invoices,
  Lessons,
  Dashboard,
} from 'routes';

import { Layout, PrivateRoute } from 'components';
import { Auth } from '@rr/auth-context';

export function App() {
  return (
    <Auth>
      <AppContainer>
        <Router>
          <Switch>
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.VERIFY_LINK} component={AuthLink} />
            <Route path={ROUTES.ACCOUNT_CREATE} component={AccountCreate} />
            <Route>
              <Layout>
                <Switch>
                  <PrivateRoute exact path="/" component={Dashboard} />
                  <PrivateRoute path={ROUTES.USERS} component={Users} />
                  <PrivateRoute path={ROUTES.STUDENTS} component={Students} />
                  <PrivateRoute path={ROUTES.TEACHERS} component={Teachers} />
                  <PrivateRoute path={ROUTES.GUARDIANS} component={Guardians} />
                  <PrivateRoute path={ROUTES.LOCATIONS} component={Locations} />
                  <PrivateRoute
                    path={ROUTES.INSTRUMENTS}
                    component={Instruments}
                  />
                  <PrivateRoute path={ROUTES.PLANS} component={Plans} />
                  <PrivateRoute path={ROUTES.INVOICES} component={Invoices} />
                  <PrivateRoute path={ROUTES.LESSONS} component={Lessons} />
                  <Route>
                    <NoRoute />
                  </Route>
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </Router>
      </AppContainer>
    </Auth>
  );
}

export default App;
