import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Modal, Space } from '@mantine/core';
import { Order_By, usePlansQuery } from 'types/graphql';
import { STUDENT_PLAN_ASSIGN_MUTATION } from './StudentPlanAssign.mutation.gql';

import { Form } from '@rr/ui';
import {
  AutocompleteTeacher,
  AutocompleteLocation,
  AutocompleteInstrument,
} from 'components';

interface ModalProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const StudentPlanAssign = ({ opened, onClose }: ModalProps) => {
  const { studentId }: { studentId: string } = useParams();

  const { loading, error, data } = usePlansQuery({
    variables: {
      limit: 100,
      order_by: {
        id: Order_By.Asc,
      },
      offset: 0,
    },
  });

  const [assignPlan] = useMutation(STUDENT_PLAN_ASSIGN_MUTATION, {
    refetchQueries: ['GetStudentPlans', 'GetAdminStudentPlans'],
  });

  const VALIDATION_SCHEMA = Yup.object({
    planId: Yup.string().required('Required'),
    teacherId: Yup.string().required('Required'),
    locationId: Yup.string().required('Required'),
    instrumentId: Yup.string().required('Required'),
  });

  const INITIAL_VALUES = {
    planId: '',
    teacherId: '',
    locationId: '',
    instrumentId: '',
  };

  return (
    <Modal opened={opened} onClose={() => onClose(false)} title="Assign a plan">
      <Form.Context
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values) => {
          console.log(values);

          assignPlan({
            variables: {
              studentId,
              planId: values.planId,
              teacherId: values.teacherId,
              locationId: values.locationId,
              instrumentId: values.instrumentId,
            },
          }).then(() => {
            onClose(false);
          });
        }}
      >
        {({ values, isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.Select
              name="planId"
              label="Plan"
              placeholder="Select a plan"
              data={data?.plans.map((plan) => ({
                value: plan.id,
                label: plan.prettyName,
              }))}
              disabled={isSubmitting}
            />
            <Space h="md" />
            <AutocompleteTeacher name="teacherId" label="Teacher" />
            <Space h="md" />
            <AutocompleteInstrument name="instrumentId" label="Instrument" />
            <Space h="md" />
            <AutocompleteLocation name="locationId" label="Location" />
            <Space h="md" />
            <Button type="submit">
              {isSubmitting ? 'Submitting...' : 'Assign plan'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
