import { Modal, Accordion } from '@mantine/core';

import { LessonEdit, LessonStatus } from './sections';

interface LessonEditModalProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
  lessonId: string | null;
}

export const LessonEditModal = ({
  opened,
  onClose,
  lessonId,
}: LessonEditModalProps) => {
  return (
    <Modal
      padding="xs"
      opened={opened}
      onClose={() => onClose(false)}
      title="Edit a lesson"
    >
      <Accordion
        defaultValue="lesson-status"
        initialItem={1}
        iconPosition="right"
        offsetIcon={false}
      >
        <Accordion.Item
          id="edit-lesson"
          itemID="edit-lesson"
          label="Edit lesson"
        >
          <LessonEdit onClose={onClose} lessonId={lessonId} />
        </Accordion.Item>
        <Accordion.Item
          id="lesson-status"
          itemID="lesson-status"
          label="Lesson status"
        >
          <LessonStatus lessonId={lessonId} />
        </Accordion.Item>
      </Accordion>
    </Modal>
  );
};
