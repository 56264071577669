import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { SplitLayout, Sidebar, SplitContainer, ZeroDetail } from '@rr/ui';
import * as ROUTES from 'config/routes';

import { GuardianDetail } from './GuardianDetail';
import { GuardianList } from './GuardianList';
import { GuardianCreate } from './GuardianCreate';

const Guardians = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastGuardian = localStorage.getItem('lastGuardian');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <GuardianList handleCreate={setCreateModalOpen} />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route path={ROUTES.GUARDIANS_UPDATE} component={GuardianDetail} />
          {lastGuardian && <Redirect to={`/guardians/${lastGuardian}`} />}
          <Route>
            <ZeroDetail title="No guardian selected">
              Please select one
            </ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
      <GuardianCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </SplitLayout>
  );
};

export { Guardians };
