import { css } from '@emotion/react';
import { number } from 'yup';

interface GridProps {
  columns?: number;
  gap?: number;
}

export const Grid: React.FC<GridProps> = ({
  children,
  columns = 1,
  gap = 24,
}) => {
  const styles = css`
    display: grid;
    padding: 12px 0;
    grid-template-columns: repeat(${columns}, 1fr);
    gap: ${gap}px;
  `;
  return <div css={styles}>{children}</div>;
};
