import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { Avatar, Badge, Button, useMantineTheme } from '@mantine/core';
import { SectionTitle, InfoItem, Grid } from '@rr/ui';
import { getRandomThemeColor } from '@rr/utils';

import { useQuery } from '@apollo/client';
import { GET_GUARDIAN_QUERY } from '../GuardianUpdate/GetGuardian.query.gql';
import { GetGuardianQuery } from 'types/graphql';

import { GuardianPlans } from './GuardianPlans';

export const GuardianDetail = () => {
  const { userId }: { userId: string } = useParams();
  const theme = useMantineTheme();

  useEffect(() => {
    localStorage.setItem('lastGuardian', userId);
  }, [userId]);

  const { loading, error, data } = useQuery<GetGuardianQuery>(
    GET_GUARDIAN_QUERY,
    {
      variables: {
        uid: userId,
      },
    }
  );

  if (loading) return <div></div>;
  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        gap: 16px;
        align-items: center;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }

        .badges {
          display: flex;
          gap: 6px;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }

    .students,
    .lessons,
    .locations,
    .plans,
    .general {
      padding: 24px;

      a {
        display: block;
      }
    }
  `;

  return (
    <div css={styles}>
      <div className="header">
        <div className="l">
          <Avatar color={getRandomThemeColor()} radius="xl">
            {data?.users_by_pk?.first_name?.charAt(0)}
            {data?.users_by_pk?.last_name?.charAt(0)}
          </Avatar>
          <div className="name t1">{data?.users_by_pk?.full_name}</div>
        </div>
        <div className="r">
          <Button variant="outline" color="red">
            Delete
          </Button>
          <Button>Edit</Button>
        </div>
      </div>
      <div className="general">
        <SectionTitle title="Basic Information" />
        <Grid columns={3}>
          <InfoItem title="Email address">{data?.users_by_pk?.email}</InfoItem>
          <InfoItem title="Phone">{data?.users_by_pk?.phone}</InfoItem>
        </Grid>
      </div>
      <div className="plans">
        <SectionTitle title="Plans"></SectionTitle>
        <GuardianPlans />
      </div>
    </div>
  );
};
