import { Space, Button } from '@mantine/core';
import { Form } from '@rr/ui';
import {
  AutocompleteStudent,
  AutocompleteTeacher,
  AutocompleteInstrument,
  AutocompleteLocation,
} from 'components';
import * as Yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';

import {
  GetLessonQuery,
  DeleteLessonMutation,
  UpdateLessonMutation,
} from 'types/graphql';
import { GET_LESSON_QUERY } from '../GetLesson.query.gql';
import { DELETE_LESSON_MUTATION } from '../DeleteLesson.mutation.gql';
import { UPDATE_LESSON_MUTATION } from '../UpdateLesson.mutation.gql';

interface ILessonEditProps {
  onClose: (boolean: boolean) => void;
  lessonId: string | null;
}

const VALIDATION_SCHEMA = Yup.object({
  student: Yup.string().required('Required'),
  instrument: Yup.string().required('Required'),
  teacher: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  start: Yup.date().required('Required'),
  end: Yup.date().required('Required'),
  day: Yup.date().required('Required'),
});

export const LessonEdit = ({ onClose, lessonId }: ILessonEditProps) => {
  const { data, loading } = useQuery<GetLessonQuery>(GET_LESSON_QUERY, {
    variables: {
      id: lessonId,
    },
  });

  const [deleteLesson] = useMutation<DeleteLessonMutation>(
    DELETE_LESSON_MUTATION,
    {
      refetchQueries: ['Lessons', 'GetAllLessons'],
      variables: {
        id: lessonId,
      },
    }
  );

  const [updateLesson] = useMutation<UpdateLessonMutation>(
    UPDATE_LESSON_MUTATION,
    {
      refetchQueries: ['Lessons', 'GetAllLessons', 'GetLesson'],
    }
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const INITIAL_VALUES = {
    student: data?.lessons_by_pk?.student_id || '',
    instrument: data?.lessons_by_pk?.instrument_id || '',
    teacher: data?.lessons_by_pk?.teacher_id || '',
    location: data?.lessons_by_pk?.location_id || '',
    start: new Date(data?.lessons_by_pk?.start_time) || new Date(),
    end: new Date(data?.lessons_by_pk?.end_time) || new Date(),
    day: new Date(data?.lessons_by_pk?.start_time) || new Date(),
  };

  const handleDeleteLesson = () => {
    deleteLesson().then((response) => {
      onClose(false);
    });
  };

  return (
    <Form.Context
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }) => {
        // Convert times to day
        const start = new Date(values.start);
        const end = new Date(values.end);
        const day = new Date(values.day);

        start.setFullYear(day.getFullYear());
        start.setMonth(day.getMonth());
        start.setDate(day.getDate());

        end.setFullYear(day.getFullYear());
        end.setMonth(day.getMonth());
        end.setDate(day.getDate());

        if (lessonId) {
          updateLesson({
            variables: {
              id: lessonId,
              student_id: values.student,
              instrument_id: values.instrument,
              teacher_id: values.teacher,
              location_id: values.location,
              start_time: start,
              end_time: end,
            },
          })
            .then((res) => {
              setSubmitting(false);
              onClose(false);
            })
            .catch(console.error);
        }
      }}
    >
      {({ isSubmitting, isValid, submitForm }) => (
        <Form.Wrapper>
          <AutocompleteStudent label="Student" name="student" />
          <Space h="md" />
          <AutocompleteTeacher label="Teacher" name="teacher" />
          <Space h="md" />
          <AutocompleteLocation label="Location" name="location" />
          <Space h="md" />
          <AutocompleteInstrument label="Instrument" name="instrument" />
          <Space h="md" />
          <hr />
          <Form.DatePicker label="Day" name="day" />
          <Space h="md" />
          <Form.TimeInput label="Start" name="start" />
          <Space h="md" />
          <Form.TimeInput label="End" name="end" />
          <Space h="md" />
          <div style={{ display: 'flex', gap: 8 }}>
            <Button
              fullWidth
              color="red"
              variant="outline"
              onClick={handleDeleteLesson}
            >
              Delete lesson
            </Button>
            <Button
              className="Button"
              component="button"
              fullWidth
              onClick={submitForm}
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Update lesson' : 'Updating lesson...'}
            </Button>
          </div>
        </Form.Wrapper>
      )}
    </Form.Context>
  );
};
