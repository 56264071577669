import { gql } from '@apollo/client';

export const ASSIGN_STUDENTS_TO_TEACHER_MUTATION = gql`
  mutation AssignStudentsToTeacher(
    $studentIds: [student_teachers_insert_input!]!
  ) {
    insert_student_teachers(objects: $studentIds) {
      affected_rows
    }
  }
`;
