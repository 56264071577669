import { css } from '@emotion/react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '@rr/auth-context';

import { Loader } from '@rr/ui';

import * as ROUTES from 'config/routes';

const AuthLink = () => {
  const styles = css`
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .wrapper {
      width: 380px;
    }
  `;

  const { user } = useAuth();

  return user ? (
    <Redirect to={ROUTES.SCHEDULE} />
  ) : (
    <div css={styles}>
      <Loader />
    </div>
  );
};

export { AuthLink };
