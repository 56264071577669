import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Form } from '@rr/ui';
import { transformEnum } from '@rr/utils';

import { CREATE_INSTRUMENT } from './CreateInstrument.mutation.gql';
import { CreateInstrumentMutation } from 'types/graphql';

interface InstrumentCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const InstrumentCreate = ({
  opened,
  onClose,
}: InstrumentCreateProps) => {
  const history = useHistory();
  const [createInstrument] = useMutation<CreateInstrumentMutation>(
    CREATE_INSTRUMENT,
    { refetchQueries: ['GetInstruments'] }
  );

  const VALIDATION_SCHEMA = Yup.object({
    prettyName: Yup.string().required('Required'),
  });

  const INITIAL_VALUES = {
    prettyName: '',
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create an instrument"
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            createInstrument({
              variables: {
                prettyName: values.prettyName,
                name: transformEnum(values.prettyName),
              },
            }).then((response) => {
              history.push(
                `/instruments/${response.data?.insert_instruments_one?.id}`
              );
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="prettyName"
              label="Name"
              placeholder="Instrument name"
              fullWidth
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create instrument' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
