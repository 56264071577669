import { Dispatch, Fragment, SetStateAction, useState } from 'react';

import { Sidebar, TableSortPropertyState } from '@rr/ui';
import { useGuardiansQuery } from 'types/graphql';

const LIMIT = 20;

interface GuardianListProps {
  handleCreate?: Dispatch<SetStateAction<boolean>>;
}

export const GuardianList = ({ handleCreate }: GuardianListProps) => {
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<TableSortPropertyState>({
    first_name: 'asc',
  });

  const { error, data } = useGuardiansQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: { ...orderBy },
    },
  });

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const guardians = data?.users || [];
  const updateOffset = (number: number) => setOffset(number);

  return (
    <Fragment>
      <Sidebar.Header title="Guardians" handleCreate={handleCreate} />
      <Sidebar.Content>
        {guardians &&
          guardians.map((guardian) => {
            return (
              <Sidebar.Item key={guardian.id} to={`/guardians/${guardian.id}`}>
                <div>
                  {guardian.first_name} {guardian.last_name}
                </div>
                <small>{guardian.email}</small>
              </Sidebar.Item>
            );
          })}
      </Sidebar.Content>
      <Sidebar.Footer
        updateOffset={updateOffset}
        offset={offset}
        limit={LIMIT}
        count={data?.users_aggregate.aggregate?.count}
      />
    </Fragment>
  );
};
