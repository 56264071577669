// TODO: Write tests!!!!

interface CombineDateTimeArgs {
  date: Date;
  time: Date;
  utc?: boolean;
  flattenSeconds?: boolean;
}
export const combineDateTime = ({
  date,
  time,
  utc = false,
  flattenSeconds = true,
}: CombineDateTimeArgs): Date => {
  const newDate = new Date(time);

  newDate.setFullYear(utc ? date.getUTCFullYear() : date.getFullYear());
  newDate.setMonth(utc ? date.getUTCMonth() : date.getMonth());
  newDate.setDate(utc ? date.getUTCDate() : date.getDate());

  if (flattenSeconds) {
    utc ? newDate.setSeconds(0) : newDate.setUTCSeconds(0);
  }

  return newDate;
};
