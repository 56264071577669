import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';
import { Avatar, Badge, Button, useMantineTheme } from '@mantine/core';
import { SectionTitle, InfoItem, Grid } from '@rr/ui';
import { getRandomThemeColor } from '@rr/utils';

import { useQuery } from '@apollo/client';
import { GET_TEACHER_QUERY } from './TeacherUpdate/GetTeacher.query.gql';
import { GetTeacherQuery } from 'types/graphql';

import { TeacherStudents } from './TeacherStudents';
import { TeacherLessons } from './TeacherLessons';
import { TeacherLocations } from './TeacherLocations';
import { TeacherUpdate } from './TeacherUpdate';
import { TeacherAssignStudents } from './TeacherAssignStudents';

export const TeacherDetail = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [assignStudentsModalOpen, setAssignStudentsModalOpen] = useState(false);
  const { userId }: { userId: string } = useParams();
  const theme = useMantineTheme();
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('lastTeacher', userId);
  }, [userId]);

  const { loading, error, data } = useQuery<GetTeacherQuery>(
    GET_TEACHER_QUERY,
    {
      variables: {
        uid: userId,
      },
    }
  );

  if (loading) return <div></div>;
  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        gap: 16px;
        align-items: center;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }

        .badges {
          display: flex;
          gap: 6px;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }

    .students,
    .lessons,
    .locations,
    .general {
      padding: 24px;

      a {
        display: block;
      }
    }
  `;

  if (data?.users_by_pk === null) {
    localStorage.removeItem('lastTeacher');
    window.location.href = '/teachers';
  }

  return (
    <>
      <div css={styles}>
        <div className="header">
          <div className="l">
            <Avatar color={getRandomThemeColor()} radius="xl">
              {data?.users_by_pk?.first_name?.charAt(0)}
              {data?.users_by_pk?.last_name?.charAt(0)}
            </Avatar>
            <div className="name t1">{data?.users_by_pk?.full_name}</div>
            <div className="badges">
              {data?.users_by_pk?.teacher_instruments.map(({ instrument }) => (
                <Badge
                  key={instrument.prettyName}
                  size="md"
                  color={getRandomThemeColor()}
                >
                  {instrument.prettyName}
                </Badge>
              ))}
            </div>
          </div>
          <div className="r">
            <Button variant="outline" color="red">
              Delete
            </Button>
            <Button onClick={() => setEditModalOpen(true)}>Edit</Button>
          </div>
        </div>
        <div className="general">
          <SectionTitle title="Basic Information" />
          <Grid columns={3}>
            <InfoItem title="Email address">
              {data?.users_by_pk?.email}
            </InfoItem>
            <InfoItem title="Phone">{data?.users_by_pk?.phone}</InfoItem>
          </Grid>
        </div>
        <div className="students">
          <SectionTitle title="Students">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setAssignStudentsModalOpen(true)}
            >
              Assign Student
            </Button>
          </SectionTitle>
          <TeacherStudents />
        </div>
        <div className="lessons">
          <SectionTitle title="Lessons">
            <Button variant="outline" color="blue" size="sm">
              + Create Lesson
            </Button>
          </SectionTitle>
          <TeacherLessons />
        </div>
        <div className="locations">
          <SectionTitle title="Locations">
            <Button variant="outline" color="blue" size="sm">
              Assign Location
            </Button>
          </SectionTitle>
          <TeacherLocations />
        </div>
      </div>
      <TeacherUpdate opened={editModalOpen} onClose={setEditModalOpen} />
      <TeacherAssignStudents
        opened={assignStudentsModalOpen}
        onClose={setAssignStudentsModalOpen}
      />
    </>
  );
};
