import { Fragment, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Code, useMantineTheme } from '@mantine/core';
import { IconCoin } from '@tabler/icons';

import { SectionTitle, InfoItem, Grid } from '@rr/ui';
import { formatCurrency } from '@rr/utils';

import { GET_PLAN_QUERY } from './GetPlan.query.gql';
import { DELETE_PLAN_MUTATION } from './DeletePlan.mutation.gql';
import { GetPlanQuery, DeletePlanMutation } from 'types/graphql';

import { PlanStudents } from './PlanStudents';
import { PlanLessons } from './PlanLessons';
import { PlanUpdate } from './PlanUpdate';

export const PlanDetail = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const theme = useMantineTheme();
  const history = useHistory();

  const { planId }: { planId: string } = useParams();
  const [deletePlan] = useMutation<DeletePlanMutation>(DELETE_PLAN_MUTATION, {
    variables: {
      id: planId,
    },
    refetchQueries: ['Plans'],
  });

  useEffect(() => {
    localStorage.setItem('lastPlan', planId);
  }, [planId]);

  const { loading, error, data } = useQuery<GetPlanQuery>(GET_PLAN_QUERY, {
    variables: {
      id: planId,
    },
  });

  if (loading) return <div></div>;

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        align-items: center;
        gap: 16px;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }

    .students,
    .lessons,
    .locations,
    .teachers,
    .general {
      padding: 24px;

      a {
        display: block;
      }
    }
  `;

  const handleDeletePlan = () => {
    const decision = window.confirm('Do you want to delete this plan?');
    if (decision) {
      deletePlan().then(() => {
        localStorage.removeItem('lastPlan');
        history.replace(`/plans`);
      });
    }
  };

  return (
    <Fragment>
      <div css={styles}>
        <div className="header">
          <div className="l">
            <Avatar color="blue" radius="xl">
              <IconCoin />
            </Avatar>
            <div className="name t1">{data?.plans_by_pk?.prettyName}</div>
            <Code color="orange">{data?.plans_by_pk?.id}</Code>
          </div>
          <div className="r">
            <Button variant="outline" color="red" onClick={handleDeletePlan}>
              Delete
            </Button>
            <Button onClick={() => setEditModalOpen(true)}>Edit</Button>
          </div>
        </div>
        <div className="general">
          <SectionTitle title="Plan Overview" />
          <Grid columns={4}>
            <InfoItem title="Lesson count">
              {data?.plans_by_pk?.lessonCount}
            </InfoItem>
            <InfoItem title="Lesson count threshold">
              {data?.plans_by_pk?.lessonCountThreshold}
            </InfoItem>
            <InfoItem title="Lesson price">
              {data?.plans_by_pk?.lessonPrice &&
                formatCurrency(data?.plans_by_pk?.lessonPrice)}
            </InfoItem>
            <InfoItem title="Lesson duration">
              {data?.plans_by_pk?.lessonDuration}
            </InfoItem>
          </Grid>
        </div>
        <div className="students">
          <SectionTitle title="Students">
            <Button variant="outline" size="sm">
              Assign Student
            </Button>
          </SectionTitle>
          <PlanStudents />
        </div>
        <div className="lessons">
          <SectionTitle title="Lessons" />
          <PlanLessons />
        </div>
      </div>
      <PlanUpdate opened={editModalOpen} onClose={setEditModalOpen} />
    </Fragment>
  );
};
