import { gql } from '@apollo/client';

export const AUTOCOMPLETE_LOCATION_QUERY = gql`
  query AutocompleteLocation {
    locations {
      id
      name
      location_type
    }
  }
`;
