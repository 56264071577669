import { useMutation, useLazyQuery } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Form } from '@rr/ui';
import { transformEnum } from '@rr/utils';
import {
  AutocompleteInstrument,
  AutocompleteLocation,
  AutocompleteStudent,
  AutocompleteTeacher,
} from 'components';

import { GET_SOURCE_LESSONS_QUERY } from './GetSourceLessons.query.gql';
import { COPY_SCHEDULE_MUTATION } from './CopySchedule.mutation.gql';

interface ICopyDayModalProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

const transformCopyData = (
  copyData: any,
  destinationDay: any,
  teacher_id: string
) => {
  return copyData?.lessons?.map((lesson: any) => {
    const destinationDayAsDate = new Date(destinationDay);
    const newEndTime = new Date(lesson.end_time);
    const newStartTime = new Date(lesson.start_time);

    newEndTime.setDate(destinationDayAsDate.getDate());
    newEndTime.setMonth(destinationDayAsDate.getMonth());
    newEndTime.setFullYear(destinationDayAsDate.getFullYear());

    newStartTime.setDate(destinationDayAsDate.getDate());
    newStartTime.setMonth(destinationDayAsDate.getMonth());
    newStartTime.setFullYear(destinationDayAsDate.getFullYear());

    return {
      teacher_id: lesson.teacher_id,
      student_id: lesson.student_id,
      instrument_id: lesson.instrument_id,
      location_id: lesson.location_id,
      plan_id: lesson.plan_id,
      status: 'SCHEDULED',
      start_time: newStartTime,
      end_time: newEndTime,
    };
  });
};

export const CopyDayModal = ({ opened, onClose }: ICopyDayModalProps) => {
  const INITIAL_VALUES = {
    teacher: '',
    originDay: new Date(),
    destinationDay: new Date(),
  };

  const VALIDATION_SCHEMA = Yup.object({});

  const [
    getLessons,
    { data: copyData, loading: copyLoading, error: copyError, refetch, called },
  ] = useLazyQuery(GET_SOURCE_LESSONS_QUERY);

  const [copySchedule, { data, loading, error }] = useMutation(
    COPY_SCHEDULE_MUTATION,
    {
      refetchQueries: ['Lessons', 'GetAllLessons'],
    }
  );

  return (
    <Modal opened={opened} onClose={() => onClose(false)} title="Copy schedule">
      <Form.Context
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          // Convert date to UTC
          const date = new Date(values.originDay);

          const dateConstruction = {
            year: date.getFullYear(),
            month:
              date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1,
            day: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
          };

          const constructDate = `${dateConstruction.year}-${dateConstruction.month}-${dateConstruction.day}`;

          const variables = {
            teacher_id: values.teacher,
            date: constructDate,
          };

          // console.log(variables);
          getLessons({ variables });

          if (copyError) {
            console.error(copyError);
          }

          if (!copyLoading && !copyError && copyData) {
            console.log(copyData);
            const transformedCopyData = transformCopyData(
              copyData,
              values.destinationDay,
              values.teacher
            );

            copySchedule({
              variables: {
                input: transformedCopyData,
              },
            })
              .then((response) => {
                console.log(response);
              })
              .catch(console.error);
          }

          // if (!called) {
          //   console.log('Not called');
          //   getLessons({
          //     variables,
          //   });
          //   if (refetch) {
          //     refetch();
          //     if (copyData) {
          //       console.log(copyData);
          //     }
          //   }
          // }

          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <AutocompleteTeacher label="Teacher" name="teacher" />
            <Space h="md" />
            <Form.DatePicker label="Origin day" name="originDay" />
            <Space h="md" />
            <Form.DatePicker label="Destination day" name="destinationDay" />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              fullWidth
              onClick={submitForm}
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Copy day' : 'Copying...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
