import { css } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';
export const SidebarContainer: React.FC = ({ children }) => {
  const theme = useMantineTheme();
  const styles = css`
    background: ${theme.colors.gray[0]};
    width: 320px;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
    margin: 24px 0;
  `;
  return <div css={styles}>{children}</div>;
};
