import { gql } from '@apollo/client';

export const GET_LESSON_QUERY = gql`
  query GetLesson($id: uuid!) {
    lessons_by_pk(id: $id) {
      id
      start_time
      end_time
      instrument_id
      location_id
      student_id
      teacher_id
      status
      instrument {
        id
        name
        prettyName
      }
      location {
        id
        name
      }
      student {
        id
        first_name
        last_name
        gdpr_name
      }
      user {
        id
        first_name
        last_name
        gdpr_name
      }
    }
  }
`;
