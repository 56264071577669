import { css } from '@emotion/react';
import { Fragment, SetStateAction, useState, Dispatch } from 'react';
import { Sidebar, TableSortPropertyState } from '@rr/ui';
import { useInvoicesQuery } from 'types/graphql';

const LIMIT = 20;

// TODO extract to shared type

interface InvoiceListProps {
  handleCreate?: Dispatch<SetStateAction<boolean>>;
}

export const InvoiceList = ({ handleCreate }: InvoiceListProps) => {
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderby] = useState<TableSortPropertyState>({
    id: 'asc',
  });

  const { error, data } = useInvoicesQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: { ...orderBy },
    },
  });

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const invoices = data?.invoices || [];
  const updateOffset = (number: number) => setOffset(number);

  const itemStyles = css`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;

    .invoiceName:last-of-type {
      width: 100%;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.active {
      svg {
        opacity: 1;
      }
    }

    svg {
      opacity: 0.5;
    }
  `;

  return (
    <Fragment>
      <Sidebar.Header title="Invoices" handleCreate={handleCreate} />
      <Sidebar.Content>
        {invoices &&
          invoices.map((invoice) => {
            return (
              <Sidebar.Item
                key={invoice.id}
                to={`/invoices/${invoice.id}`}
                style={itemStyles}
              >
                <div className="invoiceName">{invoice.id}</div>
              </Sidebar.Item>
            );
          })}
      </Sidebar.Content>
    </Fragment>
  );
};
