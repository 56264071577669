import { createRef } from 'react';
import { css } from '@emotion/react';
import FullCalendar from '@fullcalendar/react';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceDaygridPlugin from '@fullcalendar/resource-daygrid';

const DIRECTION = {
  PREV: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
};

type IDirection = keyof typeof DIRECTION;

const Calendar = ({ ...rest }: CalendarOptions) => {
  const calendarRef = createRef<FullCalendar>();

  const styles = css`
    height: 100%;

    .Toolbar {
      display: flex;
      justify-content: flex-end; // TODO: Change to space-between after date added
      align-items: center;
      gap: 8px;

      .group {
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }

    .fc-toolbar {
      // display: none;
    }
  `;

  return (
    <div css={styles} className="Calendar">
      <FullCalendar
        ref={calendarRef}
        plugins={[
          resourceDaygridPlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ]}
        schedulerLicenseKey="0509394045-fcs-1657119301"
        editable
        {...rest}
      />
    </div>
  );
};

export { Calendar };
