import { TableWrapper } from './TableWrapper';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { TablePagination } from './TablePagination';
import { TableActions } from './TableActions';
export * from './types';

export const Table = {
  Wrapper: TableWrapper,
  Header: TableHeader,
  Body: TableBody,
  Actions: TableActions,
  Pagination: TablePagination,
};
