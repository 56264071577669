import { gql } from '@apollo/client';

export const UPDATE_LESSON_SCHEDULE_MUTATION = gql`
  mutation UpdateLessonTime(
    $id: uuid!
    $start_time: timestamptz!
    $end_time: timestamptz!
  ) {
    update_lessons_by_pk(
      pk_columns: { id: $id }
      _set: { start_time: $start_time, end_time: $end_time }
    ) {
      id
      start_time
      end_time
    }
  }
`;
