import { gql } from '@apollo/client';

export const GET_STUDENT_LOCATIONS_QUERY = gql`
  query GetAdminStudentLocations($id: uuid!, $limit: Int!, $offset: Int!) {
    students_by_pk(id: $id) {
      id
      student_locations(limit: $limit, offset: $offset) {
        location {
          name
          id
        }
      }
      student_locations_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
