import { Fragment, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, useMantineTheme } from '@mantine/core';
import { IconMusic } from '@tabler/icons';

import { SectionTitle } from '@rr/ui';

import { GET_INSTRUMENT_QUERY } from './GetInstrument.query.gql';
import { DELETE_INSTRUMENT_MUTATION } from './DeleteInstrument.mutation.gql';
import { GetInstrumentQuery, DeleteInstrumentMutation } from 'types/graphql';

import { InstrumentTeachers } from './InstrumentTeachers';
import { InstrumentStudents } from './InstrumentStudents';
import { InstrumentUpdate } from './InstrumentUpdate';
import { InstrumentAssignTeachers } from './InstrumentAssignTeachers';
import { InstrumentAssignStudents } from './InstrumentAssignStudents';

export const InstrumentDetail = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [assignTeachersModalOpen, setAssignTeachersModalOpen] = useState(false);
  const [assignStudentsModalOpen, setAssignStudentsModalOpen] = useState(false);
  const theme = useMantineTheme();
  const history = useHistory();

  const { instrumentId }: { instrumentId: string } = useParams();
  const [deleteInstrument] = useMutation<DeleteInstrumentMutation>(
    DELETE_INSTRUMENT_MUTATION,
    {
      variables: {
        id: instrumentId,
      },
      refetchQueries: ['Instruments'],
    }
  );

  useEffect(() => {
    localStorage.setItem('lastInstrument', instrumentId);
  }, [instrumentId]);

  const { loading, error, data } = useQuery<GetInstrumentQuery>(
    GET_INSTRUMENT_QUERY,
    {
      variables: {
        id: instrumentId,
      },
    }
  );

  if (loading) return <div></div>;

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        align-items: center;
        gap: 16px;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }

    .students,
    .lessons,
    .locations,
    .teachers,
    .general {
      padding: 24px;

      a {
        display: block;
      }
    }
  `;

  const handleDeleteInstrument = () => {
    const decision = window.confirm('Do you want to delete this instrument?');
    if (decision) {
      deleteInstrument().then(() => {
        localStorage.removeItem('lastInstrument');
        history.replace(`/instruments`);
      });
    }
  };

  return (
    <Fragment>
      <div css={styles}>
        <div className="header">
          <div className="l">
            <Avatar color="blue" radius="xl">
              <IconMusic />
            </Avatar>
            <div className="name t1">{data?.instruments_by_pk?.prettyName}</div>
          </div>
          <div className="r">
            <Button
              variant="outline"
              color="red"
              onClick={handleDeleteInstrument}
            >
              Delete
            </Button>
            <Button onClick={() => setEditModalOpen(true)}>Edit</Button>
          </div>
        </div>
        <div className="teachers">
          <SectionTitle title="Teachers">
            <Button
              onClick={() => setAssignTeachersModalOpen(true)}
              variant="outline"
            >
              + Assign Teachers
            </Button>
          </SectionTitle>
          <InstrumentTeachers />
        </div>
        <div className="students">
          <SectionTitle title="Students">
            <Button
              onClick={() => setAssignStudentsModalOpen(true)}
              variant="outline"
            >
              + Assign Students
            </Button>
          </SectionTitle>
          <InstrumentStudents />
        </div>
      </div>
      <InstrumentUpdate opened={editModalOpen} onClose={setEditModalOpen} />
      <InstrumentAssignTeachers
        opened={assignTeachersModalOpen}
        onClose={setAssignTeachersModalOpen}
      />
      <InstrumentAssignStudents
        opened={assignStudentsModalOpen}
        onClose={setAssignStudentsModalOpen}
      />
    </Fragment>
  );
};
