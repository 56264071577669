import { gql } from '@apollo/client';

export const STUDENT_PLAN_ASSIGN_MUTATION = gql`
  mutation StudentPlanAssign(
    $studentId: uuid!
    $planId: uuid!
    $teacherId: String!
    $instrumentId: uuid!
    $locationId: uuid!
  ) {
    insert_student_plans_one(
      object: {
        student_id: $studentId
        plan_id: $planId
        teacher_id: $teacherId
        instrument_id: $instrumentId
        location_id: $locationId
      }
    ) {
      id
      student_id
      plan_id
      teacher_id
      instrument_id
      location_id
    }
  }
`;
