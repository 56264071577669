import { css } from '@emotion/react';

interface DetailSectionProps {
  title: string;
  open?: boolean;
}

export const DetailSection: React.FC<DetailSectionProps> = ({
  children,
  title,
  open = true,
}) => {
  const styles = css`
    height: auto;

    summary {
      padding: 16px;
      font-size: 1.4rem;
      font-weight: 500;
      list-style: none;
      margin: 0;
      padding-left: 0;

      &::before {
        display: inline-block;
        content: '➡️';
        margin-right: 8px;
        transform-origin: center;
      }
    }

    &[open] {
      summary {
        &::before {
          transform: rotateZ(90deg);
        }
      }
    }
  `;

  return (
    <details css={styles} open={open}>
      <summary className="t1">{title}</summary>
      <div className="detailContent">{children}</div>
    </details>
  );
};
