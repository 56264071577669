import { Fragment } from 'react';
import { css, Global } from '@emotion/react';
import emotionReset from 'emotion-reset';

import { ApolloProvider } from '@rr/apollo-client';

const AppContainer: React.FC = ({ children }) => {
  const styles = css`
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap');
    // @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

    ${emotionReset}

    * {
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
    }

    html {
      font-family: 'Inter', sans-serif;
      font-size: 15px;
    }

    .t1 {
      font-family: 'Poppins', sans-serif;
    }

    .t2 {
      font-family: 'Inter', sans-serif;
    }
  `;

  return (
    <Fragment>
      <Global styles={styles} />
      <ApolloProvider>{children}</ApolloProvider>
    </Fragment>
  );
};

export { AppContainer };
