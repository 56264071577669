import { gql } from '@apollo/client';

export const AUTOCOMPLETE_INSTRUMENT_QUERY = gql`
  query AutocompleteInstrument {
    instruments {
      id
      name
      prettyName
    }
  }
`;
