import { Fragment, SetStateAction, useState, Dispatch } from 'react';
import { Sidebar, TableSortPropertyState } from '@rr/ui';
import { useGetInstrumentsQuery } from 'types/graphql';

const LIMIT = 20;

// TODO Extract to shared type
interface InstrumentListProps {
  handleCreate?: Dispatch<SetStateAction<boolean>>;
}

export const InstrumentList = ({ handleCreate }: InstrumentListProps) => {
  const [offset, setOffset] = useState(0);

  const [orderBy, setOrderBy] = useState<TableSortPropertyState>({
    name: 'asc',
  });

  const { error, data } = useGetInstrumentsQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: {
        ...orderBy,
      },
    },
  });

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const instruments = data?.instruments || [];
  const updateOffset = (number: number) => setOffset(number);

  return (
    <Fragment>
      <Sidebar.Header title="Instruments" handleCreate={handleCreate} />
      <Sidebar.Content>
        {instruments &&
          instruments.map((instrument) => (
            <Sidebar.Item
              key={instrument.id}
              to={`/instruments/${instrument.id}`}
            >
              <div>{instrument.prettyName}</div>
            </Sidebar.Item>
          ))}
      </Sidebar.Content>
      <Sidebar.Footer
        updateOffset={updateOffset}
        offset={offset}
        limit={LIMIT}
        count={data?.instruments_aggregate.aggregate?.count}
      />
    </Fragment>
  );
};
