import { gql } from '@apollo/client';

export const DELETE_LESSON_MUTATION = gql`
  mutation DeleteLesson($id: uuid!) {
    delete_lessons(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
