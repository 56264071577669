import { gql } from '@apollo/client';

export const GET_PLAN_QUERY = gql`
  query GetPlan($id: uuid!) {
    plans_by_pk(id: $id) {
      id
      name
      prettyName
      active
      lessonCount
      lessonPrice
      lessonCountThreshold
      lessonDuration
    }
  }
`;
