import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Form } from '@rr/ui';
import { transformEnum } from '@rr/utils';
import { CreatePlanMutation } from 'types/graphql';
import { CREATE_PLAN_MUTATION } from './CreatePlan.mutation.gql';

interface PlanCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const PlanCreate = ({ opened, onClose }: PlanCreateProps) => {
  const history = useHistory();
  const [createPlan, { data, loading, error }] =
    useMutation<CreatePlanMutation>(CREATE_PLAN_MUTATION, {
      refetchQueries: ['Plans'],
    });

  const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Required'),
    lessonCount: Yup.number().required('Required'),
    lessonCountThreshold: Yup.number().required('Required'),
    lessonPrice: Yup.number().required('Required'),
    lessonDuration: Yup.number().required('Required'),
    active: Yup.boolean(),
  });

  const INITIAL_VALUES = {
    name: '',
    lessonCount: 0,
    lessonCountThreshold: 0,
    lessonPrice: 0,
    lessonDuration: 20,
    active: false,
  };
  // a comment

  return (
    <Modal opened={opened} onClose={() => onClose(false)} title="Create a plan">
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            createPlan({
              variables: {
                name: transformEnum(values.name),
                prettyName: values.name,
                lessonCount: values.lessonCount,
                lessonCountThreshold: values.lessonCountThreshold,
                lessonPrice: values.lessonPrice,
                lessonDuration: values.lessonDuration,
              },
            }).then((response) => {
              history.push(`/plans/${response.data?.insert_plans_one?.id}`);
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="name"
              label="Name"
              placeholder="Plan name"
              fullWidth
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonCount"
              label="Lesson Count"
              placeholder="Lesson Count"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonCountThreshold"
              label="Lesson Threshold"
              placeholder="Lesson Threshold"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonPrice"
              label="Lesson Price"
              placeholder="Lesson Price"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonDuration"
              label="Lesson Duration"
              placeholder="Lesson Duration"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create plan' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
