import { css } from '@emotion/react';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, useMantineTheme } from '@mantine/core';

import { GetAllTeachersQuery, useGetAllLessonsQuery } from 'types/graphql';

import { Calendar } from '@rr/ui';
import { getRandomThemeColor } from '@rr/utils';

import { UPDATE_LESSON_SCHEDULE_MUTATION } from './UpdateLessonSchedule.mutation.gql';
import { GET_ALL_TEACHERS_QUERY } from './GetAllTeachers.query.gql';

import { CopyDayModal } from './CopyDayModal';
import { LessonCreateModal } from './LessonCreateModal';
import { LessonEditModal } from './LessonEditModal';

interface ILessonCreateModalDate {
  start: Date;
  end: Date;
  day: Date;
}

const Dashboard = () => {
  const { colors } = useMantineTheme();
  const [copyDayModalOpened, setCopyDayModalOpened] = useState(false);
  const [lessonCreateModalOpened, setLessonCreateModalOpened] = useState(false);
  const [lessonCreateModalDate, setLessonCreateModalDate] =
    useState<ILessonCreateModalDate>({
      start: new Date(),
      end: new Date(),
      day: new Date(),
    });

  const [lessonEditModalOpened, setLessonEditModalOpened] = useState(false);
  const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);

  const styles = css`
    box-sizing: border-box;
    padding: 24px;
    height: calc(100vh - 64px);

    .w {
      background: white;
      box-sizing: border-box;
      height: 100%;
      padding: 16px;
      border-radius: 4px;
      box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
    }
  `;

  const { data: teachersData } = useQuery<GetAllTeachersQuery>(
    GET_ALL_TEACHERS_QUERY
  );

  const { error, data } = useGetAllLessonsQuery({
    variables: {
      offset: 0,
      limit: 9999,
      // @ts-expect-error stupid
      order_by: { start_time: 'asc' },
    },
  });

  const [updateLessonSchedule] = useMutation(UPDATE_LESSON_SCHEDULE_MUTATION);

  const lessons = data?.lessons || [];

  const generateColor = (status: 'SCHEDULED' | 'COMPLETE' | 'CANCELLED') => {
    if (!status) {
      return colors.indigo[5];
    }

    const eventColors = {
      SCHEDULED: colors.green[5],
      COMPLETE: colors.gray[5],
      CANCELLED: colors.red[5],
    };

    return eventColors[status] || eventColors['SCHEDULED'];
  };

  const events = lessons.map((lesson) => ({
    id: lesson.id,
    title: `${lesson.student.gdpr_name} / ${lesson.user.full_name} / ${lesson.instrument?.prettyName} / ${lesson.location?.name}`,
    start: lesson.start_time,
    end: lesson.end_time,
    resourceId: lesson.user.id,
    status: lesson.status,
    color: generateColor(lesson.status as any),
  }));

  const handleEventDrop = (arg: any) => {
    const { id, start, end } = arg.event;

    updateLessonSchedule({
      variables: {
        id,
        start_time: start,
        end_time: end,
      },
      refetchQueries: ['GetAllLessons'],
      awaitRefetchQueries: true,
    })
      .then((res) => {
        console.log(res);
      })
      .catch(console.error);
  };

  const handleDateClick = (arg: any) => {
    // add 20 minutes to end time
    const end = new Date(arg.date);
    end.setMinutes(end.getMinutes() + 20);

    setLessonCreateModalDate({
      start: arg.date,
      end: end,
      day: arg.date,
    });
    setTimeout(() => {
      setLessonCreateModalOpened(true);
    }, 200);
  };

  const handleEventClick = (arg: any) => {
    setSelectedLessonId(arg.event.id);
    setLessonEditModalOpened(true);
  };

  const handleCopyDay = (e: any) => {
    setCopyDayModalOpened(true);
  };

  return (
    <div css={styles}>
      <div className="w">
        <Button onClick={handleCopyDay}>Placeholder copy day</Button>
        <Calendar
          initialView="timeGridWeek"
          events={events}
          height="100%"
          editable
          eventStartEditable
          selectable
          select={(args) => console.log(args.start, args.end)}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          eventResize={handleEventDrop}
          defaultTimedEventDuration="00:20:00"
          dateClick={handleDateClick}
          slotDuration="00:20:00"
          firstDay={1}
          nowIndicator
          resources={teachersData?.users.map((teacher, index) => {
            const availableColors = ['blue', 'green'];

            return {
              id: teacher.id,
              title: teacher.first_name + ' ' + teacher.last_name,
              eventColor:
                index % 2 === 0 ? colors['green'][8] : colors['grape'][8],
            };
          })}
        />
      </div>
      <CopyDayModal
        opened={copyDayModalOpened}
        onClose={() => setCopyDayModalOpened(false)}
      />
      <LessonCreateModal
        opened={lessonCreateModalOpened}
        onClose={() => setLessonCreateModalOpened(false)}
        start={lessonCreateModalDate.start}
        end={lessonCreateModalDate.end}
        day={lessonCreateModalDate.day}
      />
      <LessonEditModal
        opened={lessonEditModalOpened}
        onClose={() => setLessonEditModalOpened(false)}
        lessonId={selectedLessonId}
      />
    </div>
  );
};

export { Dashboard };
