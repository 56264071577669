import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Modal, Space } from '@mantine/core';

import { UPDATE_INSTRUMENT_MUTATION } from './UpdateInstrument.mutation.gql';
import { GET_INSTRUMENT_QUERY } from '../GetInstrument.query.gql';
import { GetInstrumentQuery, UpdateInstrumentMutation } from 'types/graphql';

import { Form } from '@rr/ui';
import { transformEnum } from '@rr/utils';

interface InstrumentUpdateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const InstrumentUpdate = ({
  opened,
  onClose,
}: InstrumentUpdateProps) => {
  const { instrumentId }: { instrumentId: string } = useParams();

  const { data } = useQuery<GetInstrumentQuery>(GET_INSTRUMENT_QUERY, {
    variables: {
      id: instrumentId,
    },
  });

  const [updateInstrument] = useMutation<UpdateInstrumentMutation>(
    UPDATE_INSTRUMENT_MUTATION,
    {
      refetchQueries: ['GetInstrument'],
    }
  );

  const VALIDATION_SCHEMA = Yup.object({
    prettyName: Yup.string().required('Required'),
  });

  const INITIAL_VALUES = {
    prettyName: data?.instruments_by_pk?.prettyName || '',
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title={`Update instrument: ${data?.instruments_by_pk?.prettyName}`}
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            updateInstrument({
              variables: {
                id: instrumentId,
                prettyName: values.prettyName,
                name: transformEnum(values.prettyName),
              },
            }).then(() => {
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="prettyName"
              label="Name"
              placeholder="Instrument name"
              fullWidth
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Save instrument' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
