// VERB ADDITIONS
const VERB_CREATE = 'create';

// AUTHORIZATION ROUTES
export const LOGIN = '/login';
export const ACCOUNT_CREATE = `/${VERB_CREATE}-account`;
export const VERIFY_LINK = '/verify';

// USERS
export const USERS = '/users';
export const USERS_CREATE = `${USERS}/${VERB_CREATE}`;
export const USERS_UPDATE = `${USERS}/:userId`;

// SCHEDULE TODO: Not done yet
export const SCHEDULE = '/schedule';

// STUDENTS
export const STUDENTS = '/students';
export const STUDENTS_CREATE = `${STUDENTS}/${VERB_CREATE}`;
export const STUDENTS_UPDATE = `${STUDENTS}/:studentId`;

// TEACHERS
export const TEACHERS = '/teachers';
export const TEACHERS_CREATE = `${TEACHERS}/${VERB_CREATE}`;
export const TEACHERS_UPDATE = `${TEACHERS}/:userId`;

export const GUARDIANS = '/guardians';
export const GUARDIANS_CREATE = `${GUARDIANS}/${VERB_CREATE}`;
export const GUARDIANS_UPDATE = `${GUARDIANS}/:userId`;

// LOCATIONS
export const LOCATIONS = '/locations';
export const LOCATIONS_CREATE = `${LOCATIONS}/${VERB_CREATE}`;
export const LOCATIONS_UPDATE = `${LOCATIONS}/:locationId`;

// INSTRUMENTS
export const INSTRUMENTS = '/instruments';
export const INSTRUMENTS_CREATE = `${INSTRUMENTS}/${VERB_CREATE}`;
export const INSTRUMENTS_UPDATE = `${INSTRUMENTS}/:instrumentId`;

// PAYMENTS
export const PLANS = '/plans';
export const PLANS_CREATE = `${PLANS}/${VERB_CREATE}`;
export const PLANS_UPDATE = `${PLANS}/:planId`;

// INVOICES
export const INVOICES = '/invoices';
export const INVOICES_CREATE = `${INVOICES}/${VERB_CREATE}`;
export const INVOICES_UPDATE = `${INVOICES}/:invoiceId`;

// LESSONS
export const LESSONS = '/lessons';
export const LESSONS_CREATE = `${LESSONS}/${VERB_CREATE}`;
export const LESSONS_UPDATE = `${LESSONS}/:lessonId`;
