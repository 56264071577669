import { gql } from '@apollo/client';

export const GUARDIAN_CREATE_MUTATION = gql`
  mutation GuardianCreate(
    $id: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
  ) {
    insert_users_one(
      object: {
        id: $id
        first_name: $firstName
        last_name: $lastName
        email: $email
        phone: $phone
        isGuardian: true
      }
    ) {
      id
    }
  }
`;
