import { gql } from '@apollo/client';

export const CREATE_PLAN_MUTATION = gql`
  mutation CreatePlan(
    $name: String!
    $prettyName: String!
    $lessonCount: Int!
    $lessonPrice: Int!
    $lessonCountThreshold: Int!
    $lessonDuration: Int!
  ) {
    insert_plans_one(
      object: {
        name: $name
        prettyName: $prettyName
        lessonCount: $lessonCount
        lessonPrice: $lessonPrice
        lessonCountThreshold: $lessonCountThreshold
        lessonDuration: $lessonDuration
      }
    ) {
      id
    }
  }
`;
