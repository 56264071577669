const humanDate = (
  date: string,
  formatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }
) =>
  new Intl.DateTimeFormat('en-GB', { ...formatOptions }).format(new Date(date));

export { humanDate };
