import { css } from '@emotion/react';

export const SplitLayout: React.FC = ({ children }) => {
  const styles = css`
    display: flex;
    gap: 24px;
    height: calc(100vh - 64px);
    padding-left: 24px;
  `;

  return <div css={styles}>{children}</div>;
};
