import { gql } from '@apollo/client';

export const GET_TEACHER_LOCATIONS_QUERY = gql`
  query GetTeacherLocations($uid: String!, $limit: Int!, $offset: Int!) {
    users_by_pk(id: $uid) {
      teacher_locations(limit: $limit, offset: $offset) {
        location {
          name
          id
        }
      }
      teacher_locations_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
