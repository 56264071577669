import { css } from '@emotion/react';
import { Field as FormikField, ErrorMessage } from 'formik';

interface FieldProps {
  type: 'email' | 'text' | 'password' | 'textarea';
  name: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const Field: React.FC<FieldProps> = ({
  type,
  placeholder,
  fullWidth,
  disabled,
  name,
}) => {
  const styles = css`
    input {
      width: ${fullWidth ? '100%' : 'auto'};
      appearance: none;
      border: none;
      padding: 16px;
      font: inherit;
      outline: none;
      opacity: ${!disabled ? 1 : 0.5};
    }

    textarea {
      width: ${fullWidth ? '100%' : 'auto'};
      appearance: none;
      border: none;
      padding: 16px;
      font: inherit;
      outline: none;
      opacity: ${!disabled ? 1 : 0.5};
      min-height: 120px;
    }
  `;

  return (
    <div className="Field" css={styles}>
      <FormikField
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        name={name}
        as={type === 'textarea' ? 'textarea' : 'input'}
      />
      <ErrorMessage name={name} component="div" />
    </div>
  );
};

export { Field };
