import { gql } from '@apollo/client';

export const AUTOCOMPLETE_GUARDIANS_QUERY = gql`
  query AutocompleteGuardians {
    users(where: { isGuardian: { _eq: true } }) {
      id
      first_name
      last_name
    }
  }
`;
