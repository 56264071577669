import { css } from '@emotion/react';

interface AlertProps {
  message: string;
  type?: 'standard' | 'error' | 'success' | 'warning';
}

const Alert: React.FC<AlertProps> = ({ message, type = 'standard' }) => {
  const styles = {
    base: css`
      padding: 16px;
      border-radius: 4px;
      color: white;
      font-weight: 600;
    `,
    types: {
      standard: css`
        background: #0b0b0b;
        color: white;
      `,
      error: css`
        background: #eba5a0;
        color: #db261d;
      `,
      success: css`
        background: #a0eba5;
        color: #349c2a;
      `,
      warning: css`
        background: #ebdfa0;
        color: #9c872a;
      `,
    },
  };

  return (
    <div className="Alert" css={[styles.base, styles.types[type]]}>
      {message}
    </div>
  );
};

export { Alert };
