import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Form } from '@rr/ui';
import { transformEnum } from '@rr/utils';
import {
  AutocompleteInstrument,
  AutocompleteLocation,
  AutocompleteStudent,
  AutocompleteTeacher,
} from 'components';

import { LESSON_CREATE_MUTATION } from './LessonCreate.mutation.gql';

interface LessonCreateModalProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
  day: Date;
  start: Date;
  end: Date;
}

export const LessonCreateModal = ({
  opened,
  onClose,
  day,
  start,
  end,
}: LessonCreateModalProps) => {
  const INITIAL_VALUES = {
    student: '',
    instrument: '',
    teacher: '',
    location: '',
    start: start,
    end: end,
    day: day,
  };

  const VALIDATION_SCHEMA = Yup.object({});

  const [createLesson, { data, loading, error }] = useMutation(
    LESSON_CREATE_MUTATION,
    {
      refetchQueries: ['Lessons', 'GetAllLessons'],
    }
  );

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create a lesson"
    >
      <Form.Context
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          createLesson({
            variables: {
              input: {
                student_id: values.student,
                instrument_id: values.instrument,
                teacher_id: values.teacher,
                location_id: values.location,
                start_time: values.start,
                end_time: values.end,
                status: 'SCHEDULED',
              },
            },
          })
            .then((res) => {
              setSubmitting(false);
              onClose(false);
            })
            .catch(console.error);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <AutocompleteStudent label="Student" name="student" />
            <Space h="md" />
            <AutocompleteTeacher label="Teacher" name="teacher" />
            <Space h="md" />
            <AutocompleteLocation label="Location" name="location" />
            <Space h="md" />
            <AutocompleteInstrument label="Instrument" name="instrument" />
            <Space h="md" />
            <hr />
            <Form.DatePicker label="Day" name="day" />
            <Space h="md" />
            <Form.TimeInput label="Start" name="start" />
            <Space h="md" />
            <Form.TimeInput label="End" name="end" />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              fullWidth
              onClick={submitForm}
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create lesson' : 'Creating lesson...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
