import { css } from '@emotion/react';
export const SplitContainer: React.FC = ({ children }) => {
  const styles = css`
    flex: 1 0 auto;
    height: calc(100vh - 64px);
    padding: 24px 24px 24px 0;
    overflow-y: auto;
  `;
  return <div css={styles}>{children}</div>;
};
