import { ApolloProvider } from '@apollo/client';
import { useState } from 'react';

import { createApolloClient } from './createApolloClient';

const Provider: React.FC = ({ children }) => {
  const [client] = useState(createApolloClient());

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { Provider as ApolloProvider };
