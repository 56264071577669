import { gql } from '@apollo/client';

export const STUDENT_UPDATE_MUTATION = gql`
  mutation UpdateStudent(
    $id: uuid!
    $first_name: String!
    $last_name: String!
    $notes: String!
    $instrumentIds: [student_instruments_insert_input!]!
    $locationIds: [student_locations_insert_input!]!
    $teacherIds: [student_teachers_insert_input!]!
    $guardianIds: [student_users_insert_input!]!
  ) {
    update_students_by_pk(
      pk_columns: { id: $id }
      _set: { first_name: $first_name, last_name: $last_name, notes: $notes }
    ) {
      id
      first_name
      last_name
      notes
    }
    delete_student_instruments(where: { student_id: { _eq: $id } }) {
      affected_rows
    }
    insert_student_instruments(objects: $instrumentIds) {
      affected_rows
    }
    delete_student_locations(where: { student_id: { _eq: $id } }) {
      affected_rows
    }
    insert_student_locations(objects: $locationIds) {
      affected_rows
    }
    delete_student_teachers(where: { student_id: { _eq: $id } }) {
      affected_rows
    }
    insert_student_teachers(objects: $teacherIds) {
      affected_rows
    }
    delete_student_users(where: { student_id: { _eq: $id } }) {
      affected_rows
    }
    insert_student_users(objects: $guardianIds) {
      affected_rows
    }
  }
`;
