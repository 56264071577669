import { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { useMantineTheme, ActionIcon } from '@mantine/core';
import { IconPlus } from '@tabler/icons';

interface SidebarHeaderProps {
  title: string;
  handleCreate?: Dispatch<SetStateAction<boolean>>;
}

export const SidebarHeader = ({ title, handleCreate }: SidebarHeaderProps) => {
  const theme = useMantineTheme();
  const styles = css`
    background: white;
    height: 48px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid ${theme.colors.gray[2]};
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 600;
    justify-content: space-between;
  `;

  return (
    <div css={styles} className="t1">
      {title}
      {handleCreate && (
        <ActionIcon
          color="blue"
          onClick={() => handleCreate(true)}
          variant="outline"
        >
          <IconPlus size={18} />
        </ActionIcon>
      )}
    </div>
  );
};
