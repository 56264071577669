import { css } from '@emotion/react';

interface BadgeProps {
  role: 'admin' | 'student' | 'teacher';
}

const Badge: React.FC<BadgeProps> = ({ role }) => {
  // const styles = css`
  //   background: orange;
  //   display: inline-block;
  //   padding: 6px 8px;

  //   &:not(:last-of-type) {
  //     margin-right: 4px;
  //   }
  // `;
  const styles = {
    base: css`
      display: inline-block;
      font-size: 0.9em;
      font-weight: 600;

      &:not(:last-of-type) {
        margin-right: 4px;
      }
    `,
    admin: css`
      background: skyblue;
      color: black;
    `,
    student: css`
      background: coral;
      color: black;
    `,
    teacher: css`
      background: lightgreen;
      color: black;
    `,
  };

  return <div css={[styles.base, styles[role]]}>{role}</div>;
};

export { Badge };
