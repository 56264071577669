import { Fragment, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Code, useMantineTheme } from '@mantine/core';

import { SectionTitle, InfoItem, Grid } from '@rr/ui';
import { formatCurrency } from '@rr/utils';

export const InvoiceDetail = () => {
  const [editModalopen, setEditModalOpen] = useState(false);
  const theme = useMantineTheme();
  const history = useHistory();

  const { invoiceId }: { invoiceId: string } = useParams();

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        align-items: center;
        gap: 16px;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }
  `;

  return (
    <Fragment>
      <div css={styles}>
        <div className="header">
          <div className="l">
            <Avatar color="blue" radius="xl">
              {/* <IconCoin /> */}
            </Avatar>
            <div className="name t1">Person invoice</div>
            <Code color="orange">Invoice ID</Code>
          </div>
          <div className="r">
            <Button variant="outline" color="red" onClick={() => {}}>
              Delete
            </Button>
            <Button onClick={() => setEditModalOpen(true)}>Update</Button>
          </div>
        </div>
      </div>
      <div>InvoiceUpdate</div>
    </Fragment>
  );
};
