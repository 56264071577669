import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Modal, Space } from '@mantine/core';

import { Form } from '@rr/ui';
import { AutocompleteStudent } from 'components';
import { GET_TEACHER_STUDENTS_QUERY } from '../TeacherStudents/GetTeacherStudents.query.gql';
import { ASSIGN_STUDENTS_TO_TEACHER_MUTATION } from './AssignStudentsToTeacher.mutation.gql';
import { GetTeacherStudentsQuery } from 'types/graphql';

interface TeacherAssignStudentsProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const TeacherAssignStudents = ({
  opened,
  onClose,
}: TeacherAssignStudentsProps) => {
  const { userId }: { userId: string } = useParams();

  const { loading, error, data } = useQuery<GetTeacherStudentsQuery>(
    GET_TEACHER_STUDENTS_QUERY,
    {
      variables: {
        id: userId,
      },
    }
  );

  const [assignStudentsToTeacher] = useMutation(
    ASSIGN_STUDENTS_TO_TEACHER_MUTATION,
    {
      refetchQueries: ['GetTeacher', 'GetTeacherStudents'],
    }
  );

  console.log(data);

  const initialValues = {
    students: [],
  };

  const validationSchema = Yup.object().shape({
    students: Yup.array().required('Required'),
  });

  const onSubmit = (values: typeof initialValues) => {
    console.log('values', values);
    console.log(userId);
    assignStudentsToTeacher({
      variables: {
        studentIds: values.students.map((student) => ({
          teacher_id: userId,
          student_id: student,
        })),
      },
    }).then((response) => {
      console.log(response);
    });
    onClose(false);
  };

  const existingIds =
    data?.users_by_pk?.student_teachers.map((student) => student.student.id) ||
    [];

  const studentData = data?.students.map((student) => ({
    value: student.id,
    label: `${student.first_name} ${student.last_name}`,
    firstName: student.first_name,
    lastName: student.last_name,
  }));

  const filteredStudentData = studentData?.filter(
    (student) => !existingIds.includes(student.value)
  );

  console.table(filteredStudentData);

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Assign students to a teacher"
    >
      <Form.Context
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form.Wrapper>
          <AutocompleteStudent
            value={filteredStudentData}
            multiple
            name="students"
            label="Students"
          />
          <Space h="md" />
          <Button type="submit" color="blue" fullWidth>
            Assign students
          </Button>
        </Form.Wrapper>
      </Form.Context>
    </Modal>
  );
};
