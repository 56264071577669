import { gql } from '@apollo/client';

export const GET_GUARDIAN_PLANS = gql`
  query GuardianPlans($uid: String!) {
    users_by_pk(id: $uid) {
      student_users {
        id
        student {
          student_plans {
            plan {
              id
              active
              lessonCount
              lessonCountThreshold
              lessonPrice
              prettyName
            }
            user {
              id
              gdpr_name
            }
            student {
              id
              gdpr_name
            }
          }
          student_plans_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;
