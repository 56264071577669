import { css } from '@emotion/react';
import { Button } from '@mantine/core';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons';

interface SidebarFooterProps {
  offset: number | undefined;
  limit: number | undefined;
  count: number | undefined;
  updateOffset: (number: number) => void;
}

export const SidebarFooter = ({
  offset,
  limit,
  count,
  updateOffset,
}: SidebarFooterProps) => {
  const styles = css`
    background: white;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;

    .count {
      display: flex;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      pointer-events: none;
      align-items: center;
      justify-content: center;
      font-size: 0.85rem;
      opacity: 0.7;
    }
  `;

  const handlePreviousPage = () => {
    if (typeof offset !== 'undefined') {
      if (offset > 0 && limit) {
        updateOffset && updateOffset(offset - limit);
      }
    }
  };

  const handleNextPage = () => {
    if (
      typeof count != 'undefined' &&
      typeof limit !== 'undefined' &&
      typeof offset !== 'undefined'
    ) {
      if (offset + limit < count) {
        updateOffset && updateOffset(offset + limit);
      }
    }
  };

  if (
    typeof count == 'undefined' ||
    typeof offset == 'undefined' ||
    typeof limit == 'undefined'
  ) {
    return <div></div>;
  }

  return (
    <div css={styles}>
      <Button
        onClick={handlePreviousPage}
        size="xs"
        variant="subtle"
        disabled={offset < limit}
      >
        <IconArrowLeft />
      </Button>
      <Button
        onClick={handleNextPage}
        size="xs"
        variant="subtle"
        disabled={offset + limit >= count}
      >
        <IconArrowRight />
      </Button>
      <div className="count">
        {offset + 1} - {offset + limit < count ? offset + limit : count} of{' '}
        {count} results
      </div>
    </div>
  );
};
