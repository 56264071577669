import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';

import { Form } from '@rr/ui';
import { transformEnum } from '@rr/utils';
import { useGetPlanQuery, useUpdatePlanMutation } from 'types/graphql';

interface PlanUpdateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const PlanUpdate = ({ opened, onClose }: PlanUpdateProps) => {
  const { planId }: { planId: string } = useParams();

  const { data } = useGetPlanQuery({
    variables: {
      id: planId,
    },
  });

  const [updatePlan] = useUpdatePlanMutation({ refetchQueries: ['GetPlan'] });

  const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Required'),
    lessonCount: Yup.number().required('Required'),
    lessonCountThreshold: Yup.number().required('Required'),
    lessonPrice: Yup.number().required('Required'),
    lessonDuration: Yup.number().required('Required'),
    active: Yup.boolean(),
  });

  const INITIAL_VALUES = {
    name: data?.plans_by_pk?.prettyName || '',
    lessonCount: data?.plans_by_pk?.lessonCount || 0,
    lessonCountThreshold: data?.plans_by_pk?.lessonCountThreshold || 0,
    lessonPrice: data?.plans_by_pk?.lessonPrice || 0,
    lessonDuration: data?.plans_by_pk?.lessonDuration || 20,
    active: false,
  };

  return (
    <Modal opened={opened} onClose={() => onClose(false)} title="Update plan">
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            updatePlan({
              variables: {
                id: planId,
                prettyName: values.name,
                name: transformEnum(values.name),
                lessonCount: values.lessonCount,
                lessonPrice: values.lessonPrice,
                lessonCountThreshold: values.lessonCountThreshold,
                lessonDuration: values.lessonDuration,
              },
            }).then(() => {
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="name"
              label="Name"
              placeholder="Plan name"
              fullWidth
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonCount"
              label="Lesson Count"
              placeholder="Lesson Count"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonCountThreshold"
              label="Lesson Threshold"
              placeholder="Lesson Threshold"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonPrice"
              label="Lesson Price"
              placeholder="Lesson Price"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="lessonDuration"
              label="Lesson Duration"
              placeholder="Lesson Duration"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Save plan' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
