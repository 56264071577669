import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Space } from '@mantine/core';
import * as Yup from 'yup';

import { Form } from '@rr/ui';
import * as ROUTES from 'config/routes';

import { GET_USER_QUERY } from './GetUser.query.gql';
import { UPDATE_USER_MUTATION } from './UpdateUser.mutation.gql';

import { GetUserQuery } from 'types/graphql';

interface UpdateUserParams {
  userId: string;
}

const VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required('Required.'),
  lastName: Yup.string().required('Required'),
  phone: Yup.string(),
});

const UserUpdate: React.FC = () => {
  const history = useHistory();
  const { userId } = useParams<UpdateUserParams>();

  const { loading, error, data } = useQuery<GetUserQuery>(GET_USER_QUERY, {
    variables: {
      uid: userId,
    },
  });

  const [
    updateUser,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: ['Users'],
  });

  if (loading || updateLoading) return <div>Loading...</div>;
  if (error || updateError) {
    console.error(error || updateError);
    return <div>Error</div>;
  }

  if (data || updateData) {
    console.log(data || updateData);
  }

  return (
    <div>
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          firstName: data?.users_by_pk?.first_name,
          lastName: data?.users_by_pk?.last_name,
          phone: data?.users_by_pk?.phone,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            updateUser({
              variables: {
                uid: userId,
                first_name: values.firstName,
                last_name: values.lastName,
                phone: values.phone,
              },
            }).then((response) => {
              history.push(ROUTES.USERS);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper className="wrapper">
            <Form.FieldNew
              type="text"
              name="firstName"
              label="First name"
              placeholder="First name"
              fullWidth
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="lastName"
              label="Last name"
              placeholder="Last name"
              fullWidth
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              fullWidth
              disabled={isSubmitting}
            />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Save user' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </div>
  );
};

export { UserUpdate };
