import { useState, useEffect, useContext, createContext } from 'react';
import { initializeApp } from 'firebase/app';
import {
  User,
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
  signInWithEmailLink,
  isSignInWithEmailLink,
  signOut,
  onAuthStateChanged,
  sendSignInLinkToEmail,
} from 'firebase/auth';
import { firebaseConfig } from '@rr/firebase';
import { HasuraRoles, getDecodedRoles } from '@rr/utils';

import {
  CreateAccountArguments,
  LoginArguments,
  UserContextProps,
  UserState,
} from './types';

export const fb = initializeApp(firebaseConfig);

const authContext = createContext<UserContextProps>({
  user: null,
  roles: null,
  login: () => {
    return null;
  },
  logout: () => {
    return null;
  },
  createAccount: () => {
    return null;
  },
});

export const Auth: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {auth.user === null ? <div></div> : children}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const [user, setUser] = useState<UserState | null>(null);
  const [roles, setRoles] = useState<HasuraRoles[] | null>(null);

  const getToken = async (userInstance: User) => {
    return userInstance ? userInstance.getIdToken(true) : undefined;
  };

  const createAccount = ({
    firstName,
    lastName,
    email,
    password,
  }: CreateAccountArguments) => {
    return createUserWithEmailAndPassword(getAuth(), email, password)
      .then((response) => {
        updateProfile(response.user, {
          displayName: `${firstName} ${lastName}`,
        }).catch(console.error);
      })
      .catch(console.error);
  };

  const login = ({ email }: LoginArguments) => {
    if (process.env.NX_ADMIN_VERIFICATION_URL) {
      return sendSignInLinkToEmail(getAuth(), email, {
        url: process.env.NX_ADMIN_VERIFICATION_URL,
        handleCodeInApp: true,
      });
    } else {
      return false;
    }
  };

  const logout = () => {
    return signOut(getAuth())
      .then(() => {
        window.location.href = '/login';
      })
      .catch(console.error);
  };

  const checkForSignIn = () => {
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email =
          window.prompt('Please provide your email for confirmation') || '';
      }
      signInWithEmailLink(getAuth(), email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          window.location.href = '/';
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    checkForSignIn();
    const unsubscribe = onAuthStateChanged(getAuth(), async (userInstance) => {
      if (userInstance) {
        const token = await getToken(userInstance);
        if (token) {
          localStorage.setItem('token', token);
          const hasuraRoles = getDecodedRoles(token);
          setRoles(hasuraRoles);
          setUser(userInstance);
          console.log(hasuraRoles);
          // Write some logic to check if the user has the correct roles
        } else {
          logout();
        }
      } else {
        localStorage.removeItem('token');
        setUser(false);
      }
    });
    return () => unsubscribe();
  }, [user]);

  return {
    user,
    roles,
    createAccount,
    login,
    logout,
  };
}
