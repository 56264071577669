import { css } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';
import { Loader } from '../../Loader';
import { useTable } from '../TableWrapper';

const TableBody: React.FC = () => {
  const theme = useMantineTheme();
  const { getTableBodyProps, data, rows, prepareRow } = useTable();
  const styles = css`
    height: calc(100% - 48px * 2);
    background: white;
    overflow-y: auto;

    .TableCell {
      padding: 16px;

      a {
        color: inherit;
      }
    }

    .TableRow:nth-of-type(odd) {
      .TableCell {
        background: ${theme.colors.gray[0]};
      }
    }

    .loading {
      height: calc(100% - 48px * 2);
      background: white;
      overflow-y: auto;
    }
  `;

  return (
    <div css={styles} {...(getTableBodyProps && getTableBodyProps())}>
      {!data || !data.length ? (
        <div className="Loading">
          <Loader background="#FFF" color="#CCC" />
        </div>
      ) : (
        rows &&
        rows.map((row) => {
          prepareRow && prepareRow(row);
          return (
            <div className="TableRow" {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <div className="TableCell" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </div>
              ))}
            </div>
          );
        })
      )}
    </div>
  );
};

export { TableBody };
