import { gql } from '@apollo/client';

export const GET_STUDENT_TEACHERS_QUERY = gql`
  query GetStudentDetailTeachers($id: uuid!, $limit: Int!, $offset: Int!) {
    students_by_pk(id: $id) {
      id
      student_teachers(limit: $limit, offset: $offset) {
        user {
          id
          gdpr_name
          teacher_instruments {
            instrument {
              prettyName
            }
          }
          teacher_locations {
            location {
              name
            }
          }
        }
      }
      student_teachers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
