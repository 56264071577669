import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar, SplitLayout, SplitContainer, ZeroDetail } from '@rr/ui';
import * as ROUTES from 'config/routes';

import { StudentList } from './StudentList';
import { StudentDetail } from './StudentDetail';
import { StudentCreate } from './StudentCreate';

const Students = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastStudent = localStorage.getItem('lastStudent');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <StudentList handleCreate={setCreateModalOpen} />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route path={ROUTES.STUDENTS_UPDATE} component={StudentDetail} />
          {lastStudent && <Redirect to={`/students/${lastStudent}`} />}
          <Route>
            <ZeroDetail title="No student selected">
              Please select one
            </ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
      <StudentCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </SplitLayout>
  );
};

export { Students };
