import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Badge } from '@mantine/core';

import { MiniTable } from '@rr/ui';

import { GET_LOCATION_STUDENTS_QUERY } from './GetLocationStudents.query.gql';
import { GetLocationStudentsQuery, Students } from 'types/graphql';
import { IconUserExclamation } from '@tabler/icons';

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Students;
    };
  };
}

interface TeacherNameCell {
  value: string;
  cell: {
    row: {
      original: {
        teacherId: string;
      };
    };
  };
}

interface InstrumentNameCell {
  value: string;
  cell: {
    row: {
      original: {
        instrumentId: string;
      };
    };
  };
}

export const LocationStudents = () => {
  const { locationId }: { locationId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [locationId]);

  const updateOffset = (number: number) => setOffset(number);

  const { error, data } = useQuery<GetLocationStudentsQuery>(
    GET_LOCATION_STUDENTS_QUERY,
    {
      variables: {
        id: locationId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const STUDENT_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, cell }: NameCell) => (
        <Link to={`/students/${cell.row.original.id}`}>{value}</Link>
      ),
    },
    {
      Header: 'Teacher',
      accessor: 'teacher',
      Cell: ({ value, cell }: TeacherNameCell) =>
        value ? (
          <Link to={`/teachers/${cell.row.original.teacherId}`}>{value}</Link>
        ) : (
          <Badge color="red" variant="filled">
            teacher missing
          </Badge>
        ),
    },
    {
      Header: 'Instrument',
      accessor: 'instrument',
      Cell: ({ value, cell }: InstrumentNameCell) =>
        value ? (
          <Link to={`/instruments/${cell.row.original.instrumentId}`}>
            {value}
          </Link>
        ) : (
          <Badge color="red" variant="filled">
            instrument missing
          </Badge>
        ),
    },
  ];

  const studentData =
    data?.locations_by_pk?.student_locations.map(({ student }) => ({
      id: student.id,
      name: student.gdpr_name,
      instrument: student.student_instruments[0]?.instrument.prettyName || '',
      teacher: student.student_teachers[0]?.user.gdpr_name || null,
      teacherId: student.student_teachers[0]?.user.id || null,
    })) || [];

  return studentData.length ? (
    <MiniTable.Wrapper
      columns={STUDENT_COLUMNS}
      offset={offset}
      count={
        data?.locations_by_pk?.student_locations_aggregate.aggregate?.count
      }
      limit={LIMIT}
      updateOffset={updateOffset}
      data={studentData}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Students</MiniTable.Zero>
  );
};
