import { css } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';

export const MiniTableZero: React.FC = ({ children }) => {
  const theme = useMantineTheme();

  const styles = css`
    background: ${theme.colors.gray[0]};
    border: 1px solid ${theme.colors.gray[2]};
    color: ${theme.colors.gray[6]};
    text-align: center;
    padding: 24px;
    font-weight: 500;
    border-radius: 5px;
  `;

  return <div css={styles}>{children}</div>;
};
