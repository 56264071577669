import { Fragment, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import * as ROUTES from 'config/routes';

import { LessonOverview } from './LessonOverview';
import { LessonCreate } from './LessonCreate';

const Lessons = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastLesson = localStorage.getItem('lastLesson');

  return (
    <Fragment>
      <Route path={ROUTES.LESSONS}>
        <LessonOverview handleCreate={setCreateModalOpen} />
      </Route>
      <Switch>
        <Route path={ROUTES.LESSONS_UPDATE}>UPDATE LESSON</Route>
      </Switch>
      <LessonCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </Fragment>
  );
};

export { Lessons };
