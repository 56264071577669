import { Fragment, useState } from 'react';
import { Link, Route } from 'react-router-dom';
import * as ROUTES from 'config/routes';
import { Button } from '@mantine/core';
import { PlusIcon } from '@primer/octicons-react';

import { Table, TableSortPropertyState } from '@rr/ui';
import { useUsersQuery } from 'types/graphql';

const LIMIT = 20;
const UserOverview = () => {
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<TableSortPropertyState>({
    first_name: 'asc',
  });

  const { error, data } = useUsersQuery({
    variables: {
      offset,
      limit: LIMIT,
      order_by: { ...orderBy },
    },
  });

  const users = data?.users || [];

  const columns = [
    {
      Header: 'First name',
      accessor: 'first_name',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) => {
        return (
          <div>
            <Link to={`/users/${cell.row.original.id}`}>{value}</Link>
          </div>
        );
      },
      sortable: true,
    },
    {
      Header: 'Last name',
      accessor: 'last_name',
      sortable: true,
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
  ];

  if (error) {
    console.error(error);
    return <div>Error! Please check the console.</div>;
  }

  const updateOffset = (number: number) => setOffset(number);

  const handleSort = (id: string) => {
    if (Object.keys(orderBy).includes(id)) {
      setOrderBy({
        [id]: orderBy[id] === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setOrderBy({
        [id]: 'asc',
      });
    }
  };

  return (
    <Fragment>
      <Table.Wrapper
        data={users}
        columns={columns}
        offset={offset}
        limit={LIMIT}
        count={data?.users_aggregate.aggregate?.count || 0}
        updateOffset={updateOffset}
        handleSort={handleSort}
        activeSortProperty={orderBy}
      >
        <Table.Actions>
          <Button
            leftIcon={<PlusIcon />}
            component={Link}
            variant="white"
            to={ROUTES.USERS_CREATE}
          >
            Create user
          </Button>
        </Table.Actions>
        <Table.Header />
        <Table.Body />
        <Table.Pagination />
      </Table.Wrapper>
      <Route path={ROUTES.USERS_UPDATE}>Update!</Route>
    </Fragment>
  );
};

export { UserOverview };
