import { css } from '@emotion/react';

interface ZeroDetailProps {
  title?: string;
}

export const ZeroDetail: React.FC<ZeroDetailProps> = ({ title, children }) => {
  const styles = css`
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 108px);

    .wrap {
    }

    .title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .subtext {
      font-size: 1.2rem;
      opacity: 0.8;
    }
  `;
  return (
    <div css={styles}>
      <div className="wrap">
        {title && <div className="title t1">{title}</div>}
        {children && <div className="subtext">{children}</div>}
      </div>
    </div>
  );
};
