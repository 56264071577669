import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MiniTable } from '@rr/ui';

import { GET_TEACHER_STUDENTS_QUERY } from './GetTeacherStudents.query.gql';
import { GetTeacherStudentsQuery, Students } from 'types/graphql';

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Students;
    };
  };
}

export const TeacherStudents = () => {
  const { userId: teacherId }: { userId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [teacherId]);

  const updateOffset = (number: number) => setOffset(number);

  const { loading, error, data, called } = useQuery<GetTeacherStudentsQuery>(
    GET_TEACHER_STUDENTS_QUERY,
    {
      variables: {
        uid: teacherId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const STUDENT_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, cell }: NameCell) => (
        <Link to={`/students/${cell.row.original.id}`}>{value}</Link>
      ),
    },
    {
      Header: 'Instrument',
      accessor: 'instrument',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
  ];

  const studentData =
    data?.users_by_pk?.student_teachers.map(({ student }) => ({
      id: student.id,
      name: student.gdpr_name,
      instrument: student.student_instruments[0]?.instrument.prettyName || (
        <div>Missing instrument</div>
      ),
      location: student.student_locations[0]?.location.name || '',
    })) || [];

  if (loading && called) {
    return <div>Loading...</div>;
  }

  return studentData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={STUDENT_COLUMNS}
      offset={offset}
      count={data?.users_by_pk?.student_teachers_aggregate.aggregate?.count}
      limit={LIMIT}
      updateOffset={updateOffset}
      data={studentData}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Students</MiniTable.Zero>
  );
};
