import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { Anchor, Button, useMantineTheme, Divider } from '@mantine/core';

export interface NavigationLink {
  to: string;
  label: string;
  exact?: boolean;
  admin?: boolean;
}

interface NavigationProps {
  items: NavigationLink[];
}

const isAdminEnvironment = (() => {
  return process.env.NX_ADMIN_URL === 'http://localhost:4200';
})();

const Navigation = ({ items }: NavigationProps) => {
  const theme = useMantineTheme();
  const styles = css`
    margin-left: 24px;

    ul {
      display: flex;

      li {
        a {
          &.active {
            color: ${theme.colors.blue[9]};
          }
        }
      }
    }
  `;

  return (
    <nav css={styles}>
      <ul>
        {isAdminEnvironment
          ? items.map(({ to, label, exact, admin }: NavigationLink) => (
              <li key={`link-${to}`}>
                <Button
                  color={admin ? 'red' : 'gray'}
                  variant="white"
                  component={NavLink}
                  to={to}
                  exact={exact}
                >
                  {label}
                </Button>
              </li>
            ))
          : items
              .filter(({ admin }) => !admin)
              .map(({ to, label, exact, admin }: NavigationLink) => (
                <li key={`link-${to}`}>
                  <Button
                    color="gray"
                    variant="white"
                    component={NavLink}
                    to={to}
                    exact={exact}
                  >
                    {label}
                  </Button>
                </li>
              ))}
      </ul>
    </nav>
  );
};

export { Navigation };
