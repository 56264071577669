import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar, SplitLayout, SplitContainer, ZeroDetail } from '@rr/ui';
import * as ROUTES from 'config/routes';

import { InstrumentCreate } from './InstrumentCreate';
import { InstrumentDetail } from './InstrumentDetail';
import { InstrumentList } from './InstrumentList';

const Instruments = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastInstrument = localStorage.getItem('lastInstrument');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <InstrumentList handleCreate={setCreateModalOpen} />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route
            path={ROUTES.INSTRUMENTS_UPDATE}
            component={InstrumentDetail}
          />
          {lastInstrument && <Redirect to={`/instruments/${lastInstrument}`} />}
          <Route>
            <ZeroDetail title="No instrument selected">
              Please select one
            </ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
      <InstrumentCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </SplitLayout>
  );
};

export { Instruments };
