import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';

import { MiniTable } from '@rr/ui';
import { GET_STUDENT_LOCATIONS_QUERY } from './GetStudentLocations.query.gql';
import { GetStudentLocationsQuery, Locations } from 'types/graphql';

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Locations;
    };
  };
}

export const StudentLocations = () => {
  const { studentId }: { studentId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [studentId]);

  const updateOffset = (number: number) => setOffset(number);

  const { loading, error, data } = useQuery<GetStudentLocationsQuery>(
    GET_STUDENT_LOCATIONS_QUERY,
    {
      variables: {
        id: studentId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const LOCATION_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, cell }: NameCell) => (
        <div>
          <Link to={`/locations/${cell.row.original.id}`}>{value}</Link>
        </div>
      ),
    },
  ];

  const locationData =
    data?.students_by_pk?.student_locations.map(
      (location) => location.location
    ) || [];

  return !loading && locationData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={LOCATION_COLUMNS}
      data={locationData}
      offset={offset}
      count={data?.students_by_pk?.student_locations_aggregate.aggregate?.count}
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Locations</MiniTable.Zero>
  );
};
