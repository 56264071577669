import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form } from '@rr/ui';
import {
  AutocompleteGuardian,
  AutocompleteInstrument,
  AutocompleteLocation,
  AutocompleteTeacher,
} from 'components';

import { STUDENT_CREATE_MUTATION } from './StudentCreate.mutation.gql';
import { StudentCreateDocument } from 'types/graphql';

interface StudentCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const StudentCreate = ({ opened, onClose }: StudentCreateProps) => {
  const history = useHistory();
  const [createStudent, { data, loading, error }] = useMutation(
    STUDENT_CREATE_MUTATION,
    {
      refetchQueries: ['Students'],
    }
  );

  const VALIDATION_SCHEMA = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    notes: Yup.string(),
    instruments: Yup.array().required('Required'),
    teachers: Yup.array(),
    locations: Yup.array(),
    guardians: Yup.array(),
  });

  const INITIAL_VALUES = {
    firstName: '',
    lastName: '',
    notes: '',
    instruments: [],
    teachers: [],
    locations: [],
    guardians: [],
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create a student"
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            console.log(values);
            setSubmitting(false);
            createStudent({
              variables: {
                firstName: values.firstName,
                lastName: values.lastName,
                notes: values.notes,
                instrumentIds: values.instruments.map((instrument) => ({
                  instrument_id: instrument,
                })),
                teacherIds: values.teachers.map((teacher) => ({
                  teacher_id: teacher,
                })),
                locationIds: values.locations.map((location) => ({
                  location_id: location,
                })),
                guardianIds: values.guardians.map((guardian) => ({
                  user_id: guardian,
                })),
              },
            }).then((response) => {
              const { id } = response.data.insert_students_one;
              history.push(`/students/${id}`);
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="firstName"
              label="First name"
              placeholder="First name"
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="lastName"
              label="Last name"
              placeholder="Last name"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <AutocompleteGuardian label="Guardians" name="guardians" />
            <Space h="md" />
            <AutocompleteInstrument
              multiple
              label="Instruments"
              name="instruments"
            />
            <Space h="md" />
            <AutocompleteLocation multiple label="Locations" name="locations" />
            <Space h="md" />
            <AutocompleteTeacher multiple label="Teachers" name="teachers" />
            <Space h="md" />
            <Form.FieldNew
              type="textarea"
              name="notes"
              label="Notes"
              placeholder="Notes"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create student' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
