import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { Anchor } from '@mantine/core';

interface LogoProps {
  to?: string;
}

const Logo = ({ to }: LogoProps) => {
  const styles = css`
    font-size: 1.3rem;

    a {
      text-decoration: none;
      font-weight: 600;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;

      &.active {
        text-decoration: underline;
      }
    }
  `;

  return (
    <div css={styles} className="t1">
      <Anchor component={NavLink} to={to || '/'}>
        LessonTime
      </Anchor>
    </div>
  );
};

export { Logo };
