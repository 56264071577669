import { gql } from '@apollo/client';

export const GET_SOURCE_LESSONS_QUERY = gql`
  query GetSourceLessons($teacher_id: String!, $date: String!) {
    lessons(
      where: { _and: { teacher_id: { _eq: $teacher_id }, dmy: { _eq: $date } } }
    ) {
      dmy
      start_time
      end_time
      teacher_id
      student_id
      location_id
      instrument_id
    }
  }
`;
