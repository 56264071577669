import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form } from '@rr/ui';
import { fb } from '@rr/auth-context';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
  AutocompleteGuardian,
  AutocompleteInstrument,
  AutocompleteLocation,
  AutocompleteTeacher,
} from 'components';

import { GUARDIAN_CREATE_MUTATION } from './GuardianCreate.mutation.gql';

interface GuardianCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

const functions = getFunctions(fb, 'europe-west2');

export const GuardianCreate = ({ opened, onClose }: GuardianCreateProps) => {
  const history = useHistory();
  const [createGuardian, { data, error, loading }] = useMutation(
    GUARDIAN_CREATE_MUTATION,
    {
      refetchQueries: ['Guardians'],
    }
  );

  const VALIDATION_SCHEMA = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phone: Yup.string().required('Required'),
  });

  const INITIAL_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create a guardian"
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            console.log(values);

            const userCreate = httpsCallable(functions, 'userCreate');
            userCreate({
              displayName: values.firstName + ' ' + values.lastName,
              email: values.email,
            })
              .then(({ data }) => {
                // @ts-expect-error untyped
                const id = data.uid;

                createGuardian({
                  variables: {
                    id: id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phone: values.phone.toString(),
                  },
                })
                  .then(() => {
                    history.push('/guardians');
                    onClose(false);
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="firstName"
              label="First name"
              placeholder="First name"
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="lastName"
              label="Last name"
              placeholder="Last name"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="email"
              name="email"
              label="Email"
              placeholder="Email"
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="number"
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create guardian' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
