import { css } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';

const Header: React.FC = ({ children }) => {
  const theme = useMantineTheme();
  const styles = css`
    height: 64px;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 24px 0 0;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
    z-index: 2;
    position: relative;
  `;

  return <header css={styles}>{children}</header>;
};

export { Header };
