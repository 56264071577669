import { Route, Redirect } from 'react-router-dom';

import { Loader } from '@rr/ui';
import { useAuth } from '@rr/auth-context';

import * as ROUTES from 'config/routes';

interface PrivateRouteProps {
  component: React.FC;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  path,
  exact,
}) => {
  const { user } = useAuth();

  if (user === null) return <Loader />;

  return user ? (
    <Route path={path} component={component} exact={exact} />
  ) : (
    <Redirect to={ROUTES.LOGIN} />
  );
};

export { PrivateRoute };
