import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MiniTable } from '@rr/ui';

import { GET_LOCATION_TEACHERS_QUERY } from './GetLocationTeachers.query.gql';
import { GetLocationTeachersQuery, Users } from 'types/graphql';

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Users;
    };
  };
}

export const LocationTeachers = () => {
  const { locationId }: { locationId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [locationId]);

  const updateOffset = (number: number) => setOffset(number);

  const { error, data } = useQuery<GetLocationTeachersQuery>(
    GET_LOCATION_TEACHERS_QUERY,
    {
      variables: {
        id: locationId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const TEACHER_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, cell }: NameCell) => (
        <Link to={`/teachers/${cell.row.original.id}`}>{value}</Link>
      ),
    },
    {
      Header: 'Instrument',
      accessor: 'instrument',
    },
  ];

  const teacherData =
    data?.locations_by_pk?.teacher_locations.map(({ user }) => ({
      id: user.id,
      name: user.gdpr_name,
      instrument: user.teacher_instruments[0].instrument.prettyName,
    })) || [];

  return teacherData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={TEACHER_COLUMNS}
      offset={offset}
      count={
        data?.locations_by_pk?.teacher_locations_aggregate.aggregate?.count
      }
      limit={LIMIT}
      updateOffset={updateOffset}
      data={teacherData}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Teachers</MiniTable.Zero>
  );
};
