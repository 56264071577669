import { gql } from '@apollo/client';

export const CREATE_INVOICE_MUTATION = gql`
  mutation CreateInvoice(
    $student: uuid!
    $teacher: String!
    $location: uuid!
    $issuedAt: timestamptz!
    $dueAt: timestamptz!
    $amount: Int
    $type: String!
  ) {
    insert_invoices_one(
      object: {
        student_id: $student
        teacher_id: $teacher
        location_id: $location
        issued_at: $issuedAt
        due_at: $dueAt
        amount: $amount
        type: $type
      }
    ) {
      id
    }
  }
`;
