import { css } from '@emotion/react';

interface LoaderProps {
  background?: string;
  color?: string;
}

const Loader = ({ background = '#CCC', color = '#FFF' }: LoaderProps) => {
  const styles = css`
    background: ${background};
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes loaderAnimation {
      0% {
        transform: translateY(0px);
      }

      50% {
        transform: translateY(16px);
      }

      100% {
        transform: translateY(0px);
      }
    }

    .dot {
      background: ${color};
      box-shadow: 0 5px 10px -1px rgba(0, 0, 0, 0.1);
      height: 12px;
      width: 12px;
      margin: 8px;
      border-radius: 12px;
      animation: loaderAnimation 3s infinite;

      &:nth-of-type(1) {
        animation-delay: 0.25s;
      }
      &:nth-of-type(2) {
        animation-delay: 0.5s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.75s;
      }
      &:nth-of-type(4) {
        animation-delay: 1s;
      }
      &:nth-of-type(5) {
        animation-delay: 1.25s;
      }
    }
  `;

  return (
    <div css={styles}>
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  );
};

export { Loader };
