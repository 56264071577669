import { gql } from '@apollo/client';

export const GET_STUDENT_INSTRUMENTS_QUERY = gql`
  query GetStudentsAndInstruments($instrumentId: uuid!) {
    students {
      id
      first_name
      last_name
    }
    instruments_by_pk(id: $instrumentId) {
      student_instruments {
        student_id
      }
    }
  }
`;
