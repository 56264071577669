import { gql } from '@apollo/client';

export const CREATE_INSTRUMENT = gql`
  mutation CreateInstrument($name: String!, $prettyName: String!) {
    insert_instruments_one(object: { name: $name, prettyName: $prettyName }) {
      id
      name
      prettyName
    }
  }
`;
