import { css } from '@emotion/react';
import { useHistory } from 'react-router';
import { IconX } from '@tabler/icons';
import { useMantineTheme } from '@mantine/core';

interface SidePanelProps {
  title?: string;
  returnPath?: string;
  width?: string;
}

const SidePanel: React.FC<SidePanelProps> = ({
  children,
  title,
  returnPath,
  width,
}) => {
  const theme = useMantineTheme();
  const styles = css`
    .PanelOverlay {
      background: ${theme.colors.gray[9]};
      opacity: 0.4;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .PanelContent {
      background: ${theme.colors.gray[1]};
      position: fixed;
      top: 0;
      right: 0;
      width: ${width || '400px'};
      height: 100vh;

      .PanelContentHeader {
        background: ${theme.colors.blue[6]};
        color: white;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
        font-weight: 600;

        button {
          appearance: none;
          background: rgba(255, 255, 255, 0.05);
          color: white;
          height: 56px;
          width: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          font-size: 1.4em;
        }
      }

      .PanelContentBody {
        padding: 16px;
        height: 100%;

        .wrapper {
          display: flex;
          flex-direction: column;
          height: calc(100vh - 88px);
        }

        .Button {
          margin-top: auto;
        }

        .Field {
          margin-bottom: 16px;
        }
      }
    }
  `;

  const history = useHistory();

  const handleClick = () => {
    returnPath ? history.push(returnPath) : history.goBack();
  };

  return (
    <div css={styles}>
      <div onClick={handleClick} className="PanelOverlay" />
      <div className="PanelContent">
        <div className="PanelContentHeader">
          <div className="title">{title}</div>
          <button onClick={handleClick}>
            <IconX />
          </button>
        </div>
        <div className="PanelContentBody">{children}</div>
      </div>
    </div>
  );
};
export { SidePanel };
