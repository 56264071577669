import { css } from '@emotion/react';
import { useContext, createContext, useMemo } from 'react';
import {
  Column,
  useTable as useTableHook,
  useFlexLayout,
  TableInstance,
} from 'react-table';

const tableContext = createContext({});

interface ITableContext extends TableInstance {
  offset: number;
  limit: number;
  updateOffset: (number: number) => void;
  count?: number;
  handleSort: (id: string) => void;
  activeSortProperty?: {
    [property: string]: 'asc' | 'desc';
  };
}

export const useTable = () => useContext<Partial<ITableContext>>(tableContext);

interface TableWrapperProps {
  data: Record<string, unknown>[];
  columns: Column<Record<string, unknown>>[];
  handleSort?: (id: string) => void;
  activeSortProperty?: {
    [property: string]: 'asc' | 'desc';
  };
  offset: number;
  limit: number;
  updateOffset: (number: number) => void;
  count?: number;
}

const styles = css`
  height: calc(100vh - 64px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableWrapper: React.FC<TableWrapperProps> = ({
  children,
  data,
  columns,
  offset,
  limit,
  count,
  updateOffset,
  handleSort,
  activeSortProperty,
}) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const value = useTableHook(
    { data: memoizedData, columns: memoizedColumns },
    useFlexLayout
  );

  return (
    <tableContext.Provider
      value={{
        ...value,
        offset,
        limit,
        count,
        updateOffset,
        handleSort,
        activeSortProperty,
      }}
    >
      <div css={styles}>{value === null ? 'Loading...' : children}</div>
    </tableContext.Provider>
  );
};
