import { gql } from '@apollo/client';

export const GET_TEACHER_QUERY = gql`
  query GetTeacher($uid: String!) {
    users_by_pk(id: $uid) {
      id
      email
      first_name
      last_name
      full_name
      title
      phone
      teacher_instruments {
        instrument {
          id
          name
          prettyName
        }
      }
      teacher_locations {
        location {
          id
          name
        }
      }
    }
  }
`;
