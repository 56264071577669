import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import decode from 'jwt-decode';

const ENDPOINT = process.env.NX_GRAPHQL_ENDPOINT;

const httpLink = createHttpLink({
  uri: ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token') as string;
  const decoded = decode(token);
  // @ts-expect-error stupid
  const claimsHeaders = decoded['https://hasura.io/jwt/claims'];

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      ...claimsHeaders,
    },
  };
});

export const createApolloClient = () =>
  new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
