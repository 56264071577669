import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Modal, Space } from '@mantine/core';

import { TEACHER_UPDATE_MUTATION } from './TeacherUpdate.mutation.gql';
import { GET_TEACHER_QUERY } from './GetTeacher.query.gql';

import { Form } from '@rr/ui';

import { AutocompleteInstrument, AutocompleteLocation } from 'components';
import { GetTeacherQuery } from 'types/graphql';

interface ModalProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const TeacherUpdate = ({ opened, onClose }: ModalProps) => {
  const { userId }: { userId: string } = useParams();

  const { loading, error, data } = useQuery<GetTeacherQuery>(
    GET_TEACHER_QUERY,
    {
      variables: {
        uid: userId,
      },
    }
  );

  console.log(data);

  const [updateTeacher] = useMutation(TEACHER_UPDATE_MUTATION, {
    refetchQueries: [
      'GetUser',
      'GetTeacherInfo',
      'GetTeacherStudents',
      'GetTeacherInstruments',
      'GetTeacherLocations',
    ],
  });

  const VALIDATION_SCHEMA = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string(),
    instruments: Yup.array().required('Required'),
    locations: Yup.array().required('Required'),
  });

  const INITIAL_VALUES = {
    firstName: data?.users_by_pk?.first_name || '',
    phone: data?.users_by_pk?.phone || '',
    lastName: data?.users_by_pk?.last_name || '',
    instruments:
      data?.users_by_pk?.teacher_instruments.map(
        ({ instrument }) => instrument.id
      ) || [],
    locations:
      data?.users_by_pk?.teacher_locations.map(
        ({ location }: { location: any }) => location.id
      ) || [],
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title={`Edit teacher: ${data?.users_by_pk?.first_name} ${data?.users_by_pk?.last_name}`}
      size="lg"
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            updateTeacher({
              variables: {
                uid: userId,
                first_name: values.firstName,
                last_name: values.lastName,
                phone: values.phone,
                instrumentIds: values.instruments.map((instrument: any) => ({
                  instrument_id: instrument,
                  teacher_id: userId,
                })),
                locationIds: values.locations.map((location: any) => ({
                  location_id: location,
                  teacher_id: userId,
                })),
              },
            })
              .then(() => onClose(false))
              .catch(console.error);
          }, 1000);
        }}
      >
        {({ values, isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="firstName"
              label="First name"
              placeholder="First name"
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="lastName"
              label="Last name"
              placeholder="Last name"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <AutocompleteInstrument
              multiple
              label="Instruments"
              name="instruments"
            />
            <Space h="md" />
            <AutocompleteLocation multiple label="Locations" name="locations" />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Save teacher' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
