import { gql } from '@apollo/client';

export const GET_LOCATION_TEACHERS_QUERY = gql`
  query GetLocationTeachers($id: uuid!, $limit: Int!, $offset: Int!) {
    locations_by_pk(id: $id) {
      id
      teacher_locations(limit: $limit, offset: $offset) {
        user {
          id
          gdpr_name
          teacher_instruments {
            instrument {
              prettyName
            }
          }
        }
      }
      teacher_locations_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
