import { gql } from '@apollo/client';

export const GET_TEACHER_LESSONS_QUERY = gql`
  query GetTeacherLessons($uid: String!, $limit: Int!, $offset: Int!) {
    users_by_pk(id: $uid) {
      id
      lessons(limit: $limit, offset: $offset) {
        start_time
        end_time
        status
        student {
          id
          gdpr_name
        }
        location {
          name
        }
      }
      lessons_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
