import { gql } from '@apollo/client';

export const DELETE_STUDENT_MUTATION = gql`
  mutation DeleteStudent($id: uuid!) {
    delete_students(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
