import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SidePanel } from '@rr/ui';
import * as ROUTES from 'config/routes';

import { UserOverview } from './UserOverview';
import { UserUpdate } from './UserUpdate';

const Users = () => (
  <Fragment>
    <Route path={ROUTES.USERS} component={UserOverview} />
    <Switch>
      <Route path={ROUTES.USERS_CREATE}>
        <SidePanel returnPath={ROUTES.USERS} title="Create a User">
          {/* <UserCreate /> */}
          Coming soon...
        </SidePanel>
      </Route>
      <Route path={ROUTES.USERS_UPDATE}>
        <SidePanel returnPath={ROUTES.USERS} title="Update user">
          <UserUpdate />
        </SidePanel>
      </Route>
    </Switch>
  </Fragment>
);

export { Users };
