import { MantineColor } from '@mantine/core';
const colors: MantineColor[] = [
  'blue',
  'cyan',
  'grape',
  'green',
  'indigo',
  'lime',
  'orange',
  'pink',
  'red',
  'teal',
  'violet',
  'yellow',
];

export const getRandomThemeColor = () =>
  colors[Math.floor(Math.random() * colors.length)];
