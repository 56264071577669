import { gql } from '@apollo/client';

export const GET_PLAN_LESSONS_QUERY = gql`
  query GetPlanLessons($id: uuid!, $limit: Int!, $offset: Int!) {
    plans_by_pk(id: $id) {
      id
      lessons(limit: $limit, offset: $offset) {
        id
        start_time
        end_time
        student {
          id
          gdpr_name
        }
        user {
          id
          gdpr_name
        }
        location {
          id
          name
        }
      }
      lessons_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
