import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';

import { MiniTable } from '@rr/ui';
import { GET_TEACHER_LOCATIONS_QUERY } from './GetTeacherLocations.query.gql';
import { GetTeacherLocationsQuery } from 'types/graphql';

export const TeacherLocations = () => {
  const { userId: teacherId }: { userId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [teacherId]);

  const updateOffset = (number: number) => setOffset(number);

  const { loading, error, data } = useQuery<GetTeacherLocationsQuery>(
    GET_TEACHER_LOCATIONS_QUERY,
    {
      variables: {
        uid: teacherId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const LOCATION_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
      // @ts-expect-error fixme
      Cell: ({ value, cell }) => (
        <div>
          <Link to={`/locations/${cell.row.original.id}`}>{value}</Link>
        </div>
      ),
    },
  ];

  const locationData =
    data?.users_by_pk?.teacher_locations.map((location) => location.location) ||
    [];

  return !loading && locationData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={LOCATION_COLUMNS}
      data={locationData}
      offset={offset}
      count={data?.users_by_pk?.teacher_locations_aggregate.aggregate?.count}
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No Locations</MiniTable.Zero>
  );
};
