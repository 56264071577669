import { css } from '@emotion/react';
import { useSearchTable } from '../SearchTableWrapper';
const SearchTableBody: React.FC = () => {
  const table = useSearchTable();
  const styles = css`
    height: calc(100% - 48px * 2);
    background: white;
    overflow-y: auto;

    .TableCell {
      padding: 16px;
    }

    .TableRow:nth-of-type(odd) {
      .TableCell {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  `;
  return (
    <div css={styles} className="TableBody" {...table?.getTableBodyProps()}>
      {table?.rows.map((row) => {
        table.prepareRow(row);
        return (
          <div className="TableRow" {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return (
                <div className="TableCell" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export { SearchTableBody };
