import { css } from '@emotion/react';
import { Field as FormikField, ErrorMessage } from 'formik';

interface CheckboxProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  fullWidth,
  disabled,
}) => {
  const styles = css`
    background: orange;

    .field {
      display: flex;
      align-items: center;
    }
  `;

  return (
    <div className="Field" css={styles}>
      <div className="field">
        <FormikField
          id={name}
          disabled={disabled}
          type="checkbox"
          name={name}
        />
        {label && (
          <label htmlFor={name} className="label">
            {label}
          </label>
        )}
      </div>
      <ErrorMessage name={name} component="div" />
    </div>
  );
};

export { Checkbox };
