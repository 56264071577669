import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Modal, Space } from '@mantine/core';

import { STUDENT_UPDATE_MUTATION } from './StudentUpdate.mutation.gql';
import { GET_STUDENT_QUERY } from '../GetStudent.query.gql';
import { GetStudentInfoQuery, UpdateStudentMutation } from 'types/graphql';

import { Form } from '@rr/ui';

import {
  AutocompleteInstrument,
  AutocompleteGuardian,
  AutocompleteLocation,
  AutocompleteTeacher,
} from 'components';

// TODO Extract to shared-types NX lib
interface ModalProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const StudentUpdate = ({ opened, onClose }: ModalProps) => {
  const { studentId }: { studentId: string } = useParams();

  const { loading, error, data } = useQuery<GetStudentInfoQuery>(
    GET_STUDENT_QUERY,
    {
      variables: {
        id: studentId,
      },
    }
  );

  const [updateStudent] = useMutation<UpdateStudentMutation>(
    STUDENT_UPDATE_MUTATION,
    {
      refetchQueries: [
        'GetStudentInfo',
        'GetStudentTeachers',
        'GetStudentInstruments',
        'GetStudentLocations',
      ],
    }
  );

  const VALIDATION_SCHEMA = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    notes: Yup.string(),
    instruments: Yup.array().required('Required'),
    teachers: Yup.array().required('Required'),
    locations: Yup.array().required('Required'),
    guardians: Yup.array().required('Required'),
  });

  const INITIAL_VALUES = {
    firstName: data?.students_by_pk?.first_name || '',
    lastName: data?.students_by_pk?.last_name || '',
    notes: data?.students_by_pk?.notes || '',
    instruments:
      data?.students_by_pk?.student_instruments.map(
        ({ instrument }) => instrument.id
      ) || [],
    teachers:
      data?.students_by_pk?.student_teachers.map(({ user }) => user.id) || [],
    locations:
      data?.students_by_pk?.student_locations.map(
        ({ location }) => location.id
      ) || [],
    guardians:
      data?.students_by_pk?.student_users.map(({ user }) => user.id) || [],
  };

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title={`Edit student: ${data?.students_by_pk?.first_name} ${data?.students_by_pk?.last_name}`}
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            updateStudent({
              variables: {
                id: studentId,
                first_name: values.firstName,
                last_name: values.lastName,
                notes: values.notes,
                instrumentIds: values.instruments.map((instrument) => ({
                  student_id: studentId,
                  instrument_id: instrument,
                })),
                teacherIds: values.teachers.map((teacher) => ({
                  student_id: studentId,
                  teacher_id: teacher,
                })),
                locationIds: values.locations.map((location) => ({
                  student_id: studentId,
                  location_id: location,
                })),
                guardianIds: values.guardians.map((guardian) => ({
                  student_id: studentId,
                  user_id: guardian,
                })),
              },
            }).then(() => {
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ values, isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.FieldNew
              type="text"
              name="firstName"
              label="First name"
              placeholder="First name"
              disabled={isSubmitting}
              autoFocus
            />
            <Space h="md" />
            <Form.FieldNew
              type="text"
              name="lastName"
              label="Last name"
              placeholder="Last name"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <AutocompleteInstrument
              multiple
              label="Instruments"
              name="instruments"
            />
            <Space h="md" />
            <AutocompleteTeacher multiple label="Teachers" name="teachers" />
            <Space h="md" />
            <AutocompleteLocation multiple label="Locations" name="locations" />
            <Space h="md" />
            <AutocompleteGuardian label="Guardians" name="guardians" />
            <Space h="md" />
            <Form.FieldNew
              type="textarea"
              name="notes"
              label="Notes"
              placeholder="Notes"
              disabled={isSubmitting}
            />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Save student' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
