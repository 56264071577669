import { SidebarContainer } from './SidebarContainer';
import { SidebarHeader } from './SidebarHeader';
import { SidebarContent } from './SidebarContent';
import { SidebarFooter } from './SidebarFooter';
import { SidebarItem } from './SidebarItem';

export const Sidebar = {
  Container: SidebarContainer,
  Header: SidebarHeader,
  Content: SidebarContent,
  Footer: SidebarFooter,
  Item: SidebarItem,
};
