import { css } from '@emotion/react';
import React from 'react';
import { useMantineTheme } from '@mantine/core';

interface InfoItemProps {
  title: string;
}

export const InfoItem: React.FC<InfoItemProps> = ({ title, children }) => {
  const theme = useMantineTheme();
  const styles = css`
    .InfoItem-title {
      font-size: 1.05rem;
      margin-bottom: 8px;
      font-weight: 500;
      color: ${theme.colors.gray[8]};
    }

    .InfoItem-content {
      color: ${theme.colors.gray[8]};

      a {
        color: inherit;
      }
    }
  `;

  return (
    <div css={styles}>
      <div className="InfoItem-title t1">{title}</div>
      <div className="InfoItem-content">{children}</div>
    </div>
  );
};
