import { gql } from '@apollo/client';

export const DELETE_INSTRUMENT_MUTATION = gql`
  mutation DeleteInstrument($id: uuid!) {
    delete_instruments(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
