import { gql } from '@apollo/client';

export const TEACHER_UPDATE_MUTATION = gql`
  mutation UpdateTeacher(
    $uid: String!
    $first_name: String!
    $last_name: String!
    $phone: String!
    $instrumentIds: [teacher_instruments_insert_input!]!
    $locationIds: [teacher_locations_insert_input!]!
  ) {
    update_users_by_pk(
      pk_columns: { id: $uid }
      _set: { first_name: $first_name, last_name: $last_name, phone: $phone }
    ) {
      id
      first_name
      last_name
      phone
    }
    delete_teacher_instruments(where: { teacher_id: { _eq: $uid } }) {
      affected_rows
    }
    insert_teacher_instruments(objects: $instrumentIds) {
      affected_rows
    }
    delete_teacher_locations(where: { teacher_id: { _eq: $uid } }) {
      affected_rows
    }
    insert_teacher_locations(objects: $locationIds) {
      affected_rows
    }
  }
`;
