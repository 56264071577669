import { gql } from '@apollo/client';

export const ASSIGN_STUDENTS_TO_INSTRUMENT_MUTATION = gql`
  mutation AssignStudentsToInstrument(
    $studentIds: [student_instruments_insert_input!]!
  ) {
    insert_student_instruments(objects: $studentIds) {
      affected_rows
    }
  }
`;
