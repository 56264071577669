import { css, SerializedStyles } from '@emotion/react';
import { useMantineTheme } from '@mantine/core';
import { NavLink } from 'react-router-dom';

interface SidebarItemProps {
  to: string;
  style?: SerializedStyles;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  children,
  to,
  style,
}) => {
  const theme = useMantineTheme();
  const styles = css`
    padding: 12px 16px;
    border-bottom: 1px solid ${theme.colors.gray[2]};
    display: block;
    color: ${theme.colors.gray[7]};
    text-decoration: none;
    display: flex;
    font-weight: 500;
    flex-direction: column;
    justify-content: center;

    div:last-of-type {
      line-height: 1.8;
    }

    &.active {
      font-weight: 600;
      color: ${theme.colors.gray[9]};
    }

    small {
      font-size: 0.85rem;
      margin-top: 5px;
      display: block;
      opacity: 0.7;
    }
  `;

  return (
    <NavLink css={!style ? styles : [styles, style]} to={to}>
      {children}
    </NavLink>
  );
};
