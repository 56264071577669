import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';

import { MiniTable } from '@rr/ui';
import { INSTRUMENT_TEACHERS_QUERY } from './InstrumentTeachers.query.gql';
import { InstrumentTeachersQuery, Users } from 'types/graphql';

interface InstrumentTeachersProps {
  instrumentId: string;
}

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Users;
    };
  };
}

export const InstrumentTeachers = () => {
  const { instrumentId }: { instrumentId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [instrumentId]);

  const updateOffset = (number: number) => setOffset(number);

  const { data } = useQuery<InstrumentTeachersQuery>(
    INSTRUMENT_TEACHERS_QUERY,
    {
      variables: {
        id: instrumentId,
        offset,
        limit: LIMIT,
      },
    }
  );

  const TEACHER_COLUMNS = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, cell }: NameCell) => (
        <Link to={`/teachers/${cell.row.original.id}`}>{value}</Link>
      ),
    },
  ];

  const teacherData =
    data?.instruments_by_pk?.teacher_instruments.map(({ user }) => ({
      name: user.gdpr_name,
      id: user.id,
    })) || [];

  return teacherData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={TEACHER_COLUMNS}
      data={teacherData}
      offset={offset}
      count={
        data?.instruments_by_pk?.teacher_instruments_aggregate.aggregate?.count
      }
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No teachers</MiniTable.Zero>
  );
};
