import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar, SplitLayout, SplitContainer, ZeroDetail } from '@rr/ui';

import * as ROUTES from 'config/routes';

import { InvoiceCreate } from './InvoiceCreate';
import { InvoiceList } from './InvoiceList';
import { InvoiceDetail } from './InvoiceDetail';

const Invoices = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const lastInvoice = localStorage.getItem('lastInvoice');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <InvoiceList handleCreate={setCreateModalOpen} />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route path={ROUTES.INVOICES_UPDATE} component={InvoiceDetail} />
          {lastInvoice && <Redirect to={`/invoices/${lastInvoice}`} />}
          <Route>
            <ZeroDetail title="No invoice selected">
              Please select one
            </ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
      <InvoiceCreate onClose={setCreateModalOpen} opened={createModalOpen} />
    </SplitLayout>
  );
};

export { Invoices };
