import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Modal, Space } from '@mantine/core';

import { GET_STUDENT_INSTRUMENTS_QUERY } from './GetStudentsAndInstruments.query.gql';
import { ASSIGN_STUDENTS_TO_INSTRUMENT_MUTATION } from './AssignStudentsToInstrument.mutation.gql';
import { GetStudentsAndInstrumentsQuery } from 'types/graphql';

import { Form } from '@rr/ui';
import { AutocompleteStudent } from 'components';

interface InstrumentAssignStudentsProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const InstrumentAssignStudents = ({
  opened,
  onClose,
}: InstrumentAssignStudentsProps) => {
  const { instrumentId }: { instrumentId: string } = useParams();

  const { loading, error, data } = useQuery<GetStudentsAndInstrumentsQuery>(
    GET_STUDENT_INSTRUMENTS_QUERY,
    {
      variables: {
        instrumentId,
      },
    }
  );

  const [assignStudentsToInstrument] = useMutation(
    ASSIGN_STUDENTS_TO_INSTRUMENT_MUTATION,
    {
      refetchQueries: ['GetInstrument', 'InstrumentStudents'],
    }
  );

  const initialValues = {
    students: [],
  };

  const validationSchema = Yup.object().shape({
    students: Yup.array().required('Required'),
  });

  const onSubmit = (values: typeof initialValues) => {
    console.log('values', values);
    console.log(instrumentId);
    assignStudentsToInstrument({
      variables: {
        studentIds: values.students.map((student) => ({
          instrument_id: instrumentId,
          student_id: student,
        })),
      },
    }).then((response) => {
      console.log(response);
    });
    onClose(false);
  };

  if (loading) return <div></div>;
  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const existingIds =
    data?.instruments_by_pk?.student_instruments.map(
      (student) => student.student_id
    ) || [];

  const studentData = data?.students.map((student) => ({
    value: student.id,
    label: `${student.first_name} ${student.last_name}`,
    firstName: student.first_name,
    lastName: student.last_name,
  }));

  const filteredStudentData = studentData?.filter(
    (student) => !existingIds.includes(student.value)
  );

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Assign students to an instrument"
    >
      <Form.Context
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form.Wrapper>
          <AutocompleteStudent
            value={filteredStudentData}
            multiple
            name="students"
            label="Students"
          />
          <Space h="md" />
          <Button type="submit" color="blue" fullWidth>
            Assign students
          </Button>
        </Form.Wrapper>
      </Form.Context>
    </Modal>
  );
};
