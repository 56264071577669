import { gql } from '@apollo/client';

export const GET_TEACHERS_INSTRUMENTS_QUERY = gql`
  query GetTeachersAndInstruments($instrumentId: uuid!) {
    users(where: { isTeacher: { _eq: true } }) {
      id
      first_name
      last_name
    }
    instruments_by_pk(id: $instrumentId) {
      teacher_instruments {
        teacher_id
      }
    }
  }
`;
