import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import { Badge } from '@mantine/core';

import { MiniTable } from '@rr/ui';
import { GET_STUDENT_LESSONS_QUERY } from './GetStudentLessons.query.gql';
import { GetAdminStudentLessonsQuery, Lessons } from 'types/graphql';
import { humanDate } from '@rr/utils';

interface NameCell {
  value: string;
  cell: {
    row: {
      original: Lessons;
    };
  };
}

export const StudentLessons = () => {
  const { studentId }: { studentId: string } = useParams();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    setOffset(0);
  }, [studentId]);

  const updateOffset = (number: number) => setOffset(number);

  const { loading, error, data } = useQuery<GetAdminStudentLessonsQuery>(
    GET_STUDENT_LESSONS_QUERY,
    {
      variables: {
        id: studentId,
        offset,
        limit: LIMIT,
      },
    }
  );

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log(data);

  const LESSON_COLUMNS = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Time',
      accessor: 'start_time',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) =>
        value ? (
          <>
            {value} - {cell.row.original.end_time}
          </>
        ) : (
          <Badge color="red" variant="filled">
            Time not booked
          </Badge>
        ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) => {
        console.log(cell);
        return <div>{cell.row.original.status}</div>;
      },
    },
    {
      Header: 'Teacher',
      accessor: 'teacher',
      Cell: ({ value, cell }: NameCell) => (
        <Link to={`/teachers/${cell.row.original.teacher_id}`}>{value}</Link>
      ),
    },
    {
      Header: 'Instrument',
      accessor: 'instrument',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) =>
        value ? (
          <div>{value}</div>
        ) : (
          <Badge color="red" variant="filled">
            Instrument missing
          </Badge>
        ),
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Plan',
      accessor: 'plan',
      Cell: ({ value, cell }: NameCell) => (
        <Link to={`/plans/${cell.row.original.plan_id}`}>{value}</Link>
      ),
    },
  ];

  const lessonData =
    data?.students_by_pk?.lessons.map((lesson) => ({
      date: humanDate(lesson.start_time),
      start_time: humanDate(lesson.start_time, {
        // TODO FIXME
        minute: '2-digit',
        hour: '2-digit',
        hour12: true,
      }),
      end_time: humanDate(lesson.end_time, {
        // TODO FIXME from 00:00 to 12:00
        minute: '2-digit',
        hour: '2-digit',
        hour12: true,
      }),
      teacher: lesson.user.gdpr_name,
      teacher_id: lesson.user.id,
      instrument: lesson.instrument?.prettyName,
      location: lesson.location?.name,
      plan: lesson.plan?.prettyName,
      plan_id: lesson.plan?.id,
      status: lesson.status,
    })) || [];

  return lessonData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={LESSON_COLUMNS}
      data={lessonData}
      offset={offset}
      count={data?.students_by_pk?.lessons_aggregate.aggregate?.count}
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No upcoming lessons</MiniTable.Zero>
  );
};
