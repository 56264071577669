import { gql } from '@apollo/client';

export const GET_USER_QUERY = gql`
  query GetUser($uid: String!) {
    users_by_pk(id: $uid) {
      id
      email
      first_name
      last_name
      full_name
      title
      phone
    }
  }
`;
